import { PencilIcon, PlusIcon, PlusSmallIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { BaseModal } from "../../popups";
import { Button, Input } from "../../form";
import AdminPanelButton from "../../form/button/adminPanelButton";
import { useFormik } from "formik";
import {
  createAccessKey,
  deleteAccesKey,
  getAccessKeyByOrg,
  updateAccesKey
} from "../../../service";
import { toast } from "react-toastify";

export default function AccesKey({ prefilledData }) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceskeys, setAccesKeys] = useState([]);
  const [key, setKey] = useState();

  const submitHandler = async (values, { setSubmitting }) => {
    setLoading(true);

    try {
      const res = key?._id
        ? await updateAccesKey({ ...values }, prefilledData._id, key._id)
        : await createAccessKey({ ...values }, prefilledData._id);
      toast.success(res.data.message);
      getAccessKyes();
    } catch (err) {
      toast.error("failed to created access token");
      console.log(err);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };
  const getAccessKyes = async () => {
    if (!prefilledData?._id) return;
    try {
      const res = await getAccessKeyByOrg(prefilledData?._id);
      setAccesKeys(res.data);
    } catch (err) {
      toast.error("something went wrong");
    }
  };

  const deleteKey = async () => {
    try {
      const res = await deleteAccesKey(prefilledData?._id, key._id);
      setDeleteOpen(!deleteOpen);
      getAccessKyes();
      toast.success("acces key deleted");
    } catch (err) {
      toast.error("something went wrong");
      console.log(err);
    }
  };
  useEffect(() => {
    getAccessKyes();
  }, [prefilledData]);

  const addRegion = () => {
    formik.setFieldValue("name", "");

    setKey(null);
    setOpen(true);
  };

  const handleEdit = (key) => {
    formik.setFieldValue("name", key.name || "");

    setKey(key);
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      name: ""
    },
    //validationSchema: regionSchema,
    onSubmit: submitHandler
  });

  return (
    <>
      <div className="mt-10 flex justify-between">
        <div className="grow-[2]">
          <p className="font-semibold">Access Keys</p>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto"></div>
              <div className="mt-4 sm:ml-16 sm:mt-0"></div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 cursor-pointer">
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer w-[100px]">
                          Key
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">
                          Created
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">
                          Last Used
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"></th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {acceskeys.map((key) => {
                        return (
                          <tr key={key.name}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-0">
                              {key.name}
                            </td>
                            <td
                              className="divide-y divide-gray-400 divide-dotted
 whitespace-nowrap px-3 py-4 text-sm text-gray-500 overflow-hidden text-ellipsis w-[100px]">
                              <span
                                className="truncate w-36 block
">
                                {" "}
                                {key.token}
                              </span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {key.createdAt}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              1{key.lastUsed}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 text-black">
                              <button className="" type="button" onClick={() => handleEdit(key)}>
                                <PencilIcon className="w-[1rem] h-[1rem] mr-2" />
                              </button>
                              <button
                                className=""
                                type="button"
                                onClick={() => {
                                  setDeleteOpen(!deleteOpen);
                                  setKey(key);
                                  //   serRegion(region);
                                }}>
                                <TrashIcon className="w-[1rem] h-[1rem] mr-2" />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div onClick={() => addRegion()}>
            <PlusSmallIcon className={`w-7 h-7 stroke-2 stroke-white bg-[#E3E3E3] rounded-full`} />
          </div>
        </div>
        <div>
          <div>
            <AdminPanelButton
              type="submit"
              isSave={true}
              loading={false}
              bgColor="#FC9E5A"
              textColor="#FFFFFF"
              color="white"
              onClick={() => {}}>
              Save
            </AdminPanelButton>
          </div>
        </div>
      </div>

      <BaseModal open={deleteOpen} setOpen={setDeleteOpen} title={""}>
        <h1 className="text-center mb-4 font-semibold">Remove Access Key?</h1>
        <div className="flex justify-between ">
          <div className="grow text-right pr-5">
            <div className="0 flex justify-end">
              <AdminPanelButton
                type="submit"
                isSave={true}
                loading={false}
                bgColor="#FC9E5A"
                textColor="#FFFFFF"
                color="white"
                onClick={() => {
                  deleteKey();
                }}>
                Remove
              </AdminPanelButton>
            </div>
          </div>
          <div className="grow">
            <AdminPanelButton
              type="submit"
              isSave={true}
              loading={false}
              bgColor="#FFFFFF"
              textColor="#000000"
              color="white"
              onClick={() => {
                setDeleteOpen(!deleteOpen);
              }}>
              Don’t Remove
            </AdminPanelButton>
          </div>
        </div>
      </BaseModal>

      <BaseModal open={open} setOpen={setOpen} title={!key ? "create key" : "update key"}>
        <div className="overflow-x-scroll">
          <p>{key?.token}</p>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-2 mt-5">
            <Input
              label="acces key name"
              name="name"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values["name"]}
              error={formik?.errors["name"]}
              touched={formik?.touched["name"]}
            />
          </div>

          <div className="mt-5 sm:mt-4 grid grid-cols-2 gap-4">
            <AdminPanelButton
              type="submit"
              isSave={true}
              loading={loading}
              bgColor="#FC9E5A"
              textColor="#FFFFFF"
              color="white"
              onClick={() => {}}>
              Create
            </AdminPanelButton>
          </div>
        </form>
      </BaseModal>
    </>
  );
}
