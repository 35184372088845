import { useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { adminMagicVerify } from "../service";
import { ProfileContext } from "../contexts/profileContext";

import { Spinner } from "../components/form";

function Magic() {
  const navigate = useNavigate();
  const [_, setProfileData] = useContext(ProfileContext);
  const [searchParams] = useSearchParams();

  const handleVerification = async (code) => {
    try {
      const { data: { token = "" } = {} } = await adminMagicVerify({ token: code });
      localStorage.setItem("masterToken", token);
      navigate("/dashboard");
      setProfileData({ loaded: true, token, reload: 1 });
    } catch (e) {
      localStorage.removeItem("token");
      console.log("ERROR", e);
      toast.error("Login failed");
      navigate("/");
    }
  };

  //   const fetchUser = async () => {
  //     try {
  //       const { data = {} } = await getAdminProfile();
  //       navigate('/dashboard');
  //       setProfileData({ ...data, loaded: true });
  //     } catch (error) {
  //       console.log('EEE', error);
  //     }
  //   };

  useEffect(() => {
    const ref = searchParams.get("ref");
    if (!ref) return;

    handleVerification(ref);
  }, [searchParams]);

  return (
    <div className="h-screen w-full flex items-center justify-center flex-row">
      <p className="text-xl flex gap-2 items-center">
        <Spinner isSubmitting={true} color="#000" setSize={20} /> Verifying login
      </p>
    </div>
  );
}

export default Magic;
