import React, { useEffect, useState } from "react";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { Popup, SlideInPopup } from "../../popups";
import { Input, Button } from "../../form";
import {
  getOrganizationProfileTypes,
  createOrganizationProfileType,
  updateOrganizationProfileType,
  getProfileTypePages,
  getProfileTypeFields
} from "../../../service";
import { editIcon, grayTickIcon, orangeTickIcon } from "../../../asssets/icons";

const OrgProfiles = ({ prefilledData }) => {
  const [addNewProfile, setAddNewProfile] = useState();
  const [profileTypes, setProfileTypes] = useState([]);
  const [updatedNames, setUpdatedNames] = useState({});
  const [editPages, setEditPages] = useState(false);
  const [editFields, setEditFields] = useState(false);
  const applicableLanguages = prefilledData?.languages;

  const getProfileTypes = async () => {
    try {
      const { data = [] } = await getOrganizationProfileTypes(prefilledData._id);
      setProfileTypes(data);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleAddNewProfileType = async () => {
    try {
      await createOrganizationProfileType(prefilledData._id, {
        name: addNewProfile
      });
      setAddNewProfile(false);
      toast.success("Profile type created successfully");
      getProfileTypes();
    } catch (error) {
      toast.error("Error creating profile type");
      console.log(error);
    }
  };

  const handleUpdateProfileTypeName = async (id, updatedName) => {
    try {
      await updateOrganizationProfileType(id, {
        name: updatedName,
        type: "name"
      });
      toast.success("Profile type name updated successfully");
      getProfileTypes();
    } catch (error) {
      toast.error("Error updating profile type name");
      console.log(error);
    }
  };

  const handleEditProfileTypePages = async (id, name) => {
    try {
      const { data = [] } = await getProfileTypePages(id);
      setEditPages({ id, name, pages: data });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTogglePageSelection = (id) => {
    const updatedPages = editPages.pages.map((page) => {
      if (page._id === id) {
        return { ...page, isAllowed: !page.isAllowed };
      }
      return page;
    });
    setEditPages({ ...editPages, pages: updatedPages });
  };

  const handleSaveProfileTypePages = async () => {
    try {
      const allowedPages = editPages.pages.filter((page) => page.isAllowed).map((page) => page._id);

      await updateOrganizationProfileType(editPages.id, { allowedPages, type: "pages" });
      toast.success("Profile type pages saved successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error saving profile type pages");
    }
  };

  const handleEditProfileTypeFields = async (id, name) => {
    try {
      const { data = [] } = await getProfileTypeFields(id);
      setEditFields({ id, name, fields: data });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggleFieldSelection = (id) => {
    const updatedFields = editFields.fields.map((field) => {
      if (field._id === id) {
        return { ...field, isAllowed: !field.isAllowed };
      }
      return field;
    });
    setEditFields({ ...editFields, fields: updatedFields });
  };

  const handleFieldEditClick = (id) => {
    const updatedFields = editFields.fields.map((field) => {
      return {
        ...field,
        isEditMode: field._id === id ? !field.isEditMode : false
      };
    });
    setEditFields({ ...editFields, fields: updatedFields });
  };

  const handleFieldLabelChange = (id, lang, value) => {
    const updatedFields = editFields.fields.map((field) => {
      if (field._id === id) {
        if (!field.label) {
          field.label = {};
        }
        return {
          ...field,
          label: {
            ...field.label,
            [lang]: value
          }
        };
      }
      return field;
    });

    setEditFields({ ...editFields, fields: updatedFields });
  };

  const handleSaveProfileTypeFields = async () => {
    try {
      const inputFields = editFields.fields
        .filter((field) => field.isAllowed)
        .map((field) => {
          return {
            fieldId: field._id,
            label: { ...field.label }
          };
        });

      if (!inputFields.length) {
        toast.info("Please enable field before saving");
      }

      await updateOrganizationProfileType(editFields.id, { inputFields, type: "fields" });
      toast.success("Profile type fields saved successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error saving profile type fields");
    }
  };

  useEffect(() => {
    if (!prefilledData?._id) return;

    getProfileTypes();
  }, [prefilledData?._id]);

  return (
    <>
      <div
        className="flex justify-start items-center gap-4 px-4 py-3 bg-white cursor-pointer shadow-sm"
        onClick={() => setAddNewProfile(!addNewProfile)}>
        <PlusSmallIcon className={`w-7 h-7 stroke-2 stroke-white bg-corange-100 rounded-full`} />
        <p className="text-md font-bold text-gray-900">Create a Profile Type</p>
      </div>

      {/* List of profile types */}
      <p className="text-base font-bold text-[#191919] mb-1 mt-8">Profile Type Editor</p>
      <p className="text-base text-[#757575] mb-2">
        Specify the pages to be included within the profile setup, their titles as well as the
        fields to be included in the personal information form:
      </p>

      {profileTypes.map(({ _id, name }, index) => {
        return (
          <div key={index} className="grid grid-cols-5 mb-2 gap-1 w-full">
            <div className="col-span-3 flex items-center gap-2 pr-2">
              <input
                className="block w-full px-4 py-2 rounded bg-white text-base font-light text-[#757575] focus:ring-0 focus:outline-none"
                defaultValue={name}
                onChange={(e) => setUpdatedNames({ ...updatedNames, [_id]: e.target.value })}
              />
              {updatedNames[_id] && updatedNames[_id] !== name && (
                <span
                  className="text-[#757575] cursor-pointer"
                  onClick={() => handleUpdateProfileTypeName(_id, updatedNames[_id])}>
                  <FaCheck />
                </span>
              )}
            </div>
            <div
              className="col-span-1 px-4 py-2 rounded bg-corange-100 text-base font-light text-white text-center cursor-pointer"
              onClick={() => handleEditProfileTypePages(_id, name)}>
              Pages
            </div>
            <div
              className="col-span-1 px-4 py-2 rounded bg-corange-100 text-base font-light text-white text-center cursor-pointer"
              onClick={() => handleEditProfileTypeFields(_id, name)}>
              Fields
            </div>
          </div>
        );
      })}

      {/* Add new profile popup */}
      {addNewProfile && (
        <Popup
          icon="add-profile"
          title={"Create a New Profile Type"}
          description={"Please create a name for this profile type."}
          closeModal={() => setAddNewProfile(false)}
          data-testid="add-profile-type-popup">
          <h2 className="text-xl font-bold mt-6 text-center">Profile Type Name</h2>
          <Input
            className="my-3"
            inputClassName="text-sm"
            labelClassName="text-xl font-bold text-[#191919] mb-[10px]"
            name="new-profile-name"
            data-testid="new-profile-name-field"
            placeholder={"CHAMPION Profile"}
            onChange={(e) => setAddNewProfile(e.target.value)}
          />
          <Button.ButtonOutlined className={"block mx-auto"} handler={handleAddNewProfileType}>
            Create
          </Button.ButtonOutlined>
        </Popup>
      )}

      {/* Edit pages of profile type */}
      <SlideInPopup
        heading={editPages?.name}
        subHeading={"Specify the features included in this profile type for the profile setup:"}
        open={editPages?.pages?.length > 0}
        setOpen={setEditPages}>
        <div className="px-4 sm:px-6">
          {editPages?.pages?.map(({ _id, name, isAllowed }, index) => {
            return (
              <div
                key={index}
                className="grid grid-cols-12 items-center cursor-pointer my-4"
                onClick={() => handleTogglePageSelection(_id)}>
                <span className="col-span-1">{isAllowed ? orangeTickIcon : grayTickIcon}</span>
                <span className="col-span-11 bg-[#F4F4F4] px-4 py-2 text-base text-black">
                  {name}
                </span>
              </div>
            );
          })}
          <Button.ButtonOutlined
            className="my-3 w-full font-bold"
            handler={handleSaveProfileTypePages}>
            Save
          </Button.ButtonOutlined>
        </div>
      </SlideInPopup>

      {/* Edit fields of profile type */}
      <SlideInPopup
        heading={editFields?.name}
        subHeading={"Specify the features included in this profile type for the profile setup:"}
        open={editFields?.fields?.length > 0}
        setOpen={setEditFields}>
        <div className="px-4 sm:px-6">
          {editFields?.fields?.map(
            ({ _id, name, placeholder, label, isAllowed, isEditMode }, index) => {
              console.log(label);
              return (
                <div key={index} className="flex items-center">
                  <div className="grid grid-cols-12 items-center my-4 flex-1">
                    <span
                      className="col-span-1 cursor-pointer"
                      onClick={() => handleToggleFieldSelection(_id)}>
                      {isAllowed ? orangeTickIcon : grayTickIcon}
                    </span>
                    <div className="col-span-10 flex flex-col">
                      <div className={`flex flex-col gap-2 mb-4`}>
                        {applicableLanguages.map((lang) => (
                          <div
                            key={lang}
                            className="text-sm tracking-wide font-medium flex flex-col">
                            [{lang.toUpperCase()}]{" "}
                            {isEditMode ? (
                              <input
                                type="text"
                                className="h-6 bg-[#F4F4F4] px-4 text-sm"
                                value={label && label[lang]}
                                onChange={(e) => handleFieldLabelChange(_id, lang, e.target.value)}
                              />
                            ) : (
                              <span className="bg-[#F4F4F4] px-4 py-1 h-6 rounded-sm text-xs text-black">
                                {label && label[lang]}
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                      {isEditMode ? (
                        <input
                          type="text"
                          className="h-9 bg-[#F4F4F4] px-4 text-[#828282] text-sm"
                          placeholder="Edit placeholder text..."
                        />
                      ) : (
                        <span className="bg-[#F4F4F4] px-4 py-2 h-9 rounded-sm text-sm text-black">
                          {placeholder}
                        </span>
                      )}
                      <span className="text-[10px] text-gray-400 text-right py-1 px-1 bg-orange-100 rounded-sm my-0.5">
                        {name}
                      </span>
                    </div>
                  </div>
                  <span
                    className={`col-span-1 p-2 border rounded hover:bg-orange-100 cursor-pointer ${
                      isEditMode && "bg-corange-100"
                    }`}
                    onClick={() => handleFieldEditClick(_id)}>
                    {editIcon}
                  </span>
                </div>
              );
            }
          )}
          <Button.ButtonOutlined
            className="my-3 w-full font-bold"
            handler={handleSaveProfileTypeFields}>
            Save
          </Button.ButtonOutlined>
        </div>
      </SlideInPopup>
    </>
  );
};

export default OrgProfiles;
