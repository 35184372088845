import React from "react";
import { Spinner } from "../form";

const FullScreenLoader = ({ text }) => {
  return (
    <div className="fixed top-0 left-0 z-50 h-screen w-full flex items-center justify-center flex-row bg-[#00000047] backdrop-blur-sm">
      <p className="text-xl flex flex-col gap-2 items-center">
        <Spinner isSubmitting={true} color="#000" setSize={50} />{" "}
        <span className="block">{text ?? ""}</span>
      </p>
    </div>
  );
};

export default FullScreenLoader;
