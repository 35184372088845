import React from "react";
import Spinner from "../spinner";

export default function ButtonRounded({
  onClick,
  className,
  children,
  loading,
  bgColor = "#163F9B",
  ...props
}) {
  return (
    <button
      {...props}
      className={`bg-[${bgColor}] text-white px-4 py-2 rounded font-bold flex items-center justify-center ${className} disabled:opacity-60`}
      disabled={loading}
      onClick={onClick}>
      {children} <Spinner isSubmitting={loading} />
    </button>
  );
}
