import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import SideBar from "./sideBar";
import { MenuIcon } from "../../asssets/icons";

const AdminLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-screen w-full flex flex-col overflow-hidden max-h-screen">
      <div className="h-[20px] w-[100%] bg-[#E3E3E3] absolute left-0 top-0" />

      {/* Sidebar toggle button */}
      {!isSidebarOpen && (
        <div className={"w-full lg:hidden mt-8 px-5 md:pl-14 md:pr-10"}>
          <button
            className={`my-3 ml-2 p-1 w-min border-2 border-transparent bg-corange-100 rounded-md`}
            onClick={toggleSidebar}>
            {MenuIcon}
          </button>
        </div>
      )}

      <div className="flex flex-grow">
        <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className={"flex-grow ml-4 mt-4"}>
          <div className={"relative px-5 md:pt-5 md:pl-14 md:pr-10 w-full overflow-y-auto"}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
