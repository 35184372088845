import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ProfileContext } from "../../../contexts/profileContext";

import { createOrganization, updateOrganizationInfo } from "../../../service";
import ImageUploader from "../../common/imageUploader";
import { Dropdown, Input } from "../../form";
import AdminPanelButton from "../../form/button/adminPanelButton";
import { uploadFileIfNeeded } from "../../../utils";

const OrgInfo = ({ prefilledData }) => {
  const logoInputRef = useRef(null);
  const faviconInputRef = useRef(null);

  const schoolLogoInputRef = useRef(null);
  const schoolFaviconInputRef = useRef(null);

  const teamLogoInputRef = useRef(null);
  const teamFaviconInputRef = useRef(null);

  const brandingBackgroundInputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [profile, setProfile] = useContext(ProfileContext);
  const [orgName, setOrgName] = useState("");
  const [email, setEmail] = useState("");
  const [translationPrefix, setTranslationPrefix] = useState("");
  const [logo, setLogo] = useState(null); // for image preview
  const [favicon, setFavicon] = useState(null); // for image preview

  const [schoolLogo, setSchoolLogo] = useState(null); // for image preview
  const [schoolFavicon, setSchoolFavicon] = useState(null); // for image preview

  const [teamLogo, setTeamLogo] = useState(null); // for image preview
  const [teamFavicon, setTeamFavicon] = useState(null); // for image preview
  const [brandingBackground, setBrandingBackground] = useState(null); // for image preview

  const [languages, setLanguages] = useState([
    { _id: "de", name: "German", description: "German Language", isSelected: false },
    { _id: "fr", name: "French", description: "French Language", isSelected: false },
    { _id: "it", name: "Italian", description: "Italian Language", isSelected: false },
    { _id: "en", name: "English", description: "English Language", isSelected: false }
  ]);

  const handleSelectLanguages = (id) => {
    let temp = [...languages];
    temp = temp.map((language) => {
      if (language._id === id) {
        return { ...language, isSelected: !language.isSelected };
      }

      return language;
    });

    setLanguages(temp);
  };

  const createOrgHandler = async () => {
    setLoading(true);

    try {
      const logoLink = (await uploadFileIfNeeded(logo, "organization-logos")) || logo;
      const faviconLink = (await uploadFileIfNeeded(favicon, "organization-logos")) || favicon;

      const schoolLogoLink =
        (await uploadFileIfNeeded(schoolLogo, "organization-logos")) || schoolLogo;
      const schoolFaviconLink =
        (await uploadFileIfNeeded(schoolFavicon, "organization-logos")) || schoolFavicon;

      const teamLogoLink = (await uploadFileIfNeeded(teamLogo, "organization-logos")) || teamLogo;
      const teamFaviconLink =
        (await uploadFileIfNeeded(teamFavicon, "organization-logos")) || teamFavicon;

      const brandingBackgroundLink =
        (await uploadFileIfNeeded(brandingBackground, "organization-logos")) || brandingBackground;

      const postBody = {
        name: orgName,
        translationPrefix,
        logo: logoLink,
        favicon: faviconLink,
        schoolLogo: schoolLogoLink,
        schoolFavicon: schoolFaviconLink,
        teamLogo: teamLogoLink,
        teamFavicon: teamFaviconLink,
        brandingBackground: brandingBackgroundLink,
        languages: languages.filter((l) => l.isSelected).map((l) => l._id),
        email: email
      };

      if (!prefilledData?._id) {
        const { data: newOrg } = await createOrganization(postBody);
        toast.success(orgName + " created!");
        navigate("/dashboard/organisation/orginfo/" + newOrg);
      } else {
        await updateOrganizationInfo({ id: prefilledData._id, ...postBody });
        navigate(`/dashboard/organisation/orginfo/${prefilledData._id}`);
        toast.success(orgName + " updated!");
      }

      // Reload data
      setProfile({ ...profile, reload: new Date().getTime() });
    } catch (error) {
      console.error("Create organization error", error);
      toast.error("Error while creating organization:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!prefilledData?._id) return;

    setOrgName(prefilledData.name);
    setLogo(prefilledData.logo);
    setFavicon(prefilledData.favicon);
    setSchoolLogo(prefilledData.schoolLogo);
    setSchoolFavicon(prefilledData.schoolFavicon);
    setTeamLogo(prefilledData.teamLogo);
    setTeamFavicon(prefilledData.teamFavicon);
    setBrandingBackground(prefilledData.brandingBackground);
    setEmail(prefilledData.email);
    setTranslationPrefix(prefilledData.translationPrefix);

    setLanguages(
      languages.map((l) => {
        return {
          ...l,
          isSelected:
            prefilledData.languages.includes(l._id) ||
            prefilledData.languages.includes(l._id.toUpperCase())
        };
      })
    );
  }, [prefilledData]);

  return (
    <>
      <div className="flex justify-between">
        <div>
          <div className="mb-[30px]">
            <p className="text-base font-bold text-[#191919] mb-2">Organisation Name</p>
            <Input
              defaultValue={orgName}
              className="mb-3"
              inputClassName="text-sm"
              labelClassName="text-xl font-bold text-[#191919] mb-[10px]"
              name="name"
              data-testid="org-name-field"
              onChange={(e) => setOrgName(e.target.value)}
            />
            <p className="text-base font-bold text-[#191919] mb-2">Email address (Org. owner)</p>
            <Input
              defaultValue={email}
              className="mb-3"
              inputClassName="text-sm"
              labelClassName="text-xl font-bold text-[#191919] mb-[10px]"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <p className="text-base font-bold text-[#191919] mb-2">Translation Prefix</p>
            <Input
              defaultValue={translationPrefix}
              className="mb-3"
              inputClassName="text-sm"
              labelClassName="text-xl font-bold text-[#191919] mb-[10px]"
              name="translationPrefix"
              onChange={(e) => setTranslationPrefix(e.target.value)}
              disabled={!!prefilledData?.id}
            />

            <ImageUploader label="Logo" image={logo} setImage={setLogo} inputRef={logoInputRef} />

            <ImageUploader
              label="Favicon"
              image={favicon}
              setImage={setFavicon}
              inputRef={faviconInputRef}
            />

            <ImageUploader
              label="mf Logo"
              image={schoolLogo}
              setImage={setSchoolLogo}
              inputRef={schoolLogoInputRef}
            />

            <ImageUploader
              label="mf Favicon"
              image={schoolFavicon}
              setImage={setSchoolFavicon}
              inputRef={schoolFaviconInputRef}
            />

            <ImageUploader
              label="mft Logo"
              image={teamLogo}
              setImage={setTeamLogo}
              inputRef={teamLogoInputRef}
            />

            <ImageUploader
              label="mft Favicon"
              image={teamFavicon}
              setImage={setTeamFavicon}
              inputRef={teamFaviconInputRef}
            />

            <ImageUploader
              label="Branding Background"
              image={brandingBackground}
              setImage={setBrandingBackground}
              inputRef={brandingBackgroundInputRef}
            />
          </div>

          <Dropdown
            heading={"Applicable Languages"}
            subheading={"Select the language/s applicable to this organisation:"}
            toggleButtonText={"Add Language"}
            list={languages}
            errorMessage={
              languages.filter((l) => l.isSelected).length === 0
                ? "Select at least one language"
                : ""
            }
            onClick={handleSelectLanguages}
          />
        </div>
        <div>
          <AdminPanelButton
            type="submit"
            isSave={true}
            loading={loading}
            bgColor="#FC9E5A"
            textColor="#FFFFFF"
            color="white"
            onClick={createOrgHandler}>
            Save
          </AdminPanelButton>
        </div>
      </div>
    </>
  );
};

export default OrgInfo;
