import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

import { ProfileContext } from "../contexts/profileContext";
import { PageWrapper } from "../components/admin";
import { OrgRegions, OrgEstablishments, OrgAcademies } from "../components/admin/organization";

export default function OrganisationRegion() {
  const [profile] = useContext(ProfileContext);
  const [org, setOrg] = useState(null);
  const [showRegion, setShowRegion] = useState(false);
  const [showEstablishment, setShowEstablishment] = useState(false);
  const [showAcademy, setShowAcademy] = useState(false);

  const { orgId } = useParams();
  const { organizations } = profile;

  useEffect(() => {
    if (!orgId || !organizations?.length) return;
    const org = organizations.find((org) => org._id === orgId);
    if (!org) return;

    setOrg({ ...org });
  }, [orgId, organizations]);

  return (
    <div>
      <PageWrapper pageHeading={"Organisation Regions, Establishments & Academies"}>
        {/* <div>
          <h2 className="text-3xl font-bold mb-10">Language/s</h2>
          <Dropdown
            heading={"Language Selector"}
            subheading={""}
            toggleButtonText={"Add Language"}
            list={languages}
            errorMessage={
              languages.filter((l) => l.isSelected).length === 0
                ? "Select at least one language"
                : ""
            }
            onClick={handleSelectLanguages}
          />
        </div> */}
        <div className="flex flex-row justify-between items-center">
          <div className="w-full bg-[#ffffff] rounded relative">
            <div className="flex justify-start items-center gap-4 px-4 py-3 cursor-pointer shadow-sm">
              Edit Regions
            </div>
          </div>
          <div className="cursor-pointer" onClick={() => setShowRegion(!showRegion)}>
            <FaEdit />
          </div>
        </div>

        {showRegion && <OrgRegions prefilledData={org} />}

        <div className="flex flex-row justify-between items-center mt-10">
          <div className="w-full bg-[#ffffff] rounded relative">
            <div className="flex justify-start items-center gap-4 px-4 py-3 cursor-pointer shadow-sm">
              Edit Establishments
            </div>
          </div>
          <div className="cursor-pointer" onClick={() => setShowEstablishment(!showEstablishment)}>
            <FaEdit />
          </div>
        </div>

        {showEstablishment && <OrgEstablishments prefilledData={org} />}

        <div className="flex flex-row justify-between items-center mt-10">
          <div className="w-full bg-[#ffffff] rounded relative">
            <div className="flex justify-start items-center gap-4 px-4 py-3 cursor-pointer shadow-sm">
              Edit Academies
            </div>
          </div>
          <div className="cursor-pointer" onClick={() => setShowAcademy(!showAcademy)}>
            <FaEdit />
          </div>
        </div>

        {showAcademy && <OrgAcademies prefilledData={org} />}
      </PageWrapper>
    </div>
  );
}
