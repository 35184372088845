import React, { useState } from "react";

import { toast } from "react-toastify";
import { Input, Button } from "../components/form";
import { Popup } from "../components/popups";
import { useFormik } from "formik";
import { loginSchema } from "../schemas/schemas";
import { adminLogin } from "../service";

const LoginForm = () => {
  const [emailSent, setEmailSent] = useState(false);

  const submitHandler = async (values, {}) => {
    try {
      const response = await adminLogin(values);
      if (response?.status === 200) {
        setEmailSent(true);
      } else toast.error("Login failed");
    } catch (error) {
      toast.error(error.message);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: loginSchema,
    onSubmit: submitHandler
  });

  const closeModal = () => {
    formik.resetForm();
    setEmailSent(false);
  };

  return (
    <div data-testid="login-form">
      <div className="h-[20px] w-[100%] bg-[#E3E3E3] absolute left-0 top-0" />
      {!emailSent && (
        <div className="w-full h-screen flex items-center justify-center">
          <div className="w-[90%] md:w-[600px] md:px-10 relative">
            <h1 className="text-4xl uppercase text-center font-bold">{"Login"}</h1>

            <form className="py-8 mx-2" onSubmit={formik.handleSubmit}>
              <div className="flex flex-col justify-between">
                <Input
                  className="mb-3"
                  label={"E-mail"}
                  inputClassName="text-sm"
                  labelClassName="text-xl font-bold text-[#191919] mb-[10px]"
                  name="email"
                  data-testid="email-field"
                  placeholder={"email@gmail.com"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                />

                <div className="flex justify-end w-full mt-[40px]">
                  <Button.ButtonRounded
                    loading={formik.isSubmitting}
                    className={`bg-corange-100 rounded text-white px-[78px] py-[14px] font-bold disabled:opacity-60 gap-10 rounded-5`}
                    type="submit"
                    bgColor="corange-100"
                    data-testid="submit-button">
                    {"LOGIN"}
                  </Button.ButtonRounded>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {emailSent && (
        <Popup
          icon="email-sent"
          title={"Login Email Sent!"}
          description={"Check your inbox for a link to login."}
          closeModal={closeModal}
          data-testid="email-sent-popup"
        />
      )}
    </div>
  );
};

export default LoginForm;
