import React from "react";

import HelpLinePopupItem from "./helpLinePopupItem";
import { CrossButton } from "../common";

export default function HelpLinePopup(props) {
  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="rounded w-[600px] max-w-[100%]">
        <div className="py-2 px-3 bg-white rounded shadow-lg relative">
          <div className="content">
            <CrossButton
              onClick={props.closeModal}
              className={`p-2 rounded-full text-white bg-[#757575] text-xl`}
            />
            <div className="text-center">
              <img
                src={`/images/icons/${props.icon}.png`}
                className="mx-auto mb-6 w-[100px] md:w-[150px] lg:w-[200px]"
              />
            </div>
            <HelpLinePopupItem title={props.title} description={props.description} />
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
}
