import { useFormik } from "formik";
import { toast } from "react-toastify";
import { contactInfoSchema } from "../../../schemas/schemas";
import { updateOrganizationContactInfo } from "../../../service";
import { Button, Input } from "../../form";

const OrgContact = ({ prefilledData }) => {
  const formik = useFormik({
    initialValues: {
      name: prefilledData?.contact?.name,
      email: prefilledData?.contact?.email,
      phone: prefilledData?.contact?.phone
    },
    validationSchema: contactInfoSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updateOrganizationContactInfo({ id: prefilledData._id, ...values });
        toast.success("Contact updated successfully.");
      } catch (error) {
        console.error("Failed to update contact.");
        toast.error("Failed to update contact.");
      }
    }
  });

  return (
    <div className="my-10 border-[1px] border-[#606060] rounded-md p-4">
      <p className="font-bold text-lg mb-2">Contact Information</p>
      <form onSubmit={formik.handleSubmit} className="my-4">
        <Input
          className="mb-4"
          inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
          label="Name"
          name="name"
          type="text"
          value={formik.values.name}
          touched={formik.touched.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.name}
        />
        <Input
          className="mb-4"
          inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
          label="Email"
          name="email"
          type="email"
          value={formik.values.email}
          touched={formik.touched.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
        />
        <Input
          className="mb-4"
          inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
          label="Telephone"
          name="phone"
          type="text"
          value={formik.values.phone}
          touched={formik.touched.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.phone}
        />
        <div className="mt-5 flex justify-end">
          <Button.AdminPanelButton
            type="submit"
            isSave={true}
            loading={formik.isSubmitting}
            bgColor="#515151"
            textColor="#FFFFFF"
            color="white">
            Save
          </Button.AdminPanelButton>
        </div>
      </form>
    </div>
  );
};

export default OrgContact;
