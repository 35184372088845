import { useParams } from "react-router-dom";
import { OrgTheme } from "../components/admin/organization";
import { useState, useEffect, useContext } from "react";
import { ProfileContext } from "../contexts/profileContext";
import { PageWrapper } from "../components/admin";

export default function OrganisationTheme() {
  const [profile] = useContext(ProfileContext);
  const [org, setOrg] = useState(null);

  const { orgId } = useParams();
  const { organizations } = profile;

  useEffect(() => {
    if (!orgId || !organizations?.length) return;
    const org = organizations.find((org) => org._id === orgId);
    if (!org) return;

    setOrg({ ...org });
  }, [orgId, organizations]);
  return (
    <div>
      <PageWrapper pageHeading={"Organisation Theme"}>
        <OrgTheme prefilledData={org} />
      </PageWrapper>
    </div>
  );
}
