import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import { PageWrapper } from "../components/admin";
import { ProfileContext } from "../contexts/profileContext";
import { createAdmin, getPermissions } from "../service";
import { subAdminAccountSchema } from "../schemas/schemas";
import { Button, Input } from "../components/form";

export default function AddSubAdmin() {
  const navigate = useNavigate();
  const [profile, setProfile] = useContext(ProfileContext);

  const [permissions, setPermissions] = useState([]);

  let pageHeading = "Add Sub-Admin Account";
  const getFormInitialValues = () => {
    return {
      email: "",
      permissions: []
    };
  };

  const handleSubmit = async (formValues, { resetForm }) => {
    try {
      const res = await createAdmin(formValues);
      toast.success(res.data);
      resetForm();
      setProfile({ ...profile, reload: new Date().getTime() });
      navigate("/dashboard/sub-admin");
    } catch (e) {
      toast.error("Error while adding a sub-admin account", e);
      console.error("error", e);
    }
  };

  const formik = useFormik({
    initialValues: getFormInitialValues(),
    validationSchema: subAdminAccountSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    getMasterAdminPermissions();
  }, []);

  const getMasterAdminPermissions = async () => {
    try {
      const { data } = await getPermissions();
      setPermissions(data);
    } catch (e) {
      toast.error("Error while getting permissions", e);
      console.error("error", e);
    }
  };

  const handlePermssionClick = (id) => {
    const permissionExists = permissions.find((permission) => permission._id === id);
    if (!permissionExists) return;

    const formValuesPermissions = formik.values.permissions;
    const permissionIndex = formValuesPermissions.findIndex((permission) => permission === id);
    if (permissionIndex === -1) {
      formValuesPermissions.push(id);
    } else {
      formValuesPermissions.splice(permissionIndex, 1);
    }
    formik.setFieldValue("permissions", formValuesPermissions);
  };

  return (
    <PageWrapper pageHeading={pageHeading}>
      <form onSubmit={formik.handleSubmit}>
        <Input
          label="Sub-Admin Email"
          placeholder="please enter a email"
          type="email"
          name="email"
          formik={formik}
          isDark={true}
          className={"mb-2"}
          onChange={formik.handleChange}
          error={formik.errors.email}
          value={formik.values.email}
        />
        <div className="my-5">
          {permissions.map((permission) => (
            <button
              key={permission._id}
              type="button"
              onClick={() => handlePermssionClick(permission._id)}
              className={`flex items-center justify-between w-full rounded-3xl px-3 py-2 my-2 ${
                formik.values.permissions.includes(permission._id)
                  ? "bg-corange-100"
                  : "bg-gray-200"
              }`}>
              <p>{permission.name}</p>{" "}
              {formik.values.permissions.includes(permission._id) && (
                <div className="w-8 h-8 p-2 rounded-full ml-4">
                  <FaCheck className="text-white" />
                </div>
              )}
            </button>
          ))}
        </div>
        <Button.AdminPanelButton
          type="submit"
          isSave={true}
          loading={formik.isSubmitting}
          textColor="black">
          save
        </Button.AdminPanelButton>
      </form>
    </PageWrapper>
  );
}
