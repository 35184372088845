import { useState } from "react";
import { toast } from "react-toastify";
import { Input } from "../../form";
import { TrashIcon } from "@heroicons/react/24/outline";
import { VerticalIcon } from "../../../asssets/icons";
import { deleteAcademy, updateAcademy } from "../../../service";
import { useFormik } from "formik";
import AdminPanelButton from "../../form/button/adminPanelButton";
import { BaseModal } from "../../popups";
import ButtonOutlined from "../../form/button/buttonOutlined";

export default function OrgAcademy({ academy, getAdminAcademies, updatedOrderKey = [] }) {
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const submitHandler = async () => {
    setLoading(true);

    try {
      const postBody = {
        ...formik.values,
        orderKey: academy.orderKey
      };

      const res = await updateAcademy(
        { ...postBody, _id: academy._id },
        academy.masterOrganization
      );

      updatedOrderKey.forEach(async (item) => {
        try {
          await updateAcademy({ ...item }, item.masterOrganization);
        } catch (err) {
          console.log(err);
        }
      });
      toast.success(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteAcademy(academy.masterOrganization, { _id: academy._id });
      getAdminAcademies();
      toast.success("Academy deleted succesfully");
      setDeleteOpen(!deleteOpen);
    } catch (err) {
      toast.error("Error while deleting academy");
    }
  };

  const formik = useFormik({
    initialValues: {
      name_de: academy?.name_de,
      name_en: academy?.name_en,
      name_fr: academy?.name_fr,
      name_it: academy?.name_it
    },
    onSubmit: submitHandler
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-between items-center  w-[100%]">
          <div className="flex items-center">
            <div className="mr-2">
              <VerticalIcon />
            </div>
            <div className="flex flex-col">
              <div className="flex">
                <div>
                  EN
                  <Input
                    name="name_en"
                    formik={formik}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values["name_en"]}
                    error={formik?.errors["name_en"]}
                    touched={formik?.touched["name_en"]}
                  />
                </div>
                <div>
                  DE{" "}
                  <Input
                    name="name_de"
                    formik={formik}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values["name_de"]}
                    error={formik?.errors["name_de"]}
                    touched={formik?.touched["name_de"]}
                  />
                </div>
              </div>
              <div className="flex">
                <div>
                  FR{" "}
                  <Input
                    name="name_fr"
                    formik={formik}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values["name_fr"]}
                    error={formik?.errors["name_fr"]}
                    touched={formik?.touched["name_fr"]}
                  />
                </div>
                <div>
                  IT{" "}
                  <Input
                    name="name_it"
                    formik={formik}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values["name_it"]}
                    error={formik?.errors["name_it"]}
                    touched={formik?.touched["name_it"]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center ">
            <button
              className=""
              type="button"
              onClick={() => {
                setDeleteOpen(!deleteOpen);
              }}>
              <TrashIcon className="w-[1rem] h-[1rem] mr-2" />
            </button>
          </div>
          <div>
            <AdminPanelButton
              type="submit"
              isSave={true}
              loading={loading}
              bgColor="#FC9E5A"
              textColor="#FFFFFF"
              color="white"
              onClick={() => {}}>
              Save
            </AdminPanelButton>
          </div>
        </div>
      </form>

      <BaseModal open={deleteOpen} setOpen={setDeleteOpen} title={"Delete Academy"}>
        <h1 className="text-center mb-4">Sure you want to delete?</h1>
        <div className="flex ">
          <div className="grow text-right pr-5">
            <ButtonOutlined
              type="button"
              className="bg-orange-500 text-white border-2 border-orange-500"
              handler={() => {
                handleDelete();
              }}>
              yes
            </ButtonOutlined>
          </div>
          <div className="grow">
            <ButtonOutlined
              type="button"
              handler={() => {
                setDeleteOpen(!deleteOpen);
              }}>
              No
            </ButtonOutlined>
          </div>
        </div>
      </BaseModal>
    </>
  );
}
