import React from "react";

export default function ButtonOutlined({ className, children, type, handler, disabled }) {
  return (
    <button
      className={`px-4 py-2 rounded font-bold border border-black ${className} disabled:opacity-60`}
      type={type}
      disabled={disabled}
      onClick={() => handler()}>
      {children}
    </button>
  );
}
