import React, { useContext, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { toast } from "react-toastify";
import { ProfileContext } from "../../../contexts/profileContext";

import { FaCheck } from "react-icons/fa";
import { updateOrganizationTheme } from "../../../service";

import { Button, InputSelect } from "../../form";

export const valueOption = [
  { label: "25%", value: "25%" },
  { label: "50%", value: "50%" },
  { label: "75%", value: "75%" },
  { label: "100%", value: "100%" }
];

const OrgTheme = ({ prefilledData }) => {
  const [profile, setProfile] = useContext(ProfileContext);

  const [primary, setPrimary] = useState("#007aff");
  const [primaryVariant, setPrimaryVariant] = useState("#007aff");
  const [secondary, setSecondary] = useState("#007aff");
  const [secondaryVariant, setSecondaryVariant] = useState("#5ac8fa");
  const [tertiary, setTertiary] = useState("#616161");
  const [tertiaryVariant, setTertiaryVariant] = useState("#616161");

  const [mfPrimary, setMfPrimary] = useState("#007aff");
  const [mfPrimaryVariant, setMfPrimaryVariant] = useState("#007aff");
  const [mfSecondary, setMfSecondary] = useState("#007aff");
  const [mfSecondaryVariant, setMfSecondaryVariant] = useState("#5ac8fa");
  const [mfTertiary, setMfTertiary] = useState("#616161");
  const [mfTertiaryVariant, setMfTertiaryVariant] = useState("#616161");

  const [buttonRadius, setButtonRadius] = useState(null);
  const [dropShadowColor, setDropShadowColor] = useState("#616161");
  const [intensityPrimary, setintensityPrimary] = useState({
    label: "25%",
    value: "25%"
  });
  const [intensitySecondary, setintensitySecondary] = useState({
    label: "25%",
    value: "25%"
  });
  const [intensityTertiary, setIntensityTertiary] = useState({
    label: "25%",
    value: "25%"
  });
  const [mfIntensityPrimary, setMfintensityPrimary] = useState({
    label: "25%",
    value: "25%"
  });
  const [mfIntensitySecondary, setMfintensitySecondary] = useState({
    label: "25%",
    value: "25%"
  });
  const [mfIntensityTertiary, setMfIntensityTertiary] = useState({
    label: "25%",
    value: "25%"
  });
  const [intensityDropShadow, setIntensityDropShadow] = useState({
    label: "25%",
    value: "25%"
  });

  const pickerMethods = [];

  const [loading, setLoading] = useState(false);

  const udpateOrgThemeHandler = async () => {
    setLoading(true);
    try {
      if (!prefilledData?._id) return;

      const putBody = {
        id: prefilledData._id,
        primary,
        primaryVariant,
        intensityPrimary: intensityPrimary.value,
        secondary,
        secondaryVariant,
        intensitySecondary: intensitySecondary.value,
        tertiary,
        tertiaryVariant,
        intensityTertiary: intensityTertiary.value,
        mf: {
          primary: mfPrimary,
          primaryVariant: mfPrimaryVariant,
          intensityPrimary: mfIntensityPrimary.value,
          secondary: mfSecondary,
          secondaryVariant: mfSecondaryVariant,
          intensitySecondary: mfIntensitySecondary.value,
          tertiary: mfTertiary,
          tertiaryVariant: mfTertiaryVariant,
          intensityTertiary: mfIntensityTertiary.value
        },
        buttonRadius,
        dropShadowColor,
        intensityDropShadow: intensityDropShadow.value
      };

      await updateOrganizationTheme(putBody);

      toast.success("Theme updated!");

      // Reload data
      setProfile({ ...profile, reload: new Date().getTime() });
    } catch (error) {
      console.error("Update organization theme error", error);
      toast.error("Error while update organization theme:", error);
    } finally {
      setLoading(false);
    }
  };
  const parentFunction2 = (m, i, p) => {
    if (pickerMethods[i]) {
      pickerMethods[i](!p);
    } else {
      m(!p);

      pickerMethods[i] = m;
    }
    pickerMethods.map((ele, ind) => {
      if (i == ind) {
        return;
      } else {
        ele(false);
      }
    });
  };

  const handleChange = (e, type) => {
    switch (type) {
      case "primary":
        setintensityPrimary(e);
        break;

      case "secondary":
        setintensitySecondary(e);
        break;

      case "teritary":
        setIntensityTertiary(e);
        break;

      case "mfPrimary":
        setMfintensityPrimary(e);
        break;

      case "mfSecondary":
        setMfintensitySecondary(e);
        break;

      case "mfTeritary":
        setMfIntensityTertiary(e);
        break;

      case "dropShadow":
        setIntensityDropShadow(e);
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    if (!prefilledData?._id) return;

    const { customConfigurations } = prefilledData;

    setPrimary(customConfigurations?.colors?.primary || "#007aff");
    setPrimaryVariant(customConfigurations?.colors?.primaryVariant || "#007aff");
    setSecondary(customConfigurations?.colors?.secondary || "#007aff");
    setSecondaryVariant(customConfigurations?.colors?.secondaryVariant || "#5ac8fa");
    setTertiary(customConfigurations?.colors?.tertiary || "#616161");
    setTertiaryVariant(customConfigurations?.colors?.tertiaryVariant || "#616161");

    setMfPrimary(customConfigurations?.colors?.mf?.primary || "#007aff");
    setMfPrimaryVariant(customConfigurations?.colors?.mf?.primaryVariant || "#007aff");
    setMfSecondary(customConfigurations?.colors?.mf?.secondary || "#007aff");
    setMfSecondaryVariant(customConfigurations?.colors?.mf?.secondaryVariant || "#5ac8fa");
    setMfTertiary(customConfigurations?.colors?.mf?.tertiary || "#616161");
    setMfTertiaryVariant(customConfigurations?.colors?.mf?.tertiaryVariant || "#616161");

    setintensityPrimary({
      label: customConfigurations?.colors?.intensityPrimary || "25%",
      value: customConfigurations?.colors?.intensityPrimary || "25%"
    });
    setintensitySecondary({
      label: customConfigurations?.colors?.intensitySecondary || "25%",
      value: customConfigurations?.colors?.intensitySecondary || "25%"
    });
    setIntensityTertiary({
      label: customConfigurations?.colors?.intensityTertiary || "25%",
      value: customConfigurations?.colors?.intensityTertiary || "25%"
    });

    setMfintensityPrimary({
      label: customConfigurations?.colors?.mf?.intensityPrimary || "25%",
      value: customConfigurations?.colors?.mf?.intensityPrimary || "25%"
    });
    setMfintensitySecondary({
      label: customConfigurations?.colors?.mf?.intensitySecondary || "25%",
      value: customConfigurations?.colors?.mf?.intensitySecondary || "25%"
    });
    setMfIntensityTertiary({
      label: customConfigurations?.colors?.mf?.intensityTertiary || "25%",
      value: customConfigurations?.colors?.mf?.intensityTertiary || "25%"
    });

    setIntensityDropShadow({
      label: customConfigurations?.colors?.intensityDropShadow || "25%",
      value: customConfigurations?.colors?.intensityDropShadow || "25%"
    });
    setButtonRadius(customConfigurations?.colors?.buttonRadius || "0px");
    setDropShadowColor(customConfigurations?.colors?.dropShadowColor || "#616161");
  }, [prefilledData?._id]);

  return (
    <>
      <div className="mb-[30px] flex justify-between">
        <div className="w-full space-y-12">
          <div>
            <h3 className="font-bold text-xl mb-2">Primary Colour Selection</h3>
            <div className="border-2 rounded-md p-4 mr-8">
              <p className="text-base font-bold text-[#191919] mb-2">Primary Colour</p>
              <ColorPicker
                color={primary}
                setColor={setPrimary}
                z={6}
                index={0}
                parentFunction2={parentFunction2}
              />
              <p className="text-base font-bold text-[#191919] mb-2 mt-8">Primary Variation</p>
              <div className="flex ">
                <div>
                  <ColorPicker
                    color={primaryVariant}
                    setColor={setPrimaryVariant}
                    z={5}
                    index={1}
                    parentFunction2={parentFunction2}
                  />
                </div>
                <div className="ml-10">
                  <InputSelect
                    value={intensityPrimary}
                    options={valueOption}
                    onChange={(e) => handleChange(e, "primary")}
                  />
                </div>
              </div>
              <p className="text-base font-bold text-[#191919] mb-2 mt-8">Secondary Colour</p>
              <ColorPicker
                color={secondary}
                setColor={setSecondary}
                z={4}
                index={2}
                parentFunction2={parentFunction2}
              />
              <p className="text-base font-bold text-[#191919] mb-2 mt-4">Secondary Variation</p>
              <div className="flex ">
                <div>
                  <ColorPicker
                    color={secondaryVariant}
                    setColor={setSecondaryVariant}
                    z={3}
                    index={3}
                    parentFunction2={parentFunction2}
                  />
                </div>
                <div className="ml-10">
                  <InputSelect
                    value={intensitySecondary}
                    options={valueOption}
                    onChange={(e) => handleChange(e, "secondary")}
                  />
                </div>
              </div>
              <p className="text-base font-bold text-[#191919] mb-2 mt-8">Tertiary Colour</p>
              <ColorPicker
                color={tertiary}
                setColor={setTertiary}
                z={2}
                index={4}
                parentFunction2={parentFunction2}
              />
              <p className="text-base font-bold text-[#191919] mb-2 mt-4">Tertiary Variation</p>
              <div className="flex ">
                <div>
                  <ColorPicker
                    color={tertiaryVariant}
                    setColor={setTertiaryVariant}
                    z={1}
                    index={5}
                    parentFunction2={parentFunction2}
                  />
                </div>
                <div className="ml-10">
                  <InputSelect
                    value={intensityTertiary}
                    options={valueOption}
                    onChange={(e) => handleChange(e, "teritary")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3 className="font-bold text-xl mb-2">mf Colour Selection</h3>
            <div className="border-2 rounded-md p-4 mr-8">
              <p className="text-base font-bold text-[#191919] mb-2">Primary Colour</p>
              <ColorPicker
                color={mfPrimary}
                setColor={setMfPrimary}
                z={6}
                index={0}
                parentFunction2={parentFunction2}
              />
              <p className="text-base font-bold text-[#191919] mb-2 mt-8">Primary Variation</p>
              <div className="flex ">
                <div>
                  <ColorPicker
                    color={mfPrimaryVariant}
                    setColor={setMfPrimaryVariant}
                    z={5}
                    index={1}
                    parentFunction2={parentFunction2}
                  />
                </div>
                <div className="ml-10">
                  <InputSelect
                    value={mfIntensityPrimary}
                    options={valueOption}
                    onChange={(e) => handleChange(e, "mfPrimary")}
                  />
                </div>
              </div>
              <p className="text-base font-bold text-[#191919] mb-2 mt-8">Secondary Colour</p>
              <ColorPicker
                color={mfSecondary}
                setColor={setMfSecondary}
                z={4}
                index={2}
                parentFunction2={parentFunction2}
              />
              <p className="text-base font-bold text-[#191919] mb-2 mt-4">Secondary Variation</p>
              <div className="flex ">
                <div>
                  <ColorPicker
                    color={mfSecondaryVariant}
                    setColor={setMfSecondaryVariant}
                    z={3}
                    index={3}
                    parentFunction2={parentFunction2}
                  />
                </div>
                <div className="ml-10">
                  <InputSelect
                    value={mfIntensitySecondary}
                    options={valueOption}
                    onChange={(e) => handleChange(e, "mfSecondary")}
                  />
                </div>
              </div>
              <p className="text-base font-bold text-[#191919] mb-2 mt-8">Tertiary Colour</p>
              <ColorPicker
                color={mfTertiary}
                setColor={setMfTertiary}
                z={2}
                index={4}
                parentFunction2={parentFunction2}
              />
              <p className="text-base font-bold text-[#191919] mb-2 mt-4">Tertiary Variation</p>
              <div className="flex ">
                <div>
                  <ColorPicker
                    color={mfTertiaryVariant}
                    setColor={setMfTertiaryVariant}
                    z={1}
                    index={5}
                    parentFunction2={parentFunction2}
                  />
                </div>
                <div className="ml-10">
                  <InputSelect
                    value={mfIntensityTertiary}
                    options={valueOption}
                    onChange={(e) => handleChange(e, "mfTeritary")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="border-2 rounded-md p-4 mr-8">
            <p className="text-base font-bold text-[#191919] mb-2">Button Style</p>
            <p className="text-base text-[#757575] mb-2 font-bold">Corner Radius</p>
            <div className="flex flex-col">
              <RadiusOption
                radius="9999px"
                label="9999px"
                setButtonRadius={setButtonRadius}
                buttonRadius={buttonRadius}
              />
              <RadiusOption
                radius="0.75rem"
                label="0.75rem"
                setButtonRadius={setButtonRadius}
                buttonRadius={buttonRadius}
              />
              <RadiusOption
                radius="0px"
                label="0px"
                setButtonRadius={setButtonRadius}
                buttonRadius={buttonRadius}
              />
            </div>

            <p className="text-base font-bold text-[#191919] mb-2 mt-4">Drop Shadow</p>
            <p>Drop Shadow Colour</p>
            <div className="flex ">
              <div>
                <ColorPicker
                  color={dropShadowColor}
                  setColor={setDropShadowColor}
                  z={1}
                  index={6}
                  parentFunction2={parentFunction2}
                />
              </div>
              <div className="ml-10">
                <InputSelect
                  value={intensityDropShadow}
                  options={valueOption}
                  onChange={(e) => handleChange(e, "dropShadow")}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button.AdminPanelButton
            type="submit"
            isSave={true}
            loading={loading}
            bgColor="#515151"
            textColor="#FFFFFF"
            color="white"
            onClick={udpateOrgThemeHandler}>
            Save
          </Button.AdminPanelButton>
        </div>
      </div>
    </>
  );
};

const RadiusOption = ({ radius, label, setButtonRadius, buttonRadius }) => (
  <div className="flex items-center mt-5">
    <div
      className={`w-[200px] h-[50px] border-2 border-[#ABABAB] bg-[#ABABAB]`}
      style={{ borderRadius: radius }}></div>
    <input type="checkbox" className="hidden" />
    <div
      onClick={() => setButtonRadius(buttonRadius === label ? null : label)}
      className="w-[20px] h-[20px] bg-black ml-3 flex items-center justify-center rounded-full cursor-pointer">
      {buttonRadius === label && <FaCheck style={{ color: "white" }} />}
    </div>
  </div>
);

const ColorPicker = ({ color, setColor, z, index, parentFunction2 = () => {} }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(index);

  return (
    <div className="flex items-center gap-4 relative" style={{ zIndex: z }}>
      <div
        className="pickerCircle w-10 h-10 rounded-full relative"
        style={{ backgroundColor: color }}
        onClick={(e) => {
          if (
            e.target.classList.contains("pickerCircle") ||
            e.target.classList.contains("colorPickerCover")
          )
            parentFunction2(setShowPicker, currentIndex, showPicker);
        }}>
        {showPicker && (
          <>
            <div className="colorPickerCover fixed top-0 left-0 right-0 bottom-0" />
            <div className="absolute left-[100%] top-[100%]">
              <SketchPicker color={color} onChange={({ hex }) => setColor(hex)} />
            </div>
          </>
        )}
      </div>
      <input
        type="text"
        placeholder="#163F9B"
        value={color}
        onChange={(e) => setColor(e.target.value)}
        className="flex-grow border-none px-4 py-2 rounded-full bg-gray-100 focus:border-none focus:ring-0 focus:outline-none"
      />
    </div>
  );
};

export default OrgTheme;
