import { RxCross1 } from 'react-icons/rx';

const CrossButton = (props) => (
  <section className="px-5 py-5">
    <button className="bg-cblue-500 p-4 rounded-full text-white z-20" {...props}>
      <RxCross1 className="icon" />
    </button>
  </section>
);

export default CrossButton;
