export default function ItemWithSingleButton({ children, classNames, handleClick, icon }) {
  return (
    <div
      className={`flex justify-between items-center my-2 px-4 py-3.5 rounded bg-[#E3E3E3] shadow-sm ${classNames} `}>
      <div className="">
        <p className="text-lg text-[#191919]">{children}</p>
      </div>
      <div className="cursor-pointer" onClick={handleClick}>
        {icon}
      </div>
    </div>
  );
}
