import React from "react";

import { PageWrapper } from "../components/admin";
import { OrgInfo } from "../components/admin/organization";

export default function CreateOrganization() {
  let pageHeading = "Create Organization";
  let pageSubheading =
    "Enter all the details and then click on the save button to create a new organization.";

  return (
    <PageWrapper pageHeading={pageHeading} pageSubheading={pageSubheading}>
      <OrgInfo />
    </PageWrapper>
  );
}
