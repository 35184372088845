import React, { useState, useEffect } from "react";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";
import { ToggleBack } from "../../asssets/icons";

const ToggleComponent = ({ handleCompToggle: onToggle, element, isToggle, dataTestid }) => {
  const [isToggled, setIsToggled] = useState(isToggle);

  const handleToggle = () => {
    setIsToggled((prev) => !prev);
    onToggle(element, !isToggled);
  };

  useEffect(() => {
    setIsToggled(isToggle);
  }, [isToggle]);

  return (
    <>
      <div className="cursor-pointer select-none">
        {isToggled ? (
          <FaToggleOn onClick={handleToggle} color="green" size={30} data-testid={dataTestid} />
        ) : (
          <div onClick={handleToggle} data-testid={dataTestid}>
            <FaToggleOff color="gray" size={30} />
          </div>
        )}
      </div>
    </>
  );
};

export default ToggleComponent;
