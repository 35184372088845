import { getUploadingFileData, uploadFileToAWS } from "../service";

export const uploadFileIfNeeded = async (file, basePath) => {
  if (!file?.type) return file;

  const timestamp = new Date().getTime();
  const {
    data: { url, finalName }
  } = await getUploadingFileData({
    name: `${basePath}/${timestamp}-${file.name}`,
    type: file.type
  });

  await uploadFileToAWS(url, file, file.type);
  return finalName;
};
