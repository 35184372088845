import clsx from "clsx";

const MenuItem = ({ children, className, isSelected = false, onClick }) => {
  return (
    <div
      onClick={onClick ? onClick : null}
      className={clsx(
        `hover:bg-corange-100 rounded md:px-4 px-2 flex gap-5 justify-start items-center cursor-pointer group duration-300 ease-in-out relative`,
        { [`bg-corange-100`]: isSelected },
        { "bg-white": !isSelected },
        className
      )}>
      {children}
    </div>
  );
};

export default MenuItem;
