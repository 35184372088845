import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { ProfileContext } from "../../../contexts/profileContext";
import Menu from "./sidebarMenu";
import Header from "./header";
import { CancelIconWhite } from "../../../asssets/icons";

const SideBar = ({ isSidebarOpen = false, toggleSidebar }) => {
  const navigate = useNavigate();

  const [profile] = useContext(ProfileContext);

  const handleNewOrgClick = () => {
    navigate("/dashboard/organisation");
  };

  const handleLogout = () => {
    localStorage.removeItem("masterToken");
    navigate("/");
  };

  return (
    <>
      <div
        className={clsx(
          "relative",
          "flex-col justify-between",
          "h-screen",
          "overflow-hidden",
          "overflow-y-auto",
          "mini-scrollbar",
          "bg-white flex-shrink-0 px-5 md:px-2 z-10 h-auto hidden lg:w-[366px] lg:flex xl:w-[388px] lg:ml-4 lg:rounded-b lg:px-5 lg:shadow-lg",
          { "!flex relative": isSidebarOpen }
        )}>
        {isSidebarOpen && (
          <button
            className={`lg:hidden bg-pink absolute right-5 top-5 bg-corange-100 rounded-md`}
            onClick={toggleSidebar}>
            {CancelIconWhite}
          </button>
        )}
        <div className="pt-5">
          <Header email={profile.email} role={"Master Admin"} />
          <div className="h-[60vh] mt-[1vh] overflow-y-auto">
            <Menu items={profile?.organizations ?? []} />
          </div>
        </div>
        <div>
          <button
            onClick={handleNewOrgClick}
            className="w-full gap-4 bg-gray-50 rounded cursor-pointer flex items-center px-4 py-3 font-bold text-gray-900 md:mt-10 shadow-sm">
            <PlusSmallIcon
              className={`w-7 h-7 stroke-2 stroke-white bg-corange-100 rounded-full`}
            />
            <span>Add Organization</span>
          </button>

          <div className="text-center mt-6">
            <button
              onClick={handleLogout}
              className="text-xl font-semibold text-center underline text-gray-900 mb-10">
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
