import { PencilIcon, PlusIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ProfileCard } from "@psyfiers-it/ui-lib";

import { ArrowBackIcon } from "../../../../../asssets/icons";
import {
  createNewProfileType,
  getBadgeUIConfig,
  getServiceDetails,
  getSystemAdminUserGroups,
  updateProfileType,
  updateBadgeUIConfig,
  uploadFileToAWS,
  getUploadingFileData
} from "../../../../../service";
import ToggleComponent from "../../../../common/toggleComponent";
import { Button, Input } from "../../../../form";
import { BaseModal } from "../../../../popups";
import PersonalInformationFormEditor from "./personalInformation";
import VideoEditor from "./videoEditor";
import { ColorPicker } from "../../../../common";
import InputSelect from "../../../../form/InputSelect";

export default function Profiles({ title, org, currentService, setShowComponent }) {
  const [profileName, setProfileName] = useState("");
  const [showNewProfileModal, setShowNewProfileModal] = useState(false);
  const [isNewProfileSubmitting, setIsNewProfileSubmitting] = useState(false);
  const [profileSubmittingId, setProfileSubmittingId] = useState(null);
  const [profileList, setProfilesList] = useState([]);
  const [editProfileNameId, setEditProfileNameId] = useState(null);
  const [isVideoEditorModalOpen, setIsVideoEditorModalOpen] = useState(false);
  const [isPersonalInfoEditorModalOpen, setIsPersonalInfoEditorModalOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [systemAdminGroups, setSystemAdminGroups] = useState([]);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [organizationBadgeUIConfig, setOrganizationBadgeUIConfig] = useState({});

  const fetchAllProfiles = async () => {
    try {
      const { data } = await getServiceDetails(org._id, currentService._id);
      const fetchedProfilesList =
        data.profiles.map((profile) => ({
          ...profile,
          badgeUIConfig: { ...profile.badgeUIConfig?.config }
        })) || [];

      console.log("fetchedProfilesList", fetchedProfilesList);
      setProfilesList(fetchedProfilesList);
    } catch (error) {
      toast.error("Failed to load profiles types.");
      console.error("Failed to load profiles types:", error);
    }
  };

  const fetchAllSystemAdminGroups = async () => {
    try {
      const { data: userGroupsData } = await getSystemAdminUserGroups(org._id);
      const temp = [];
      userGroupsData.forEach((group) => {
        if (!group?.groups) return;

        group.groups.forEach((subGroup) => {
          temp.push({ label: `${group.name} | ${subGroup.name}`, value: subGroup._id });
        });
      });
      setSystemAdminGroups(temp);
    } catch (error) {
      toast.error("Failed to load system admin groups.");
      console.error("Failed to load system admin groups:", error);
    }
  };

  useEffect(() => {
    fetchAllProfiles();
    fetchAllSystemAdminGroups();
  }, [org]);

  const handleNewProfileCreate = async (e) => {
    e.preventDefault();

    try {
      setIsNewProfileSubmitting(true);
      await createNewProfileType(org._id, {
        name: profileName,
        serviceId: currentService._id
      });
      toast.success("New profile type created successfully.");
      setShowNewProfileModal(false);
      setProfileName("");
      fetchAllProfiles();
    } catch (error) {
      toast.error("Failed to create new profile type.");
      console.error("Failed to create new profile type: ", error);
    } finally {
      setIsNewProfileSubmitting(false);
    }
  };

  const handleFeatureToggle = (profileIndex, feature) => {
    const updatedProfilesList = JSON.parse(JSON.stringify(profileList));
    updatedProfilesList[profileIndex][feature] = !updatedProfilesList[profileIndex][feature];
    setProfilesList(updatedProfilesList);
  };

  const handleProfileTypeUpdate = async (profile) => {
    try {
      if (profileSubmittingId) {
        return;
      }

      let updatedProfileName = profileName?.trim() === "" ? profile.name : profileName;

      const {
        badgeUIConfig: { cornerImageObj, ...badgeUIConfig },
        ...profileRemaining
      } = profile;

      let secondaryImage = badgeUIConfig?.secondaryImage;
      if (cornerImageObj) {
        console.log("organizationBadgeUIConfig", cornerImageObj);
        secondaryImage = await uploadSecondaryImage(cornerImageObj, profileRemaining._id);
      }

      setProfileSubmittingId(profileRemaining._id);
      await updateProfileType(org._id, {
        ...profileRemaining,
        name: updatedProfileName,
        badgeUIConfig: { ...badgeUIConfig, secondaryImage }
      });
      toast.success("Profile information updated successfully.");
      setProfileName("");
      setEditProfileNameId(null);
      fetchAllProfiles();
    } catch (error) {
      toast.error("Failed to update profile information.");
      console.error("Failed to update profile information: ", error);
    } finally {
      setProfileSubmittingId(null);
    }
  };

  const handleEditProfileNameClick = (profile) => {
    setProfileName(profile.name);
    setEditProfileNameId(profile._id);
  };

  const showVideoEditorModal = (profile) => {
    setSelectedProfile(profile);
    setIsVideoEditorModalOpen(!isVideoEditorModalOpen);
  };

  const updateBadgeUI = (event, profileIndex, name, subname, isOrganization) => {
    let badgeUIConfig = {};
    let updatedProfilesList = {};
    if (isOrganization) {
      badgeUIConfig = JSON.parse(JSON.stringify(organizationBadgeUIConfig));
    } else {
      updatedProfilesList = JSON.parse(JSON.stringify(profileList));
      badgeUIConfig = profileList[profileIndex].badgeUIConfig || { ringGradientColor: {} };
    }

    if (subname) {
      if (!badgeUIConfig[name]) badgeUIConfig[name] = {};
      badgeUIConfig[name][subname] = event.hex;
    } else {
      badgeUIConfig[name] = event.hex;
    }

    if (isOrganization) {
      setOrganizationBadgeUIConfig({ ...badgeUIConfig });
      return;
    } else {
      updatedProfilesList[profileIndex].badgeUIConfig = badgeUIConfig;
      setProfilesList(updatedProfilesList);
    }
  };

  const getCurrentBadgeUI = async (organizationId) => {
    try {
      const { data } = await getBadgeUIConfig(organizationId);
      const badgeUI = data?.badgeUIConfig || {};
      setOrganizationBadgeUIConfig(badgeUI);
    } catch (error) {
      toast.error("Failed to get badge UI");
      console.error("Failed to get badge UI: ", error);
    }
  };

  useEffect(() => {
    if (currentOrganization) {
      getCurrentBadgeUI(currentOrganization.value);
    }
  }, [currentOrganization]);

  const uploadSecondaryImage = async (image, organisation) => {
    try {
      const fileName = "badge-ui" + "/" + organisation + "/" + image.name;
      const {
        data: { url }
      } = await getUploadingFileData({
        name: fileName,
        type: image.type
      });
      const resp = await uploadFileToAWS(url, image, image.type);
      console.log("resp", resp);
      return fileName;
    } catch (error) {
      console.error("Failed to upload secondary image: ", error);
      toast.error("Failed to upload secondary image");
    }
  };

  const updateCurrentBadgeUI = async () => {
    try {
      if (!currentOrganization) {
        toast.error("Please select a group");
        return;
      }

      const { cornerImageObj, ...badgeUIConfig } = organizationBadgeUIConfig;

      let secondaryImage = badgeUIConfig?.secondaryImage;
      if (cornerImageObj) {
        console.log("organizationBadgeUIConfig", cornerImageObj);
        secondaryImage = await uploadSecondaryImage(cornerImageObj, currentOrganization.value);
      }

      await updateBadgeUIConfig(currentOrganization.value, {
        badgeUIConfig: { ...badgeUIConfig, secondaryImage }
      });
      toast.success("Badge UI updated successfully.");
    } catch (error) {
      toast.error("Failed to update badge UI");
      console.error("Failed to update badge UI: ", error);
    }
  };

  const handleImageSelect = (file, index, isOrganization, isRemoval) => {
    if (isOrganization) {
      const currentObject = JSON.parse(JSON.stringify(organizationBadgeUIConfig));
      currentObject.cornerImageObj = file;
      if (isRemoval) currentObject.secondaryImage = null;
      setOrganizationBadgeUIConfig(currentObject);
    } else {
      const profileListTemp = [...profileList];
      profileListTemp[index].badgeUIConfig.cornerImageObj = file;
      if (isRemoval) profileListTemp[index].badgeUIConfig.secondaryImage = null;

      setProfilesList([...profileListTemp]);
    }
  };

  return (
    <>
      <button
        className="flex gap-3 items-center w-[fit-content] flex-wrap mb-10"
        onClick={setShowComponent}>
        <span className="w-8 h-8">
          <ArrowBackIcon />
        </span>
        <h2 className="text-xl leading-10 font-bold">{title ?? "Profiles"}</h2>
      </button>

      <div className="w-full pl-8">
        {profileList.map((profile, profileIndex) => (
          <div key={profile._id} className="border-2 rounded p-4 mb-8">
            <div className="flex justify-between">
              <div className="mt-5">
                {editProfileNameId === profile._id ? (
                  <Input
                    name="edit-name"
                    onChange={(e) => setProfileName(e.target.value)}
                    value={profileName || ""}
                  />
                ) : (
                  <h2>{profile.name}</h2>
                )}
              </div>
              <div className="mt-5 flex items-center">
                {editProfileNameId !== profile._id && (
                  <PencilIcon
                    className="w-[1rem] h-[1rem] mr-2 cursor-pointer"
                    onClick={() => handleEditProfileNameClick(profile)}
                  />
                )}
                <ToggleComponent
                  handleCompToggle={() => handleFeatureToggle(profileIndex, "isActive")}
                  element="is-active"
                  isToggle={profile.isActive}
                />
              </div>
            </div>
            <div className="mt-10">
              <h2 className="font-semibold mt-5">Profile Setup</h2>
              <div className="flex justify-between">
                <h2 className="mt-5">Personal Information Form</h2>
                <div className="mt-5 cursor-pointer">
                  <PencilIcon
                    className="w-[1rem] h-[1rem] mr-2"
                    onClick={() => {
                      setSelectedProfile(profile);
                      setIsPersonalInfoEditorModalOpen(true);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <h2 className="mt-5">Video Function</h2>
                <div className="mt-5 flex items-center">
                  <PencilIcon
                    className="w-[1rem] h-[1rem] mr-2 cursor-pointer"
                    onClick={() => showVideoEditorModal(profile)}
                  />
                  <ToggleComponent
                    handleCompToggle={() => handleFeatureToggle(profileIndex, "hasVideoFunction")}
                    element="video-function"
                    isToggle={profile.hasVideoFunction}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <div className="flex justify-between">
                <h2 className="font-semibold">Profile Webpage</h2>
                <ToggleComponent
                  handleCompToggle={() => handleFeatureToggle(profileIndex, "hasProfileWebPage")}
                  element="profile-webpage"
                  isToggle={profile.hasProfileWebPage}
                />
              </div>
            </div>
            <div className="mt-10">
              <div className="flex justify-between">
                <h2 className="font-semibold">Discovery Page</h2>
                <ToggleComponent
                  handleCompToggle={() => handleFeatureToggle(profileIndex, "hasDiscoveryWebPage")}
                  element="discovery-webpage"
                  isToggle={profile.hasDiscoveryWebPage}
                />
              </div>
            </div>

            <BadgeUI
              updateBadgeUI={updateBadgeUI}
              profile={profile}
              profileIndex={profileIndex}
              handleImageSelect={handleImageSelect}
            />

            <div className="flex mt-8 justify-end">
              <Button.AdminPanelButton
                type="submit"
                isSave={true}
                loading={profile._id === profileSubmittingId}
                bgColor="#FC9E5A"
                textColor="#FFFFFF"
                color="white"
                onClick={() => handleProfileTypeUpdate(profile)}>
                Save
              </Button.AdminPanelButton>
            </div>
          </div>
        ))}

        <div className="border-2 rounded p-4 mb-8">
          <div className="flex justify-between">
            <div className="mt-5">
              <h2 className="font-bold mt-5">Group-Specific Badge UI</h2>
            </div>
          </div>
          <div className="mt-10">
            <h2 className="font-semibold mt-5">(S.A) Profile Group</h2>
            <InputSelect
              onChange={setCurrentOrganization}
              value={currentOrganization}
              subheading={"Select Group"}
              className="w-4/6"
              options={systemAdminGroups}
            />
          </div>

          <BadgeUI
            updateBadgeUI={updateBadgeUI}
            profile={{ badgeUIConfig: organizationBadgeUIConfig }}
            isOrganization={true}
            profileIndex={0}
            handleImageSelect={handleImageSelect}
          />

          <div className="flex mt-8 justify-end">
            <Button.AdminPanelButton
              type="submit"
              isSave={true}
              bgColor="#FC9E5A"
              textColor="#FFFFFF"
              color="white"
              onClick={updateCurrentBadgeUI}>
              Save
            </Button.AdminPanelButton>
          </div>
        </div>

        {/* New Profile Type Modal */}
        <BaseModal open={showNewProfileModal} setOpen={setShowNewProfileModal} title={"Enter Name"}>
          <form onSubmit={handleNewProfileCreate}>
            <Input
              label="Title"
              name="name"
              onChange={(e) => setProfileName(e.target.value)}
              value={profileName || ""}
            />
            <div className="mt-5">
              <Button.AdminPanelButton
                type="submit"
                isSave={true}
                loading={isNewProfileSubmitting}
                bgColor="#FC9E5A"
                textColor="#FFFFFF"
                color="white">
                Create
              </Button.AdminPanelButton>
            </div>
          </form>
        </BaseModal>
        {/* New Profile Type Add Button - Triggers Modal */}
        <div
          className="mt-10 cursor-pointer inline-block"
          onClick={() => {
            setShowNewProfileModal(!showNewProfileModal);
          }}>
          <PlusIcon className={`w-7 h-7 stroke-2 stroke-white bg-[#E3E3E3] rounded-full`} />
        </div>
      </div>

      {/* Video Editor */}
      <BaseModal
        open={isVideoEditorModalOpen}
        setOpen={setIsVideoEditorModalOpen}
        title={"Video Editor"}>
        <VideoEditor org={org} profile={selectedProfile} />
      </BaseModal>

      {/* Personal Information Form Editor */}
      <BaseModal
        open={isPersonalInfoEditorModalOpen}
        setOpen={setIsPersonalInfoEditorModalOpen}
        title={"Personal Information Form Editor"}>
        <PersonalInformationFormEditor
          org={org}
          profile={selectedProfile}
          onSubmit={() => {
            fetchAllProfiles();
            setIsPersonalInfoEditorModalOpen(false);
          }}
        />
      </BaseModal>
    </>
  );
}

const BadgeUI = ({ profile, updateBadgeUI, profileIndex, isOrganization, handleImageSelect }) => {
  return (
    <>
      <div className="mt-10 mb-2">
        <div className="flex justify-between">
          <h2 className="font-semibold">Profile Badge UI</h2>
        </div>
      </div>

      <div className="flex justify-between my-2">
        <h2 className="">background Colour</h2>
        <ColorPicker
          color={profile.badgeUIConfig?.primaryColor}
          handleChange={(event) =>
            updateBadgeUI(event, profileIndex, "primaryColor", null, isOrganization)
          }
        />
      </div>
      <div className="flex justify-between my-2">
        <h2 className="">Ring Colour</h2>
        <ColorPicker
          color={profile.badgeUIConfig?.secondaryColor}
          handleChange={(event) =>
            updateBadgeUI(event, profileIndex, "secondaryColor", null, isOrganization)
          }
        />
      </div>
      <div className="flex justify-between my-2">
        <h2 className="">Title Colour</h2>
        <ColorPicker
          color={profile.badgeUIConfig?.titleTextColor}
          handleChange={(event) =>
            updateBadgeUI(event, profileIndex, "titleTextColor", null, isOrganization)
          }
        />
      </div>
      <div className="flex justify-between my-2">
        <h2 className="">Caption Colour</h2>
        <ColorPicker
          color={profile.badgeUIConfig?.captionTextColor}
          handleChange={(event) =>
            updateBadgeUI(event, profileIndex, "captionTextColor", null, isOrganization)
          }
        />
      </div>
      <div className="flex justify-between my-2">
        <h2 className="">Ring Gradient Color</h2>
        <div className="grid grid-cols-2 gap-1">
          <ColorPicker
            color={profile.badgeUIConfig?.ringGradientColor?.colorFrom}
            handleChange={(event) =>
              updateBadgeUI(event, profileIndex, "ringGradientColor", "colorFrom", isOrganization)
            }
          />
          <ColorPicker
            color={profile.badgeUIConfig?.ringGradientColor?.colorTo}
            handleChange={(event) =>
              updateBadgeUI(event, profileIndex, "ringGradientColor", "colorTo", isOrganization)
            }
          />
        </div>
      </div>
      <div>
        <ImagePicker
          selectImage={handleImageSelect}
          profileIndex={profileIndex}
          isOrganization={isOrganization}
          selectedImage={profile.badgeUIConfig?.cornerImageObj}
        />
      </div>
      <div className="my-5">
        <ProfileCard
          title="Card Title"
          caption="Card Caption"
          gradient={profile.badgeUIConfig?.ringGradientColor}
          primaryColor={profile.badgeUIConfig?.primaryColor}
          titleTextColor={profile.badgeUIConfig?.titleTextColor}
          secondaryColor={profile.badgeUIConfig?.secondaryColor}
          captionTextColor={profile.badgeUIConfig?.captionTextColor}
          imageUrl="https://placehold.co/450x450/png"
          secondaryImage={
            profile.badgeUIConfig?.cornerImageObj
              ? URL.createObjectURL(profile.badgeUIConfig?.cornerImageObj)
              : profile.badgeUIConfig?.secondaryImage
                ? process.env.REACT_APP_AWS_URL + profile.badgeUIConfig?.secondaryImage
                : "https://placehold.co/60x60/png"
          }
        />
      </div>
    </>
  );
};

const ImagePicker = ({ selectImage, profileIndex, isOrganization, selectedImage }) => {
  const fileRefs = useRef({});
  return (
    <>
      <div className="flex items-baseline justify-between">
        <h3>Corner image</h3>
        <button
          className="underline text-red-500"
          onClick={() => selectImage(null, profileIndex, isOrganization, true)}>
          Remove Image
        </button>
      </div>
      <input
        ref={(el) => (fileRefs.current.img = el)}
        name="image_de"
        type="file"
        accept="image/*"
        onChange={(event) => {
          selectImage(event.currentTarget.files[0], profileIndex, isOrganization);
        }}
        className="hidden"
      />
      <div
        className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-3 rounded-3xl  placeholder-[#606060] bg-[#F6F5F5] text-sm shadow-md max-w-60 cursor-pointer"
        onClick={() => {
          fileRefs.current.img.click();
        }}>
        <PlusSmallIcon className={`w-5 h-5 stroke-1 stroke-white bg-gray-400 rounded-full`} />
        {selectedImage && "Save to Upload"}
      </div>
    </>
  );
};
