import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { BackIcon } from "../../asssets/icons";

export default function SlideInPopup({ heading, subHeading, open, setOpen, children }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="relative pointer-events-auto w-screen max-w-lg">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white px-8 py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="absolute left-4 top-6 inline" onClick={() => setOpen(false)}>
                        <BackIcon />
                      </div>
                      <div className="flex flex-col items-start justify-between">
                        <Dialog.Title className="text-2xl font-semibold leading-6 text-gray-900">
                          {heading}
                        </Dialog.Title>
                        <p className="mt-2 text-md text-left text-[#757575]">{subHeading}</p>
                        <hr className="my-2 border-[0.5px] border-solid w-full" />
                      </div>
                    </div>
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
