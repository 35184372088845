import React, { useState } from "react";

import { Input, InputSelect, TextArea } from "../../../../form";
import ToggleComponent from "../../../../common/toggleComponent";

import { MinusSquare, SortIcon } from "../../../../../asssets/icons";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";

const componentTitles = {
  classPage: {
    studentsList: "Students List",
    strengthWordCloud: "Strength Wordcloud",
    professionRolesImageCloud: "Professional Roles Image Cloud",
    profileVideoSelection: "Profile Video Selection",
    professionSelection: "Profession Selection",
    downloadAllProfessions: "Download All Students Professions",
    aptitudeWordCloud: "Aptitude Wordcloud",
    regionalLinks: "Regional Links",
    htmlBox: "HTML Box",
    videosPlayer: "Videos Player"
  },
  studentPage: {
    preferredStrengths: "3 Preferred Strengths",
    preferredProfessionalRoles: "3 Preferred Professional Roles",
    similarProfileBadges: "Similar Profile Badges",
    professionSelection: "Profession Selection",
    profileDownload: "Profile Download",
    htmlBox: "HTML Box",
    videosPlayer: "Videos Player"
  }
};

const ClassStudentComponents = ({
  index,
  type = "classPage",
  projectVariant,
  projectVariantTitle,
  componentType,
  backgroundHeading,
  updateBackgroundHeading,
  foregroundHeading,
  updateForegroundHeading,
  subText,
  updateSubText,
  buttonText,
  updateButtonText,
  html,
  updateHTMLBox,
  videos,
  updateVideosPlayerTitle,
  updateVideosPlayerUrl,
  addNewVideoInVideosPlayer,
  deleteVideoInVideosPlayer,
  videPlayerAptitudeCloudEnabled,
  toggleVideoPlayerAptitudeCloud,
  regions,
  selectedRegionalLinks,
  selectProjectTypeRegionalLink,
  availableUsersGroups = [],
  selectedUsersGroups = [],
  selectUsersGroup,
  professionTypes,
  selectedProfessionType,
  selectProfessionType,
  useOrganizationStrengthsListInAptitudeCloud,
  toggleUseOrganizationStrengthsListInAptitudeCloud,
  useOrganizationRolesListInAptitudeCloud,
  toggleUseOrganizationRolesListInAptitudeCloud,
  aptitudeCloudOptions = [],
  updateAptitudeCloudOption,
  addAptitudeCloudOption,
  deleteAptitudeCloudOption,
  customVideoOptions,
  addCustomVideoOption,
  deleteCustomVideoOption,
  updateCustomVideoOption,
  removeComponent
}) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className="p-3 rounded-md bg-gray-200 w-full relative">
      <span className="block w-4 h-4 absolute left-3 top-3 cursor-grab">
        <SortIcon />
      </span>
      <span className="block w-5 h-5 absolute right-3 top-3 cursor-pointer text-red-600">
        <TrashIcon onClick={() => removeComponent(type, projectVariant, index)} />
      </span>

      <p
        className="font-bold text-base text-center mt-2 mb-4 cursor-pointer"
        onClick={() => setCollapsed(!collapsed)}>
        <span className="inline-block px-2 py-1 rounded text-center bg-corange-100 text-black mb-2">
          {type === "classPage" ? "Class Page" : "Student Page"} - {projectVariantTitle} - comp#{" "}
          {index + 1}
        </span>
        <br />
        {componentTitles[type]?.[componentType]}
      </p>

      {!collapsed && (
        <>
          {componentType !== "htmlBox" && (
            <>
              {updateForegroundHeading !== undefined && (
                <>
                  <p className="font-bold">Forground Heading</p>
                  <div className="flex flex-row gap-1 my-2">
                    {["en", "de", "fr", "it"].map((lang) => {
                      return (
                        <Input
                          key={`foregroundHeading-${lang}`}
                          className={"w-full"}
                          inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                          labelClassName={"text-sm font-normal text-black"}
                          label={lang.toUpperCase() + " Title"}
                          placeholder={lang}
                          value={foregroundHeading?.[lang]}
                          onChange={(e) => {
                            if (updateForegroundHeading)
                              updateForegroundHeading(
                                type,
                                projectVariant,
                                index,
                                lang,
                                e.target.value
                              );
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              )}

              {updateBackgroundHeading !== undefined && (
                <>
                  <p className="font-bold">Background Heading</p>
                  <div className="flex flex-row gap-1 my-2">
                    {["en", "de", "fr", "it"].map((lang) => {
                      return (
                        <Input
                          key={`backgroundHeading-${lang}`}
                          className={"w-full"}
                          inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                          labelClassName={"text-sm font-normal text-black"}
                          label={lang.toUpperCase() + " Title"}
                          placeholder={lang}
                          value={backgroundHeading?.[lang]}
                          onChange={(e) => {
                            if (updateForegroundHeading)
                              updateBackgroundHeading(
                                type,
                                projectVariant,
                                index,
                                lang,
                                e.target.value
                              );
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              )}

              {updateSubText !== undefined && (
                <>
                  <p className="font-bold">Sub Text</p>
                  <div className="flex flex-row gap-1 my-2">
                    {["en", "de", "fr", "it"].map((lang) => {
                      return (
                        <Input
                          key={`subText-${lang}`}
                          className={"w-full"}
                          inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                          labelClassName={"text-sm font-normal text-black"}
                          label={lang.toUpperCase() + " Title"}
                          placeholder={lang}
                          value={subText?.[lang]}
                          onChange={(e) => {
                            if (updateSubText)
                              updateSubText(type, projectVariant, index, lang, e.target.value);
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              )}

              {updateButtonText !== undefined && (
                <>
                  <p className="font-bold">Button Text</p>
                  <div className="flex flex-row gap-1 my-2">
                    {["en", "de", "fr", "it"].map((lang) => {
                      return (
                        <Input
                          key={`buttonText-${lang}`}
                          className={"w-full"}
                          inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                          labelClassName={"text-sm font-normal text-black"}
                          label={lang.toUpperCase() + " Title"}
                          placeholder={lang}
                          value={buttonText?.[lang]}
                          onChange={(e) => {
                            if (updateButtonText)
                              updateButtonText(type, projectVariant, index, lang, e.target.value);
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              )}

              {componentType === "regionalLinks" && (
                <div className="w-full mt-2">
                  <p className="font-bold">Selected Regional Links</p>
                  <InputSelect
                    name={projectVariant + "-" + componentType + "-" + index}
                    className={"mb-0"}
                    options={regions
                      .map((region) => {
                        if (selectedRegionalLinks.includes(region._id)) return null;
                        return {
                          value: region._id,
                          label:
                            region.name_en || region.name_de || region.name_fr || region.name_it
                        };
                      })
                      .filter((value) => value !== null)}
                    onChange={(e) =>
                      selectProjectTypeRegionalLink(type, projectVariant, index, e.value)
                    }
                  />

                  {selectedRegionalLinks.length > 0 && (
                    <div className="flex flex-wrap gap-2 mt-2">
                      {selectedRegionalLinks.map((regionId) => {
                        const region = regions.find((region) => region._id === regionId);
                        return (
                          <span
                            key={regionId}
                            className="px-2 py-1 rounded-full flex gap-3 items-center">
                            {region?.name_en || region?.name_de || region?.name_fr}{" "}
                            <button
                              onClick={() =>
                                selectProjectTypeRegionalLink(type, projectVariant, index, regionId)
                              }>
                              <MinusSquare />
                            </button>
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}

              {["profileVideoSelection", "professionSelection", "similarProfileBadges"].includes(
                componentType
              ) && (
                <div className="w-full mt-2">
                  <p className="font-bold">Users Groups</p>
                  <InputSelect
                    name={projectVariant + "-" + componentType + "-" + index}
                    className={"mb-0"}
                    options={availableUsersGroups
                      .map((userGroup) => {
                        return {
                          value: userGroup._id,
                          label: `${userGroup?.groupColumn?.name} - ${userGroup.name}`
                        };
                      })
                      .filter(
                        (userGroup) =>
                          !selectedUsersGroups.includes(userGroup.value) && userGroup !== null
                      )}
                    onChange={(e) => selectUsersGroup(type, projectVariant, index, e.value)}
                  />

                  {selectedUsersGroups?.length > 0 && (
                    <div className="flex flex-wrap gap-2 mt-2">
                      {selectedUsersGroups.map((userGroup) => {
                        const selectedGroupItem = availableUsersGroups?.find(
                          (g) => g?._id === userGroup
                        );

                        if (!selectedGroupItem) return null;

                        return (
                          <span
                            key={userGroup}
                            className="px-2 py-1 rounded-full flex gap-3 items-center">
                            [
                            <span className="font-bold">
                              {selectedGroupItem?.groupColumn?.name} -
                            </span>
                            <span className="text-cyan-800 font-semibold">
                              {selectedGroupItem?.name}
                            </span>
                            ]
                            <button
                              onClick={() =>
                                selectUsersGroup(type, projectVariant, index, userGroup)
                              }>
                              <MinusSquare />
                            </button>
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}

              {componentType === "professionSelection" && (
                <div className="w-full mt-2">
                  <p className="font-bold">
                    Selected Profession Type:{" "}
                    <span className="text-cyan-600">{selectedProfessionType}</span>
                  </p>
                  <InputSelect
                    name={projectVariant + "-" + componentType + "-" + index + "-professionType"}
                    className={"mb-0"}
                    options={["", ...professionTypes].map((professionType) => {
                      return {
                        value: professionType,
                        label: professionType
                      };
                    })}
                    onChange={(e) => selectProfessionType(type, projectVariant, index, e.value)}
                  />
                </div>
              )}

              {componentType === "videosPlayer" && (
                <>
                  <div className="flex items-center justify-between my-5">
                    <p className="font-bold">Enable Aptitude Cloud</p>
                    <div className="flex gap-3 items-center">
                      <ToggleComponent
                        handleCompToggle={() =>
                          toggleVideoPlayerAptitudeCloud(type, projectVariant, index)
                        }
                        isToggle={videPlayerAptitudeCloudEnabled}
                      />
                    </div>
                  </div>

                  <p className="font-bold mt-5">Videos</p>
                  <div className="flex flex-col gap-1 my-2">
                    {videos?.map((video, videoIndex) => {
                      return (
                        <div
                          key={videoIndex}
                          className="flex flex-row items-center justify-center gap-1 my-2">
                          {["en", "de", "fr", "it"].map((lang) => {
                            return (
                              <Input
                                className={"w-full"}
                                inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                                labelClassName={"text-sm font-normal text-black"}
                                label={lang.toUpperCase() + " Title"}
                                placeholder={lang}
                                value={video?.title?.[lang]}
                                onChange={(e) => {
                                  if (updateVideosPlayerTitle)
                                    updateVideosPlayerTitle(
                                      type,
                                      projectVariant,
                                      index,
                                      videoIndex,
                                      lang,
                                      e.target.value
                                    );
                                }}
                              />
                            );
                          })}
                          <Input
                            className={"w-full"}
                            inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                            labelClassName={"text-sm font-normal text-black"}
                            label={"Video URL"}
                            placeholder={"Video URL"}
                            value={video?.url}
                            onChange={(e) => {
                              if (updateVideosPlayerUrl)
                                updateVideosPlayerUrl(
                                  type,
                                  projectVariant,
                                  index,
                                  videoIndex,
                                  e.target.value
                                );
                            }}
                          />
                          <button
                            className="text-red-600 p-2"
                            onClick={() =>
                              deleteVideoInVideosPlayer(type, projectVariant, index, videoIndex)
                            }>
                            <TrashIcon className="w-7 h-7 mt-5" />
                          </button>
                        </div>
                      );
                    })}
                    <button
                      className="bg-green-600 text-white rounded-md p-2 flex gap-2 items-center justify-center"
                      onClick={() => addNewVideoInVideosPlayer(type, projectVariant, index)}>
                      <PlusCircleIcon className="w-7 h-7" /> Add New Video
                    </button>
                  </div>
                </>
              )}

              {componentType === "aptitudeWordCloud" && (
                <>
                  <p className="font-bold mt-5">Aptitude Cloud Options</p>
                  <div className="flex items-center justify-between">
                    <p className="font-bold">Use organization Strengths List</p>
                    <div className="flex gap-3 items-center">
                      <ToggleComponent
                        handleCompToggle={() =>
                          toggleUseOrganizationStrengthsListInAptitudeCloud(
                            type,
                            projectVariant,
                            index
                          )
                        }
                        isToggle={useOrganizationStrengthsListInAptitudeCloud}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-4">
                    <p className="font-bold">Use organization Roles List</p>
                    <div className="flex gap-3 items-center">
                      <ToggleComponent
                        handleCompToggle={() =>
                          toggleUseOrganizationRolesListInAptitudeCloud(type, projectVariant, index)
                        }
                        isToggle={useOrganizationRolesListInAptitudeCloud}
                      />
                    </div>
                  </div>

                  {!useOrganizationStrengthsListInAptitudeCloud &&
                    !useOrganizationRolesListInAptitudeCloud && (
                      <div className="flex flex-col gap-1 my-2">
                        {aptitudeCloudOptions?.map((option, aptitudeCloudOptionIndex) => {
                          return (
                            <div
                              key={`aptitiude-options-${index}-${aptitudeCloudOptionIndex}`}
                              className="flex flex-row items-center justify-center gap-1 my-2">
                              {["en", "de", "fr", "it"].map((lang) => {
                                return (
                                  <Input
                                    className={"w-full"}
                                    inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                                    labelClassName={"text-sm font-normal text-black"}
                                    label={lang.toUpperCase() + " Title"}
                                    placeholder={lang}
                                    value={option?.[lang]}
                                    onChange={(e) => {
                                      if (updateAptitudeCloudOption)
                                        updateAptitudeCloudOption(
                                          type,
                                          projectVariant,
                                          index,
                                          aptitudeCloudOptionIndex,
                                          lang,
                                          e.target.value
                                        );
                                    }}
                                  />
                                );
                              })}

                              <button
                                className="text-red-600 p-2"
                                onClick={() => {
                                  if (deleteAptitudeCloudOption)
                                    deleteAptitudeCloudOption(
                                      type,
                                      projectVariant,
                                      index,
                                      aptitudeCloudOptionIndex
                                    );
                                }}>
                                <TrashIcon className="w-7 h-7 mt-5" />
                              </button>
                            </div>
                          );
                        })}
                        <button
                          className="bg-green-600 text-white rounded-md p-2 flex gap-2 items-center justify-center"
                          onClick={() => addAptitudeCloudOption(type, projectVariant, index)}>
                          <PlusCircleIcon className="w-7 h-7" /> Add New Option
                        </button>
                      </div>
                    )}
                </>
              )}

              {componentType === "profileVideoSelection" && (
                <>
                  <p className="font-bold mt-5">Custom Video Selection Options</p>

                  <div className="flex flex-col gap-1 my-2">
                    {customVideoOptions?.map((option, customVideoOptionIndex) => {
                      return (
                        <div
                          key={`customVideoTitle-options-${index}-${customVideoOptionIndex}`}
                          className="flex flex-row items-center justify-center gap-1 my-2">
                          {["en", "de", "fr", "it"].map((lang) => {
                            return (
                              <Input
                                className={"w-full"}
                                inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                                labelClassName={"text-sm font-normal text-black"}
                                label={lang.toUpperCase() + " Title"}
                                placeholder={lang}
                                value={option?.[lang]}
                                onChange={(e) => {
                                  if (updateCustomVideoOption)
                                    updateCustomVideoOption(
                                      type,
                                      projectVariant,
                                      index,
                                      customVideoOptionIndex,
                                      lang,
                                      e.target.value
                                    );
                                }}
                              />
                            );
                          })}

                          <button
                            className="text-red-600 p-2"
                            onClick={() => {
                              if (deleteCustomVideoOption)
                                deleteCustomVideoOption(
                                  type,
                                  projectVariant,
                                  index,
                                  customVideoOptionIndex
                                );
                            }}>
                            <TrashIcon className="w-7 h-7 mt-5" />
                          </button>
                        </div>
                      );
                    })}
                    <button
                      className="bg-green-600 text-white rounded-md p-2 flex gap-2 items-center justify-center"
                      onClick={() => addCustomVideoOption(type, projectVariant, index)}>
                      <PlusCircleIcon className="w-7 h-7" /> Add New Option
                    </button>
                  </div>
                </>
              )}
            </>
          )}

          {componentType === "htmlBox" && updateHTMLBox !== undefined && (
            <>
              <p className="font-bold">HTML Box Content</p>
              <div className="flex flex-col gap-1 my-2">
                {["en", "de", "fr", "it"].map((lang) => {
                  return (
                    <React.Fragment key={`htmlBox-${lang}`}>
                      <TextArea
                        className={"w-full"}
                        inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                        labelClassName={"text-sm font-normal text-black"}
                        label={lang.toUpperCase() + " Content"}
                        placeholder={`<div>${lang}</div>`}
                        value={html?.[lang]}
                        onChange={(e) => {
                          if (updateHTMLBox)
                            updateHTMLBox(type, projectVariant, index, lang, e.target.value);
                        }}
                      />

                      {html?.[lang] && (
                        <>
                          <p className="font-bold text-orange-600">
                            {lang?.toUpperCase()} Preview (This is approximation. May differ
                            slightly on site)
                          </p>
                          <div
                            className="w-full p-3 rounded-md bg-white border border-gray-400"
                            dangerouslySetInnerHTML={{ __html: html?.[lang] }}
                          />
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export const ClassSudentComponentsPicker = ({
  type = "classPage",
  projectVariant,
  addComponent
}) => {
  const componentTypes = Object.keys(componentTitles[type]);

  return (
    <div className="p-3 rounded-md bg-gray-200 w-full relative">
      <p className="font-bold text-lg text-center mt-2 mb-4">Add Component</p>
      <InputSelect
        name="componentType"
        className={"mb-0"}
        options={componentTypes.map((componentType) => ({
          value: componentType,
          label: componentTitles[type][componentType]
        }))}
        onChange={(e) => addComponent(type, projectVariant, e.value)}
      />
    </div>
  );
};

export default ClassStudentComponents;
