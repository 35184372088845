import React, { useState, useEffect } from "react";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import BaseModal from "../../popups/model";
import { Input } from "../../form";
import { createEstablishment, getEstablishments } from "../../../service";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AdminPanelButton from "../../form/button/adminPanelButton";
import OrgEsbalishment from "./orgEstablishment";

export default function OrgEstablishments({ prefilledData }) {
  const [open, setOpen] = useState(false);
  const [establishments, setEstablishments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [updatedOrderKey, setUpdatedOrderKey] = useState([]);

  const submitHandler = async () => {
    setLoading(true);

    try {
      const postBody = {
        ...formik.values,
        orderKey: establishments.length + 1
      };

      const res = await createEstablishment({ ...postBody }, prefilledData._id);
      getAdminEstablishments();

      toast.success(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      formik.resetForm();
      setOpen(!open);
    }
  };

  async function getAdminEstablishments() {
    try {
      if (!prefilledData) return;
      const establishments = await getEstablishments(prefilledData._id);
      establishments.data.sort(function (a, b) {
        return a.orderKey - b.orderKey;
      });
      setEstablishments(establishments.data);
    } catch (err) {
      toast.error("error while fetching establishments");
      console.log(err);
    }
  }
  useEffect(() => {
    getAdminEstablishments();
  }, [prefilledData]);

  const formik = useFormik({
    initialValues: {
      name_de: "",
      name_en: "",
      name_fr: "",
      name_it: ""
    },
    onSubmit: submitHandler
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempOrderKey = establishments[result.source.index].orderKey;
    establishments[result.source.index].orderKey =
      establishments[result.destination.index].orderKey;
    establishments[result.destination.index].orderKey = tempOrderKey;

    const temp = establishments[result.source.index];
    establishments[result.source.index] = establishments[result.destination.index];

    establishments[result.destination.index] = temp;
    const newData = updatedOrderKey.filter((item) => {
      if (
        item._id == establishments[result.source.index]._id ||
        item._id == establishments[result.destination.index]._id
      ) {
        return false;
      } else {
        return true;
      }
    });

    setUpdatedOrderKey([
      ...newData,
      establishments[result.source.index],
      establishments[result.destination.index]
    ]);
    setEstablishments([...establishments]);
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <tbody
                        className="divide-y divide-gray-200"
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {establishments.map((establishment, index) => {
                          return (
                            <Draggable
                              key={establishment._id}
                              draggableId={establishment._id}
                              index={index}>
                              {(provided) => {
                                return (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>
                                    <td
                                      colSpan="6"
                                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                      <OrgEsbalishment
                                        establishment={establishment}
                                        prefilledData={prefilledData}
                                        setOpen={setOpen}
                                        open={open}
                                        getAdminEstablishments={getAdminEstablishments}
                                        updatedOrderKey={updatedOrderKey}
                                      />
                                    </td>
                                  </tr>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setOpen(!open);
                          }}>
                          <PlusSmallIcon
                            className={`w-7 h-7 stroke-2 stroke-white bg-[#E3E3E3] rounded-full`}
                          />
                        </div>
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>
            </div>
          </div>
        </div>
      </div>
      <BaseModal open={open} setOpen={setOpen} title={"Create Establishment"}>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-2">
            <Input
              label="DE"
              name="name_de"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values["name_de"]}
              error={formik?.errors["name_de"]}
              touched={formik?.touched["name_de"]}
            />
            <Input
              label="EN"
              name="name_en"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values["name_en"]}
              error={formik?.errors["name_en"]}
              touched={formik?.touched["name_en"]}
            />
            <Input
              label="FR"
              name="name_fr"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values["name_fr"]}
              error={formik?.errors["name_fr"]}
              touched={formik?.touched["name_fr"]}
            />
            <Input
              label="IT"
              name="name_it"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values["name_it"]}
              error={formik?.errors["name_it"]}
              touched={formik?.touched["name_it"]}
            />
          </div>

          <div className="mt-5 sm:mt-4 grid grid-cols-2 gap-4">
            <AdminPanelButton
              type="submit"
              isSave={true}
              loading={loading}
              bgColor="#FC9E5A"
              textColor="#FFFFFF"
              color="white"
              onClick={() => {}}>
              create
            </AdminPanelButton>
          </div>
        </form>
      </BaseModal>
    </>
  );
}
