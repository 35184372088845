import * as Yup from "yup";

export const loginSchema = Yup.object({
  email: Yup.string()
    .email()
    .required("Email is required")
    .matches(/^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/, `Enter valid email`)
});

export const createOrganizationSchema = Yup.object({
  body: Yup.object({
    name: Yup.string().required().min(2, "too short"),
    logo: Yup.string().nullable().optional(),
    languages: Yup.array()
      .of(
        Yup.string()
          .oneOf(["en", "de", "fr", "it"], "Invalid language")
          .required("Language is required")
      )
      .required("At least one language is required")
      .min(1, "At least one language is required")
  })
});

export const subAdminAccountSchema = Yup.object({
  email: Yup.string().email().required("Email is required"),
  permissions: Yup.array().of(Yup.string().required("Permission is required"))
});

function check_with_name(value, orderKey) {
  //alert(this)
  //console.log(this)
  return (value && orderKey) || (!value && !orderKey) || (value && !orderKey) ? true : false;
}
function check_with_orderkey(value, name) {
  return (typeof value === "number" && name) || (!value && !name) || (value && !name)
    ? true
    : false;
}
export const regionSchema = Yup.object().shape({
  name_de: Yup.string().test("test_with_orderkey", "Required", function (value) {
    return check_with_name(value, this.parent["orderKey_de"]);
  }),
  orderKey_de: Yup.number()
    .typeError("not a valid number")
    .nullable()
    .test("test_with_name", "Required", function (value) {
      return check_with_orderkey(value, this.parent["name_de"]);
    }),
  name_fr: Yup.string().test("test_with_orderkey", "Required", function (value) {
    return check_with_name(value, this.parent["orderKey_fr"]);
  }),
  orderKey_fr: Yup.number()
    .typeError("not a valid number")
    .nullable()
    .test("test_with_name", "Required", function (value) {
      return check_with_orderkey(value, this.parent["name_fr"]);
    }),
  name_en: Yup.string().test("test_with_orderkey", "Required", function (value) {
    return check_with_name(value, this.parent["orderKey_en"]);
  }),
  orderKey_en: Yup.number()
    .typeError("not a valid number")
    .nullable()
    .test("test_with_name", "Required", function (value) {
      return check_with_orderkey(value, this.parent["name_en"]);
    }),
  name_it: Yup.string().test("test_with_orderkey", "Required", function (value) {
    return check_with_name(value, this.parent["orderKey_it"]);
  }),
  orderKey_it: Yup.number()
    .typeError("not a valid number")
    .nullable()
    .test("test_with_name", "Required", function (value) {
      return check_with_orderkey(value, this.parent["name_it"]);
    })
});

export const contactInfoSchema = Yup.object().shape({
  name: Yup.string().required("name is required").min(2, "name is too short"),
  email: Yup.string().email().required("email is required"),
  phone: Yup.string().optional()
});
