const IMAGE_URL = process.env.REACT_APP_AWS_URL;

export default function Header({ email, logo, role }) {
  const getImageLink = (value) => {
    return IMAGE_URL + value;
  };

  return (
    <>
      <div className="flex gap-5 items-center">
        <div
          className={`w-[96px] h-[119px] bg-corange-100 rounded-b-full flex justify-center items-end pb-[11px]`}>
          <div className="w-[75px] h-[75px] rounded-full bg-white flex justify-center items-center">
            {logo ? (
              <img src={getImageLink(logo)} className="object-contain w-full h-full" />
            ) : (
              <p className="text-xl font-medium text-gray-900">MA</p>
            )}
          </div>
        </div>
        <div aria-label="Name" className="mt-4">
          <div>
            <p className="text-xl font-bold text-gray-900">Psyfiers</p>
            <p className="text-lg text-[#757575] max-w-[200px] text-ellipsis overflow-hidden">
              Master Admin
            </p>
          </div>
        </div>
      </div>
      <div className="border-[#191919] w-full py-3 border-b-[0.1px]"></div>
    </>
  );
}
