import { useParams } from "react-router-dom";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { useState, useEffect, useRef } from "react";
import {
  getOrgStrength,
  addStrength,
  updateStrength,
  deleteStrength,
  uploadFileToAWS,
  getUploadingFileData,
} from "../../../service";
import { PlusIcon, RemoveIcon } from "../../../asssets/icons";

const AddOrgStrenght = ({ org }) => {
  const fileRefs = useRef({});
  const [strenghtSection, setstrenghtSection] = useState([
    {
      _id: "",
      name_fr: "",
      name_en: "",
      name_de: "",
      name_it: "",
      image_fr: "",
      image_en: "",
      image_de: "",
      image_it: "",
      image_fr_obj: null,
      image_en_obj: null,
      image_de_obj: null,
      image_it_obj: null,
      description_fr: "",
      description_en: "",
      description_de: "",
      description_it: "",
    },
  ]);

  const { orgId } = useParams();

  const fetchStrength = async () => {
    try {
      const { data } = await getOrgStrength(orgId);
      data.length > 0 ? setstrenghtSection(data) : addStrenghtSection();
    } catch (error) {}
  };

  useEffect(() => {
    fetchStrength(orgId);
  }, [orgId, org]);

  const addStrenghtSection = () => {
    setstrenghtSection([
      ...strenghtSection,
      {
        _id: "",
        name_fr: "",
        name_en: "",
        name_de: "",
        name_it: "",
        image_fr: "",
        image_en: "",
        image_de: "",
        image_it: "",
        image_fr_obj: null,
        image_en_obj: null,
        image_de_obj: null,
        image_it_obj: null,
        description_fr: "",
        description_en: "",
        description_de: "",
        description_it: "",
      },
    ]);
  };

  const removeStrenghtSection = async (index) => {
    const updatedSections = [...strenghtSection];
    const removedSection = updatedSections[index];
    if (removedSection._id !== "")
      await deleteStrength(orgId, { _id: removedSection._id });
    updatedSections.splice(index, 1);
    setstrenghtSection(updatedSections);
  };

  const handleChange = (e, index, lang) => {
    const { name, value } = e.target;
    const updatedSections = [...strenghtSection];
    updatedSections[index] = { ...updatedSections[index], [name]: value };
    setstrenghtSection(updatedSections);
  };

  const handleUploadChange = (file, index, key_name, obj_name, lang) => {
    if (file !== undefined) {
      const filename = "strengths-images/" + orgId + "/" + file.name;
      const updatedSections = [...strenghtSection];
      updatedSections[index] = { ...updatedSections[index], [obj_name]: file };
      updatedSections[index] = {
        ...updatedSections[index],
        [key_name]: filename,
      };
      setstrenghtSection(updatedSections);
    }
  };

  const uploadImage = async (type, image) => {
    if (image !== undefined) {
      const {
        data: { url },
      } = await getUploadingFileData({
        name: type + "/" + orgId + "/" + image.name,
        type: image.type,
      });
      await uploadFileToAWS(url, image, image.type);
    }
  };

  const saveStrenght = async () => {
    strenghtSection.forEach(async (item, index) => {
      console.log(`Item ${index + 1}:`, item);
      if (item?.image_en_obj || item?.image_en_obj?.name !== undefined) {
        await uploadImage("strengths-images", item.image_en_obj);
      }
      if (item?.image_de_obj || item?.image_de_obj?.name !== undefined) {
        await uploadImage("strengths-images", item.image_de_obj);
      }
      if (item?.image_fr_obj || item?.image_fr_obj?.name !== undefined) {
        await uploadImage("strengths-images", item.image_fr_obj);
      }
      if (item?.image_it_obj || item?.image_it_obj?.name !== undefined) {
        await uploadImage("strengths-images", item.image_it_obj);
      }

      if (item._id !== "") {
        updateStrength(orgId, item);
      } else {
        addStrength(orgId, item);
      }
    });
  };

  return (
    <>
      <div className="container mx-auto p-4">
        <div className="flex justify-end mt-4">
          <button
            onClick={saveStrenght}
            type="button"
            className="flex items-center gap-2 disabled:opacity-60 select-none py-2 rounded font-bold text-md border  bg-[#515151] text-[#FFFFFF] px-8 rounded-3xl"
          >
            Save
          </button>
        </div>
      </div>
      <div>
        {strenghtSection.map((section, index) => (
          <div key={index} className="container mx-auto">
            <div>
              <table className="table-auto w-full mt-5 rounded">
                <thead className="text-center tracking-wider">
                  <tr>
                    <th className="py-2 px-4"> </th>
                    <th className="py-2 px-4">Strength Name </th>
                    <th className="py-2 px-4">Description</th>
                    <th className="py-2 px-4">Associated Image</th>
                    <th className="py-2 px-4"> </th>
                  </tr>
                </thead>
                <tbody className="border-2 rounded-md p-4 mr-8">
                  {org.languages?.map((language, languageIndex) => (
                    <tr key={languageIndex}>
                      <td className="py-2">{language.toUpperCase()}</td>
                      <td className="hidden">{section._id}</td>
                      {language.toUpperCase() === "EN" && (
                        <>
                          <td>
                            <input
                              type="text"
                              name="name_en"
                              value={section.name_en}
                              onChange={(e) => handleChange(e, index, "en")}
                              className="w-full mb-2 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="description_en"
                              value={section.description_en}
                              onChange={(e) => handleChange(e, index, "en")}
                              className="w-full mb-2 ml-3 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                            />
                          </td>
                          <td>
                            <input
                              ref={(el) =>
                                (fileRefs.current[`en_${index}`] = el)
                              }
                              name="image_en"
                              type="file"
                              accept="image/*"
                              onChange={(event) => {
                                handleUploadChange(
                                  event.currentTarget.files[0],
                                  index,
                                  "image_en",
                                  "image_en_obj",
                                  "en"
                                );
                              }}
                              className="hidden"
                            />
                            <div className="ml-6">
                              <div
                                className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                onClick={() => {
                                  fileRefs.current[`en_${index}`].click();
                                }}
                              >
                                <PlusSmallIcon
                                  className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                />
                                <span>{section.image_en_obj?.name}</span>
                              </div>
                            </div>
                          </td>
                        </>
                      )}
                      {language.toUpperCase() === "DE" && (
                        <>
                          <td>
                            <input
                              type="text"
                              name="name_de"
                              value={section.name_de}
                              onChange={(e) => handleChange(e, index, "de")}
                              className="w-full mb-2 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="description_de"
                              value={section.description_de}
                              onChange={(e) => handleChange(e, index, "de")}
                              className="w-full mb-2 ml-3 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                            />
                          </td>
                          <td>
                            <input
                              ref={(el) =>
                                (fileRefs.current[`de_${index}`] = el)
                              }
                              name="image_de"
                              type="file"
                              accept="image/*"
                              onChange={(event) => {
                                handleUploadChange(
                                  event.currentTarget.files[0],
                                  index,
                                  "image_de",
                                  "image_de_obj",
                                  "de"
                                );
                              }}
                              className="hidden"
                            />
                            <div className="ml-6">
                              <div
                                className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                onClick={() => {
                                  fileRefs.current[`de_${index}`].click();
                                }}
                              >
                                <PlusSmallIcon
                                  className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                />
                                <span>{section.image_de_obj?.name}</span>
                              </div>
                            </div>
                          </td>
                        </>
                      )}
                      {language.toUpperCase() === "FR" && (
                        <>
                          <td>
                            <input
                              type="text"
                              name="name_fr"
                              value={section.name_fr}
                              onChange={(e) => handleChange(e, index, "fr")}
                              className="w-full mb-2 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="description_fr"
                              value={section.description_fr}
                              onChange={(e) => handleChange(e, index, "fr")}
                              className="w-full mb-2 ml-3 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                            />
                          </td>
                          <td>
                            <input
                              ref={(el) =>
                                (fileRefs.current[`fr_${index}`] = el)
                              }
                              name="image_fr"
                              type="file"
                              accept="image/*"
                              onChange={(event) => {
                                handleUploadChange(
                                  event.currentTarget.files[0],
                                  index,
                                  "image_fr",
                                  "image_fr_obj",
                                  "fr"
                                );
                              }}
                              className="hidden"
                            />
                            <div className="ml-6">
                              <div
                                className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                onClick={() => {
                                  fileRefs.current[`fr_${index}`].click();
                                }}
                              >
                                <PlusSmallIcon
                                  className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                />
                                <span>{section.image_fr_obj?.name}</span>
                              </div>
                            </div>
                          </td>
                        </>
                      )}
                      {language.toUpperCase() === "IT" && (
                        <>
                          <td>
                            <input
                              type="text"
                              name="name_it"
                              value={section.name_it}
                              onChange={(e) => handleChange(e, index, "it")}
                              className="w-full mb-2 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="description_it"
                              value={section.description_it}
                              onChange={(e) => handleChange(e, index, "it")}
                              className="w-full mb-2 ml-3 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                            />
                          </td>
                          <td>
                            <input
                              ref={(el) =>
                                (fileRefs.current[`it_${index}`] = el)
                              }
                              name="image_it"
                              type="file"
                              accept="image/*"
                              onChange={(event) => {
                                handleUploadChange(
                                  event.currentTarget.files[0],
                                  index,
                                  "image_it",
                                  "image_it_obj",
                                  "it"
                                );
                              }}
                              className="hidden"
                            />
                            <div className="ml-6">
                              <div
                                className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                onClick={() => {
                                  fileRefs.current[`it_${index}`].click();
                                }}
                              >
                                <PlusSmallIcon
                                  className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                />
                                <span>{section.image_it_obj?.name}</span>
                              </div>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex justify-end mt-4">
              {index > 0 && (
                <button
                  className="mt-4 mr-4"
                  onClick={() => removeStrenghtSection(index)}
                >
                  <RemoveIcon />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="container mx-auto p-4">
        <div className="flex justify-end mt-4">
          <button className="mt-4" onClick={() => addStrenghtSection()}>
            <PlusIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default AddOrgStrenght;
