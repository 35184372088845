import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { Input } from "../../form";
import { PlusSmallIcon, TrashIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { RefreshIcon, VerticalIcon } from "../../../asssets/icons";
import {
  deleteRegion,
  getUploadingFileData,
  updateRegion,
  uploadFileToAWS
} from "../../../service";
import { useFormik } from "formik";
import AdminPanelButton from "../../form/button/adminPanelButton";
import { BaseModal } from "../../popups";
import ButtonOutlined from "../../form/button/buttonOutlined";

export default function OrgList({ region, getAdminRegions, updatedOrderKey = [] }) {
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [logo, setLogo] = useState(region?.logo); // for image preview

  const fileInputRef = useRef(null);

  const getImageLink = (value) => {
    // new image
    if (value?.type) {
      return URL.createObjectURL(value);
    }
    return process.env.REACT_APP_AWS_URL + value;
  };

  const submitHandler = async () => {
    setLoading(true);
    let logoLink = logo;

    try {
      if (logo?.type) {
        const {
          data: { url, finalName }
        } = await getUploadingFileData({
          name: "organization-logos/" + new Date().getTime() + "-" + logo.name,
          type: logo.type
        });
        await uploadFileToAWS(url, logo, logo.type);
        logoLink = finalName;
      }
      console.log("logo link in front-end", logoLink);
      const postBody = {
        ...formik.values,
        logo: logoLink,
        orderKey: region.orderKey
      };

      const res = await updateRegion({ ...postBody, _id: region._id }, region.masterOrganization);
      updatedOrderKey.forEach(async (item) => {
        try {
          const res = await updateRegion({ ...item }, item.masterOrganization);
        } catch (err) {
          console.log(err);
        }
      });
      toast.success(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await deleteRegion(region.masterOrganization, { _id: region._id });
      getAdminRegions();
      toast.success("region deleted succesfully");
      setDeleteOpen(!deleteOpen);
    } catch (err) {
      toast.error("error while deleting region");
    }
  };

  const formik = useFormik({
    initialValues: {
      name_de: region?.name_de,
      name_en: region?.name_en,
      name_fr: region?.name_fr,
      name_it: region?.name_it,
      regionalLink: region?.regionalLink
    },
    //validationSchema: regionSchema,
    onSubmit: submitHandler
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-between items-center  w-[100%]">
          <div className="flex items-center">
            <div className="mr-2">
              <VerticalIcon />
            </div>
            <div className="region_container flex flex-col">
              <div className="first_2_region flex">
                <div>
                  EN
                  <Input
                    name="name_en"
                    formik={formik}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values["name_en"]}
                    error={formik?.errors["name_en"]}
                    touched={formik?.touched["name_en"]}
                  />
                </div>
                <div>
                  DE{" "}
                  <Input
                    name="name_de"
                    formik={formik}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values["name_de"]}
                    error={formik?.errors["name_de"]}
                    touched={formik?.touched["name_de"]}
                  />
                </div>
              </div>
              <div className="last_2_region flex">
                <div>
                  FR{" "}
                  <Input
                    name="name_fr"
                    formik={formik}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values["name_fr"]}
                    error={formik?.errors["name_fr"]}
                    touched={formik?.touched["name_fr"]}
                  />
                </div>
                <div>
                  IT{" "}
                  <Input
                    name="name_it"
                    formik={formik}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values["name_it"]}
                    error={formik?.errors["name_it"]}
                    touched={formik?.touched["name_it"]}
                  />
                </div>
              </div>
              <div>
                Regional Link{" "}
                <Input
                  name="regionalLink"
                  formik={formik}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values["regionalLink"]}
                  error={formik?.errors["regionalLink"]}
                  touched={formik?.touched["regionalLink"]}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center ">
            {logo && (
              <div className="group relative inline-block h-full">
                {/* Remove Icon */}
                <div
                  className="hidden group-hover:block absolute -top-2 -right-2 w-6 h-6 bg-black cursor-pointer rounded-full z-10"
                  onClick={() => {
                    setLogo(null);
                    if (fileInputRef.current) fileInputRef.current.value = "";
                  }}>
                  <XCircleIcon className="w-6 h-6 text-red-500" />
                </div>
                <label
                  htmlFor="imageInput"
                  className="relative block w-max group h-full"
                  onClick={() => {
                    if (fileInputRef.current) fileInputRef.current.click();
                  }}>
                  {/* Reload logo */}
                  <div className="hidden group-hover:flex absolute top-0 left-0 bottom-0 right-0 w-full h-full bg-black opacity-50 cursor-pointer items-center justify-center">
                    <RefreshIcon color="#FFFFFF" />
                  </div>

                  <div className="flex justify-center bg-[#F9F9F9] rounded-md p-4 w-[200px] h-full">
                    <div className="w-[80px] h-full rounded-full overflow-hidden">
                      <img
                        src={getImageLink(logo)}
                        className="w-full h-full object-cover cursor-pointer"
                      />
                    </div>
                  </div>
                </label>
              </div>
            )}
            {!logo && (
              <>
                <div className="flex justify-center w-[200px] bg-[#ffffff] rounded relative px-4 py-3 cursor-pointer">
                  <div
                    className={
                      logo &&
                      "flex justify-start items-center gap-4 px-4 py-3 cursor-pointer shadow-sm "
                    }
                    onClick={() => {
                      fileInputRef.current.click();
                    }}>
                    <PlusSmallIcon
                      className={`w-7 h-7 stroke-2 stroke-white bg-[#E3E3E3] rounded-full`}
                    />
                  </div>
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  name="image"
                  id="imageInput"
                  onChange={(event) => {
                    setLogo(event.currentTarget.files[0]);
                  }}
                  className={"hidden"}
                />
              </>
            )}
            <button
              className=""
              type="button"
              onClick={() => {
                setDeleteOpen(!deleteOpen);
              }}>
              <TrashIcon className="w-[1rem] h-[1rem] mr-2" />
            </button>
          </div>
          <div>
            <AdminPanelButton
              type="submit"
              isSave={true}
              loading={loading}
              bgColor="#FC9E5A"
              textColor="#FFFFFF"
              color="white"
              onClick={() => {}}>
              Save
            </AdminPanelButton>
          </div>
        </div>
      </form>

      <BaseModal open={deleteOpen} setOpen={setDeleteOpen} title={"Delete Region"}>
        <h1 className="text-center mb-4">Sure you want to delete</h1>
        <div className="flex ">
          <div className="grow text-right pr-5">
            <ButtonOutlined
              type="button"
              className="bg-orange-500 text-white border-2 border-orange-500"
              handler={() => {
                handleDelete();
              }}>
              yes
            </ButtonOutlined>
          </div>
          <div className="grow">
            <ButtonOutlined
              type="button"
              handler={() => {
                setDeleteOpen(!deleteOpen);
              }}>
              No
            </ButtonOutlined>
          </div>
        </div>
      </BaseModal>
    </>
  );
}
