import React, { useState, useRef, useEffect } from "react";
import { FaCheck, FaWindowClose } from "react-icons/fa";
import QRCode from "react-qr-code";

import { Button } from "../../form";

const getImageLink = (value) => {
  if (!value) return;

  if (value?.type) {
    return URL.createObjectURL(value);
  }
  return process.env.REACT_APP_AWS_URL + value;
};

const OrgMap = ({ map, setMap, professions }) => {
  return (
    <div className="w-[210mm] h-[297mm] m-0 mx-auto bg-gray-100 p-2">
      <MapImage map={map} setMap={setMap} professions={professions} />
      <MapHeading map={map} setMap={setMap} />
      <div className="grid grid-cols-4 gap-2">
        <MapPlanColumn map={map} setMap={setMap} professions={professions} columnNumber={1} />
        <MapPlanColumn map={map} setMap={setMap} professions={professions} columnNumber={2} />
        <MapPlanColumn map={map} setMap={setMap} professions={professions} columnNumber={3} />
        <MapPlanColumn map={map} setMap={setMap} professions={professions} columnNumber={4} />
      </div>
      <MapFooter map={map} setMap={setMap} />
    </div>
  );
};

export default OrgMap;

const MapImage = ({ map, setMap, professions }) => {
  const { image, pins } = map;

  const [editPin, setEditPin] = useState(false);

  const inputRef = useRef(null);

  const moveProfessionPin = (e) => {
    try {
      if (
        editPin &&
        (e.key === "ArrowUp" ||
          e.key === "ArrowLeft" ||
          e.key === "ArrowRight" ||
          e.key === "ArrowDown")
      ) {
        e.preventDefault();

        const pinToEdit = pins.find((pin) => pin._id === editPin);
        const index = pins.indexOf(pinToEdit);

        const MOVE_DISTANCE = 0.5;

        switch (e.key) {
          case "ArrowUp":
            if (pins[index].y > 0) {
              pins[index] = { ...pins[index], y: pins[index].y - MOVE_DISTANCE };
              setMap({ ...map, pins: pins });
            }
            break;
          case "ArrowLeft":
            if (pins[index].x > 0) {
              pins[index] = { ...pins[index], x: pins[index].x - MOVE_DISTANCE };
              setMap({ ...map, pins: pins });
            }
            break;
          case "ArrowRight":
            pins[index] = { ...pins[index], x: pins[index].x + MOVE_DISTANCE };
            setMap({ ...map, pins: pins });
            break;
          case "ArrowDown":
            pins[index] = { ...pins[index], y: pins[index].y + MOVE_DISTANCE };
            setMap({ ...map, pins: pins });
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.log("Pin moving error", error);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", moveProfessionPin);

    return () => {
      window.removeEventListener("keydown", moveProfessionPin);
    };
  }, [editPin]);

  return (
    <>
      <div className="relative">
        {image && (
          <div className="relative group">
            <img
              src={getImageLink(image)}
              className="w-full h-full"
              alt="Map Floor Plan"
              onClick={() => {
                setEditPin(null);
              }}
            />

            {pins.map((pin, index) => (
              <React.Fragment key={index}>
                {pin?.type !== "aggregate" && (
                  <ProfessionPin x={pin.x} y={pin.y} onClick={() => setEditPin(pin._id)} />
                )}
                {pin?.type === "aggregate" && (
                  <ProfessionAggregatePin x={pin.x} y={pin.y} count={pin?.count} />
                )}
              </React.Fragment>
            ))}

            <div className="absolute right-3 top-3 group-hover:flex hidden flex-col gap-2 opacity-50 max-w-[130px]">
              <button
                className="bg-white px-3 py-1 shadow-lg text-black"
                onClick={() => inputRef?.current?.click()}>
                Change Image
              </button>
              <button
                className="bg-white px-3 py-1 shadow-lg text-black"
                onClick={() => {
                  const newPin = { _id: pins?.length + 1, professionId: null, x: 0, y: 0 };
                  setMap({ ...map, pins: [...pins, newPin] });
                  setEditPin(newPin._id);
                }}>
                + Pin
              </button>

              {editPin && (
                <>
                  <select
                    value={pins?.find((pin) => pin._id === editPin)?.professionId ?? ""}
                    onChange={(e) => {
                      const newPins = pins.map((pin) => {
                        if (pin._id === editPin) {
                          return { ...pin, professionId: e.target.value };
                        }
                        return pin;
                      });
                      setMap({ ...map, pins: newPins });
                    }}>
                    <option value={null}>Select Profession</option>
                    {professions.map((profession) => (
                      <option key={profession._id} value={profession._id}>
                        {profession.jobRole_fr ??
                          profession.jobRole_en ??
                          profession.jobRole_de ??
                          profession.jobRole_it}
                      </option>
                    ))}
                  </select>
                  <div className="relative">
                    <div className="flex items-center justify-center">
                      <button
                        aria-label="up"
                        className="bg-white px-3 py-1 shadow-lg text-black w-1/3"
                        onClick={() => {
                          moveProfessionPin({ key: "ArrowUp", preventDefault: () => {} });
                        }}>
                        &uarr;
                      </button>
                    </div>
                    <div className="flex w-full items-center justify-between">
                      <button
                        aria-label="left"
                        className="bg-white px-3 py-1 shadow-lg text-black w-1/3"
                        onClick={() => {
                          moveProfessionPin({ key: "ArrowLeft", preventDefault: () => {} });
                        }}>
                        &larr;
                      </button>
                      <button
                        aria-label="right"
                        className="bg-white px-3 py-1 shadow-lg text-black w-1/3"
                        onClick={() => {
                          moveProfessionPin({ key: "ArrowRight", preventDefault: () => {} });
                        }}>
                        &rarr;
                      </button>
                    </div>
                    <div className="flex items-center justify-center">
                      <button
                        aria-label="down"
                        className="bg-white px-3 py-1 shadow-lg text-black w-1/3"
                        onClick={() => {
                          moveProfessionPin({ key: "ArrowDown", preventDefault: () => {} });
                        }}>
                        &darr;
                      </button>
                    </div>
                  </div>
                  <button
                    className="bg-white px-3 py-1 shadow-lg text-black"
                    onClick={() => {
                      try {
                        const pinToEdit = pins.find((pin) => pin._id === editPin);
                        const index = pins.indexOf(pinToEdit);

                        pins.splice(index, 1);
                        setEditPin(false);
                        setMap({ ...map, pins: pins });
                      } catch (error) {
                        console.log("Deleting error");
                      }
                    }}>
                    Delete Pin
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        {!image && (
          <Button.ButtonOutlined
            className={"mx-auto w-full my-3"}
            handler={() => inputRef?.current?.click()}>
            Add Floor Plan Image
          </Button.ButtonOutlined>
        )}
      </div>

      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        name="image"
        id="imageInput"
        onChange={(event) => {
          setMap({ ...map, image: event.currentTarget.files[0] });
        }}
        className={"hidden"}
      />
    </>
  );
};

const MapHeading = ({ map, setMap }) => {
  const { title } = map;

  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {!isEditing && (
        <h2
          className="text-xl font-bold text-center cursor-pointer my-2 text-[#000000]"
          onClick={() => setIsEditing(true)}>
          {title ?? "Edit floor plan title"}
        </h2>
      )}

      {isEditing && (
        <div className="flex gap-2 items-center w-full my-3">
          <input
            value={title ?? "Edit floor plan title"}
            onChange={(e) => setMap({ ...map, title: e.target.value })}
            className="w-full text-2xl px-3 py-2 bg-transparent"
            autoFocus
          />{" "}
          <button className="h-full p-3" onClick={() => setIsEditing(false)}>
            <FaCheck />
          </button>
        </div>
      )}
    </>
  );
};

const ProfessionPin = ({ x, y, onClick }) => {
  return (
    <span
      className="absolute block h-7 cursor-pointer"
      style={{ left: `${x}%`, top: `${y}%` }}
      onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="43"
        viewBox="0 0 31 43"
        fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3681 42.0442C10.1739 37.4211 2.47318 27.5169 0.534291 19.519C-2.02574 8.95883 4.9083 0.120975 15.0415 0.00108616C25.1746 -0.118802 32.6417 9.70624 29.6548 19.519C26.8428 28.7575 19.7764 38.0295 15.7544 42.0799C15.3661 42.4709 14.7384 42.4523 14.3681 42.0442ZM15.1742 8.27686C11.5826 8.27686 8.671 11.1885 8.671 14.7801V15.1742C8.671 18.7659 11.5826 21.6775 15.1742 21.6775C18.7659 21.6775 21.6775 18.7659 21.6775 15.1742V14.7801C21.6775 11.1885 18.7659 8.27686 15.1742 8.27686Z"
          fill="white"
        />
      </svg>
    </span>
  );
};

const ProfessionAggregatePin = ({ x, y, count }) => {
  return (
    <span className="absolute block h-7 cursor-pointer" style={{ left: `${x}%`, top: `${y}%` }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="73"
        height="53"
        viewBox="0 0 73 53"
        fill="none">
        <g filter="url(#filter0_d_2872_62672)">
          <path
            d="M58.1904 31.1538L69 0H62.2024H23.2138H20.6517L4 45L18.3082 31.1538H58.1904Z"
            fill="url(#paint0_linear_2872_62672)"
            shapeRendering="crispEdges"
          />
          <path
            d="M18.3082 30.6538H18.1059L17.9605 30.7945L5.23163 43.1124L20.9998 0.5H23.2138H62.2024H68.2973L57.8347 30.6538H18.3082Z"
            stroke="white"
            shapeRendering="crispEdges"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2872_62672"
            x="0"
            y="0"
            width="73"
            height="53"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2872_62672"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2872_62672"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2872_62672"
            x1="123.983"
            y1="-68.3656"
            x2="78.6226"
            y2="146.994"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <span className="absolute top-[35%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-xs">
        {count}
      </span>
    </span>
  );
};

const MapPlanColumn = ({ map, setMap, professions, columnNumber }) => {
  const { professionNumbers } = map;

  return (
    <div className="relative flex flex-col gap-0 group min-h-[100px]">
      {professionNumbers?.map((profession, index) => {
        const { type, columnId } = profession;

        if (columnId !== columnNumber) return;

        if (type === "heading")
          return <MapColumnHeading key={index} index={index} map={map} setMap={setMap} />;
        else
          return (
            <MapColumnProfessionNumber
              key={index}
              index={index}
              map={map}
              setMap={setMap}
              professions={professions}
            />
          );
      })}

      <div className="absolute top-1 right-1 flex-col gap-2 opacity-50 hidden group-hover:flex">
        <button
          className="bg-white px-1 py-1 shadow-lg text-xs text-black"
          onClick={() => {
            const newProfessionNumbers = [
              ...professionNumbers,
              {
                type: "heading",
                title: `Heading ${professionNumbers.length + 1}`,
                bgColor: "#EFCE29",
                columnId: columnNumber,
                professionId: professions[0]?._id // not needed but db requirement
              }
            ];
            setMap({ ...map, professionNumbers: newProfessionNumbers });
          }}>
          Add Heading
        </button>
        <button
          className="bg-white px-1 py-1 shadow-lg text-xs text-black"
          onClick={() => {
            const newProfessionNumbers = [
              ...professionNumbers,
              {
                type: "profession",
                title: `00`,
                customTitle: "",
                image: "",
                professionId: professions[0]?._id,
                columnId: columnNumber,
                bgColor: "#EFCE29" // not needed but db requirement
              }
            ];
            setMap({ ...map, professionNumbers: newProfessionNumbers });
          }}>
          Add Profession No.
        </button>
      </div>
    </div>
  );
};

const MapColumnHeading = ({ index, map, setMap }) => {
  const { professionNumbers } = map;

  const title = professionNumbers?.[index]?.title;
  const bgColor = professionNumbers?.[index]?.bgColor;

  const [isEditing, setIsEditing] = useState(false);

  const updateTitle = (e) => {
    const newProfessionNumbers = [...professionNumbers];
    newProfessionNumbers[index].title = e.target.value;
    setMap({ ...map, professionNumbers: newProfessionNumbers });
  };

  const updateBGColor = (e) => {
    const newProfessionNumbers = [...professionNumbers];
    newProfessionNumbers[index].bgColor = e.target.value;
    setMap({ ...map, professionNumbers: newProfessionNumbers });
  };

  return (
    <>
      {!isEditing && (
        <h2
          className="text-xs font-normal text-center px-2 py-1 w-full cursor-pointer text-[#000000] mb-1"
          style={{ backgroundColor: bgColor }}
          onClick={() => setIsEditing(true)}>
          {title}
        </h2>
      )}

      {isEditing && (
        <div className="w-full my-3">
          <div className="flex flex-col gap-1">
            <input
              value={title}
              onChange={updateTitle}
              className="w-full text-base px-2 py-1 bg-gray-800 text-white"
              autoFocus
            />
            <input
              value={bgColor}
              onChange={updateBGColor}
              className="w-full text-base px-2 py-1 bg-gray-800 text-white"
              autoFocus
            />
          </div>

          <div className="flex gap-2 items-center">
            <button
              className="text-2xl p-1"
              onClick={() => {
                const newProfessionNumbers = [...professionNumbers];
                newProfessionNumbers.splice(index, 1);
                setMap({ ...map, professionNumbers: newProfessionNumbers });
              }}>
              <FaWindowClose />
            </button>
            <button className="text-2xl p-1" onClick={() => setIsEditing(false)}>
              <FaCheck />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const MapColumnProfessionNumber = ({ index, professions, map, setMap }) => {
  const { professionNumbers } = map;

  const title = professionNumbers?.[index]?.title;
  const customTitle = professionNumbers?.[index]?.customTitle;
  const image = professionNumbers?.[index]?.image;
  const professionId = professionNumbers?.[index]?.professionId;
  const professionTitle = professions.find(
    (profession) => profession._id === professionId
  )?.jobRole_fr; // Change it to i18n.language here
  const aggregate = professionNumbers?.[index]?.aggregate;

  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  const updateTitle = (e) => {
    const newProfessionNumbers = [...professionNumbers];
    newProfessionNumbers[index].title = e.target.value;
    setMap({ ...map, professionNumbers: newProfessionNumbers });
  };

  const updateCustomTitle = (e) => {
    const newProfessionNumbers = [...professionNumbers];
    newProfessionNumbers[index].customTitle = e.target.value;
    newProfessionNumbers[index].professionId = "custom";
    setMap({ ...map, professionNumbers: newProfessionNumbers });
  };

  const updateProfessionImage = (e) => {
    const newProfessionNumbers = [...professionNumbers];
    newProfessionNumbers[index].image = e.target.files[0];
    setMap({ ...map, professionNumbers: newProfessionNumbers });
  };

  const updateProfessionId = (e) => {
    const newProfessionNumbers = [...professionNumbers];
    newProfessionNumbers[index].professionId = e.target.value;
    setMap({ ...map, professionNumbers: newProfessionNumbers });
  };

  return (
    <>
      {!isEditing && (
        <p
          className="text-xs font-normal text-left cursor-pointer text-[#000000] group"
          onClick={() => setIsEditing(true)}>
          <span
            className={`relative flex flex-row items-center w-full gap-2 ${
              aggregate === undefined ? "pl-3" : "pl-1"
            }`}>
            {aggregate && (
              <>
                <span className="relative w-[15%] text-left text-[#163F9B] py-[1.5px]">
                  {aggregate}
                </span>
                <span className="absolute left-[15%] h-full w-[0.5px] bg-black" />
              </>
            )}
            {!title && image && (
              <img
                src={getImageLink(image)}
                className="w-[15%] h-full object-cover"
                alt="Profession Image"
              />
            )}
            {title && <span className="w-[15%] text-right py-[1.5px]">{title}</span>}
            <span className="w-[100%] py-[1.5px]">{customTitle || professionTitle}</span>
          </span>
        </p>
      )}

      {isEditing && (
        <div className="w-full my-3">
          <div className="flex flex-col gap-1">
            <input
              ref={inputRef}
              className={""}
              type="file"
              accept="image/*"
              name="image"
              id="imageInput"
              onChange={updateProfessionImage}
            />
            <input
              value={title}
              onChange={updateTitle}
              placeholder="Profession number"
              className="w-full text-base px-2 py-1 bg-gray-800 text-white"
              autoFocus
            />
            <input
              value={customTitle}
              onChange={updateCustomTitle}
              placeholder="Custom title"
              className="w-full text-base px-2 py-1 bg-gray-800 text-white"
              autoFocus
            />
            <select
              value={professionId}
              onChange={updateProfessionId}
              className="w-full text-base px-1 py-1 bg-gray-800 text-white"
              autoFocus>
              <>
                <option value={null}>Select Profession</option>
                {professions.map((profession) => (
                  <option key={profession._id} value={profession._id}>
                    {profession.jobRole_fr}
                  </option>
                ))}
              </>
            </select>
          </div>
          <div className="flex gap-2 items-center">
            <button
              className="text-2xl p-1"
              onClick={() => {
                const newProfessionNumbers = [...professionNumbers];
                newProfessionNumbers.splice(index, 1);
                setMap({ ...map, professionNumbers: newProfessionNumbers });
              }}>
              <FaWindowClose />
            </button>
            <button className="text-2xl p-1" onClick={() => setIsEditing(false)}>
              <FaCheck />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const MapFooter = ({ map, setMap }) => {
  const { footer } = map;

  const [isEditing, setIsEditing] = useState(false);

  const updateFooterText = (e) => {
    setMap({ ...map, footer: { ...footer, text: e.target.value } });
  };

  const updateFooterQRLink = (e) => {
    setMap({ ...map, footer: { ...footer, qrLink: e.target.value } });
  };

  return (
    <>
      {!isEditing && (
        <div className="flex flex-row gap-2 mt-1 cursor-pointer">
          <div
            className={`${footer?.qrLink ? "w-[90%]" : "w-full"} text-xs text-[#000000] text-left`}
            onClick={() => setIsEditing(true)}
            dangerouslySetInnerHTML={{ __html: footer?.text }}
          />
          {footer?.qrLink && (
            <div className="w-[10%] flex items-center justify-center">
              <QRCode
                size={220}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={footer?.qrLink}
                viewBox={`0 0 220 220`}
              />
            </div>
          )}
        </div>
      )}

      {isEditing && (
        <div className="flex flex-col gap-2 items-end w-full my-3">
          <textarea
            value={footer?.text}
            onChange={updateFooterText}
            className="w-full text-sm px-3 py-2 bg-gray-800 text-white min-h-[200px]"
            autoFocus
          />
          <input
            value={footer?.qrLink}
            onChange={updateFooterQRLink}
            className="w-full text-sm px-3 py-2 bg-gray-800 text-white"
            autoFocus
          />
          <button
            className="text-2xl p-3 text-white bg-gray-800 rounded-full"
            onClick={() => setIsEditing(false)}>
            <FaCheck />
          </button>
        </div>
      )}
    </>
  );
};
