import Select, { components } from "react-select";
import { ThreeDotsIcon } from "../../asssets/icons";

const CustomDropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ThreeDotsIcon />
      </components.DropdownIndicator>
    )
  );
};

export default function InputSelect({
  themeId,
  className,
  label,
  error,
  touched,
  options,
  ...props
}) {
  return (
    <div className="signupinwrap">
      {label && (
        <label className="block mb-1 text-base font-bold text-cblack-primary">{label}</label>
      )}
      <Select
        styles={{
          control: (base) => ({
            ...base,
            borderRadius: "999px"
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none" // Remove the line between icon and label
          })
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: themeId === "ch" ? "#E5EFEF" : "gray",
            primary: themeId === "ch" ? "#006367" : "black"
          }
        })}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        className={`mb-4 rounded-full ${className}`}
        options={options}
        {...props}
      />
      {error && touched && <p className="text text-red-500">{error}</p>}
    </div>
  );
}
