import { useState } from "react";
import clsx from "clsx";

import { InfoIcon, TickIcon } from "../../../asssets/icons";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

export default function ItemWithMultipleButtons({
  children,
  classNames,
  isSelected,
  description,
  infoIcon,
  onClick
}) {
  const [showDescription, setshowDescription] = useState(false);

  return (
    <div
      className={clsx(
        `flex justify-between px-4 py-3.5 rounded-bl rounded-br cursor-pointer hover:bg-corange-100`,
        classNames,
        { [`bg-corange-100]`]: isSelected }
      )}
      onClick={(e) => {
        if (e.target.closest(".svg-button")) {
          setshowDescription(!showDescription);
        } else {
          onClick();
        }
      }}>
      <p className="text-lg text-left text-[#191919]">
        {children}
        {showDescription && <span className="block text-sm text-cblack-100">{description}</span>}
      </p>
      <div className="flex justify-start gap-x-3">
        {isSelected && TickIcon}
        {description && (
          <button type="button" className="svg-button w-[20px] h-[20px]">
            <InfoIcon color={"corange-100"} />
          </button>
        )}
        {infoIcon?.isVisible && (
          <InformationCircleIcon
            className={`w-[1rem] h-[1rem] text-cblack-300 hover:bg-corange-100 hover:text-white rounded-full`}
            onClick={infoIcon.onClick}
          />
        )}
      </div>
    </div>
  );
}
