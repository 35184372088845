import React, { useState,useRef,useEffect } from "react";
import { SketchPicker } from "react-color";


const ColorPicker = ({ color, handleChange }) => {
    const [showPicker, setShowPicker] = useState(false);
    const pickerRef = useRef(null);

    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false); // Close the picker
      }
    };

    useEffect(() => {
        // Add when the component mounts
        document.addEventListener("mousedown", handleClickOutside);
        // Remove when the component unmounts
        return () => document.removeEventListener("mousedown", handleClickOutside);
      }, []); // Empty array ensures this only runs on mount and unmount
    
  
    return (
      <div className="flex items-center gap-4 relative" >
        <div
          ref={pickerRef}
          className="z-30"
          >
          {showPicker && (
                <SketchPicker color={color}  onChangeComplete={handleChange} className="absolute right-0" />
          )}
        </div>
        <div className="w-5 h-5 border shadow-lg rounded-md" style={{ backgroundColor: color }} onClick={()=>setShowPicker(true) } />
       
      </div>
    );
  };

  export default ColorPicker;