import React, { useState, useEffect, useRef } from "react";
import { PlusSmallIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import BaseModal from "../../popups/model";
import { Input } from "../../form";
import { createRegion, getRegions, getUploadingFileData, uploadFileToAWS } from "../../../service";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RefreshIcon } from "../../../asssets/icons";
import AdminPanelButton from "../../form/button/adminPanelButton";
import OrgList from "./orgRegionsList";

export default function OrgRegions({ prefilledData }) {
  const [open, setOpen] = useState(false);
  const [regions, setRegions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(); // for image preview
  const [updatedOrderKey, setUpdatedOrderKey] = useState([]);

  const fileInputRef = useRef(null);

  const getImageLink = (value) => {
    // new image
    if (value?.type) {
      return URL.createObjectURL(value);
    }
    return process.env.REACT_APP_AWS_URL + value;
  };

  const submitHandler = async () => {
    setLoading(true);
    let logoLink = logo;

    try {
      if (logo?.type) {
        const {
          data: { url, finalName }
        } = await getUploadingFileData({
          name: "organization-logos/" + new Date().getTime() + "-" + logo.name,
          type: logo.type
        });
        await uploadFileToAWS(url, logo, logo.type);
        logoLink = finalName;
      }
      const postBody = {
        ...formik.values,
        logo: logoLink,
        orderKey: regions.length + 1
      };

      const res = await createRegion({ ...postBody }, prefilledData._id);
      getAdminRegions();

      toast.success(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      formik.resetForm();
      setOpen(!open);
      setLogo(null);
    }
  };

  async function getAdminRegions() {
    try {
      if (!prefilledData) return;
      const regions = await getRegions(prefilledData._id);
      regions.data.sort(function (a, b) {
        return a.orderKey - b.orderKey;
      });
      setRegions(regions.data);
    } catch (err) {
      toast.error("error while fetching admins");
      console.log(err);
    }
  }

  useEffect(() => {
    getAdminRegions();
  }, [prefilledData]);

  const formik = useFormik({
    initialValues: {
      name_de: "",
      name_en: "",
      name_fr: "",
      name_it: "",
      regionalLink: ""
    },
    //validationSchema: regionSchema,
    onSubmit: submitHandler
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempOrderKey = regions[result.source.index].orderKey;
    regions[result.source.index].orderKey = regions[result.destination.index].orderKey;
    regions[result.destination.index].orderKey = tempOrderKey;

    const temp = regions[result.source.index];
    regions[result.source.index] = regions[result.destination.index];

    regions[result.destination.index] = temp;
    const newData = updatedOrderKey.filter((item) => {
      if (
        item._id == regions[result.source.index]._id ||
        item._id == regions[result.destination.index]._id
      ) {
        return false;
      } else {
        return true;
      }
    });

    setUpdatedOrderKey([
      ...newData,
      regions[result.source.index],
      regions[result.destination.index]
    ]);
    setRegions([...regions]);
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <tbody
                        className="divide-y divide-gray-200"
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {regions.map((region, index) => {
                          return (
                            <Draggable key={region._id} draggableId={region._id} index={index}>
                              {(provided) => {
                                return (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>
                                    <td
                                      colSpan="6"
                                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                      <OrgList
                                        region={region}
                                        prefilledData={prefilledData}
                                        setOpen={setOpen}
                                        open={open}
                                        getAdminRegions={getAdminRegions}
                                        updatedOrderKey={updatedOrderKey}
                                      />
                                    </td>
                                  </tr>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setOpen(!open);
                          }}>
                          <PlusSmallIcon
                            className={`w-7 h-7 stroke-2 stroke-white bg-[#E3E3E3] rounded-full`}
                          />
                        </div>
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>
            </div>
          </div>
        </div>
      </div>
      <BaseModal open={open} setOpen={setOpen} title={"Create Region"}>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-2">
            <Input
              label="DE"
              name="name_de"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values["name_de"]}
              error={formik?.errors["name_de"]}
              touched={formik?.touched["name_de"]}
            />
            <Input
              label="EN"
              name="name_en"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values["name_en"]}
              error={formik?.errors["name_en"]}
              touched={formik?.touched["name_en"]}
            />
            <Input
              label="FR"
              name="name_fr"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values["name_fr"]}
              error={formik?.errors["name_fr"]}
              touched={formik?.touched["name_fr"]}
            />
            <Input
              label="IT"
              name="name_it"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values["name_it"]}
              error={formik?.errors["name_it"]}
              touched={formik?.touched["name_it"]}
            />
            <Input
              className="col-span-2"
              label="regional Link"
              name="regionalLink"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values["regionalLink"]}
              error={formik?.errors["regionalLink"]}
              touched={formik?.touched["regionalLink"]}
            />
          </div>
          {logo && (
            <div className="group relative inline-block h-full">
              {/* Remove Icon */}
              <div
                className="hidden group-hover:block absolute -top-2 -right-2 w-6 h-6 bg-black cursor-pointer rounded-full z-10"
                onClick={() => {
                  setLogo(null);
                  if (fileInputRef.current) fileInputRef.current.value = "";
                }}>
                <XCircleIcon className="w-6 h-6 text-red-500" />
              </div>
              <label
                htmlFor="imageInput"
                className="relative block w-max group h-full"
                onClick={() => {
                  if (fileInputRef.current) fileInputRef.current.click();
                }}>
                {/* Reload logo */}
                <div className="hidden group-hover:flex absolute top-0 left-0 bottom-0 right-0 w-full h-full bg-black opacity-50 cursor-pointer items-center justify-center">
                  <RefreshIcon color="#FFFFFF" />
                </div>

                <div className="flex justify-center bg-[#F9F9F9] rounded-md p-4 w-[200px] h-full">
                  <div className="w-[80px] h-full rounded-full overflow-hidden">
                    <img
                      src={getImageLink(logo)}
                      className="w-full h-full object-cover cursor-pointer"
                    />
                  </div>
                </div>
              </label>
            </div>
          )}
          {!logo && (
            <>
              <div className="flex justify-center w-[200px] bg-[#ffffff] rounded relative px-4 py-3 cursor-pointer">
                <div
                  className={
                    logo &&
                    "flex justify-start items-center gap-4 px-4 py-3 cursor-pointer shadow-sm "
                  }
                  onClick={() => {
                    fileInputRef.current.click();
                  }}>
                  <PlusSmallIcon
                    className={`w-7 h-7 stroke-2 stroke-white bg-[#E3E3E3] rounded-full`}
                  />
                </div>
              </div>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                name="image"
                id="imageInput"
                onChange={(event) => {
                  setLogo(event.currentTarget.files[0]);
                }}
                className={"hidden"}
              />
            </>
          )}

          <div className="mt-5 sm:mt-4 grid grid-cols-2 gap-4">
            <AdminPanelButton
              type="submit"
              isSave={true}
              loading={loading}
              bgColor="#FC9E5A"
              textColor="#FFFFFF"
              color="white"
              onClick={() => {}}>
              create
            </AdminPanelButton>
          </div>
        </form>
      </BaseModal>
    </>
  );
}
