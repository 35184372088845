import { ArrowPathIcon } from "@heroicons/react/24/outline";

export default function TextArea({
  defaultValue,
  label,
  placeholder,
  labelClassName,
  className,
  error,
  touched,
  isDark = false,
  inputClassName,
  onReset,
  type = "text",
  ...props
}) {
  return (
    <div className={`${className}`}>
      {label && <label className={`block font-bold mb-1 ${labelClassName} `}>{label}</label>}
      {onReset && props.value && (
        <ArrowPathIcon
          className="w-4 inline-block ml-2 cursor-pointer"
          title="reset"
          onClick={onReset}
        />
      )}
      <textarea
        className={`border border-[#757575] px-4 py-3 rounded-md min-h-56 w-full placeholder-[#606060] bg-[#F6F5F5] ${inputClassName} focus:border-[#000000] focus:ring-0 focus:outline-none`}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type={type}
        {...props}
      />
      {error && touched && (
        <p className="text text-red-500" data-tesid="error-text">
          {error}
        </p>
      )}
    </div>
  );
}
