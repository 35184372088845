import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { ProfileContext } from "../contexts/profileContext";
import { PageWrapper } from "../components/admin";
import { OrgStrength , OrgRoles} from "../components/admin/organization";
import { FaEdit } from "react-icons/fa";
import {
  ArrowBackIcon

} from "../asssets/icons";

const VALID_UI = {
  ROLES: "roles",
  STRENGTHS: "strenghts"
};

export default function StrenghtsAndRoles() {
  const [profile] = useContext(ProfileContext);
  const [org, setOrg] = useState(null);
  const [currentUI, setCurrentUI] = useState(null);

  const { orgId } = useParams();
  const { organizations } = profile;

  useEffect(() => {
    if (!orgId || !organizations?.length) return;
    const org = organizations.find((org) => org._id === orgId);
    if (!org) return;

    setOrg({ ...org });

  }, [orgId, organizations]);

  const handleStrenghtsClick = () => {
    setCurrentUI(VALID_UI.STRENGTHS);
  };

  const handleRolesClick = () => {
    setCurrentUI(VALID_UI.ROLES);
  };

  const handleUIResetClick = () => {
    setCurrentUI(null);
  };


  return (
    <div className="h-screen overflow-y-auto pb-20">
      {!currentUI && (
        <PageWrapper pageHeading={"Organisation Strenghts & Roles"}>
          <div className="flex flex-row justify-between items-center">
            <div className="w-full bg-[#ffffff] rounded relative">
              <div className="flex justify-start items-center gap-4 px-4 py-3 cursor-pointer shadow-sm">
                Strenghts
              </div>
            </div>
            <div className="cursor-pointer" onClick={handleStrenghtsClick}>
              <FaEdit />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center mt-4">
            <div className="w-full bg-[#ffffff] rounded relative">
              <div className="flex justify-start items-center gap-4 px-4 py-3 cursor-pointer shadow-sm">
                Roles
              </div>
            </div>
            <div className="cursor-pointer" onClick={handleRolesClick}>
              <FaEdit />
            </div>
          </div>
        </PageWrapper>
      )}
      {currentUI === VALID_UI.STRENGTHS && (
        <>
          <button
            className="flex gap-3 items-center w-[fit-content] flex-wrap mb-10"
            onClick={handleUIResetClick}>
            <span className="w-8 h-8">
              <ArrowBackIcon />
            </span>
            <h2 className="text-xl leading-10 font-bold">Strengths</h2>
          </button>
          <OrgStrength org={org}></OrgStrength>
          
        </>
      )}
      {currentUI === VALID_UI.ROLES && (
        <>
          <button
            className="flex gap-3 items-center w-[fit-content] flex-wrap mb-10"
            onClick={handleUIResetClick}>
            <span className="w-8 h-8">
              <ArrowBackIcon />
            </span>
            <h2 className="text-xl leading-10 font-bold">Roles</h2>
          </button>
          <OrgRoles org={org} orgId={orgId}></OrgRoles>
        </>
      )}
    </div>
  );
}
