import React, { useEffect, useState, useRef } from "react";

import { ChevronDownIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { CalendarIcon, EditIcon, SortIcon } from "../../../../../asssets/icons";
import { getPersonalInfomationFormValidFields, updateProfileType } from "../../../../../service";
import ComboBox from "../../../../common/comboBox";
import { Button } from "../../../../form";
import { FaStar } from "react-icons/fa";

const PersonalInformationFormEditor = ({ org, profile, onSubmit = () => {} }) => {
  const [validInputFields, setValidInputFields] = useState([]);
  const [inputFields, setInputFields] = useState(profile?.inputFields || []);
  const [showNewInputFieldUI, setShowNewInputFieldUI] = useState(false);
  const [selectedNewInputField, setSelectedNewInputField] = useState(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getPersonalInfomationFormValidFields(org._id);
        setValidInputFields(data);
      } catch (error) {
        console.error("Fetch fields error", error);
      }
    })();
  }, []);

  const handleNewInputFieldAdd = () => {
    if (!selectedNewInputField) {
      return;
    }

    const updatedInputFields = JSON.parse(JSON.stringify([...inputFields]));
    updatedInputFields.push({
      _id: new Date().getTime().toString(),
      field: selectedNewInputField,
      required: false
    });
    setInputFields(updatedInputFields);
    setSelectedNewInputField(null);
    setShowNewInputFieldUI(false);
  };

  const getValidInputFields = () => {
    return validInputFields.filter(
      (field) => !inputFields.some(({ field: inputField }) => inputField?._id === field?._id)
    );
  };

  const handleRemoveInputField = (inputFieldIndex) => {
    setInputFields(inputFields.filter((_, index) => index !== inputFieldIndex));
  };

  const handleFormSubmit = async () => {
    if (!inputFields.length || !profile?._id) {
      return;
    }

    try {
      setIsFormSubmitting(true);
      await updateProfileType(org._id, {
        _id: profile._id,
        type: "personalInformation",
        inputFields
      });
      toast.success("Personal Information Form saved successfully.");
      onSubmit();
    } catch (error) {
      toast.error("Failed to save the form.");
      console.error("Failed to save the form: ", error);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const newInputFieldUI = () => {
    if (showNewInputFieldUI) {
      return (
        <div className="relative flex justify-between items-center gap-4 mt-4 border-t-2 pt-2">
          <ComboBox options={getValidInputFields()} onSelect={setSelectedNewInputField} />
          <div className="flex gap-2">
            <Button.AdminPanelButton
              type="button"
              isSave={true}
              bgColor="#515151"
              textColor="#FFFFFF"
              color="white"
              onClick={handleNewInputFieldAdd}>
              Add
            </Button.AdminPanelButton>
            <Button.ButtonOutlined
              className="rounded-full border-2 border-[#515151] text-[#515151]"
              type="button"
              handler={() => setShowNewInputFieldUI(false)}>
              X
            </Button.ButtonOutlined>
          </div>
        </div>
      );
    }

    return (
      <div className="flex justify-between items-center mt-4 border-t-2 pt-2">
        {inputFields?.length !== validInputFields?.length && (
          <PlusIcon
            className={`w-7 h-7 stroke-2 stroke-white bg-[#606060] rounded-md cursor-pointer`}
            onClick={() => setShowNewInputFieldUI(true)}
          />
        )}
        <Button.AdminPanelButton
          type="submit"
          isSave={true}
          loading={isFormSubmitting}
          bgColor="#515151"
          textColor="#FFFFFF"
          color="white"
          onClick={handleFormSubmit}>
          Save
        </Button.AdminPanelButton>
      </div>
    );
  };

  const onDragEnd = (result) => {
    try {
      const { draggableId, destination: { index } = {} } = result;
      const updatedInputFields = JSON.parse(JSON.stringify([...inputFields]));
      const dragItemIndex = inputFields.findIndex((inputField) => inputField._id === draggableId);
      updatedInputFields.splice(index, 0, updatedInputFields.splice(dragItemIndex, 1)[0]);
      setInputFields(updatedInputFields);
    } catch (error) {
      console.error("Drag error", error);
    }
  };

  const toggleFieldRequired = (index) => {
    const updatedInputFields = JSON.parse(JSON.stringify([...inputFields]));
    updatedInputFields[index].required = !updatedInputFields[index].required;
    setInputFields(updatedInputFields);
  };

  return (
    <>
      {/* CURRENT INPUT FIELDS */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="inputFields">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {inputFields.map((inputField, inputFieldIndex) => (
                <Draggable
                  key={inputField._id}
                  draggableId={inputField._id}
                  index={inputFieldIndex}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      id={inputField._id}
                      className="flex gap-2 items-center mb-4 !top-auto !left-auto">
                      <span
                        className={`w-4 h-4 cursor-pointer ${
                          inputField?.required ? "text-red-500" : "text-gray-500"
                        }`}
                        onClick={() => toggleFieldRequired(inputFieldIndex)}>
                        <FaStar />
                      </span>
                      <span className="w-4 h-4">
                        <SortIcon />
                      </span>

                      <div className="w-full">
                        <div className="flex items-center justify-between gap-4 mb-2">
                          <span className="text-xs text-white bg-[#515151] px-1">
                            {inputFieldIndex + 1}. {inputField?.field?.key}
                          </span>
                          <div className="flex items-center justify-between gap-2">
                            <EditIcon className="w-4 h-4" />
                            <MinusIcon
                              className={`w-4 h-4 stroke-2 stroke-white bg-[#606060] rounded cursor-pointer`}
                              onClick={() => handleRemoveInputField(inputFieldIndex)}
                            />
                          </div>
                        </div>
                        <div>{inputFieldTypeComponent[inputField?.field?.type]}</div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* NEW INPUT FIELD */}
      {newInputFieldUI()}
    </>
  );
};

const inputFieldTypeComponent = {
  string: (
    <div className="bg-[#f6f6f6] p-4 rounded-lg text-[#515151] h-12 flex-1">
      {/* <span className="max-w-32 truncate">String Input Field</span> */}
    </div>
  ),
  dropdown: (
    <div className="bg-[#f6f6f6] p-4 rounded-lg text-[#515151] flex items-center justify-between h-12 flex-1">
      {/* <span className="max-w-32 truncate">Dropdown Input Field</span> */}
      <div className="flex-grow"></div> {/* spacer */}
      <ChevronDownIcon className="w-5" />
    </div>
  ),
  date: (
    <div className="bg-[#f6f6f6] p-4 rounded-lg text-[#515151] flex items-center justify-between h-12 flex-1">
      {/* <span className="max-w-32 truncate">Date Input Field</span> */}
      <div className="flex-grow"></div> {/* spacer */}
      <CalendarIcon className="w-5" />
    </div>
  ),
  checkbox: (
    <div className="bg-[#f6f6f6] p-4 rounded-lg text-[#515151] flex items-center justify-between h-12 w-12">
      <div className="flex-grow"></div> {/* spacer */}
    </div>
  ),
  twoCheckboxFields: (
    <div className="flex">
      <div className="flex-1">
        <div className="bg-[#f6f6f6] p-4 rounded-lg text-[#515151] flex items-center justify-between h-12 w-12">
          <div className="flex-grow"></div> {/* spacer */}
        </div>
      </div>
      <div className="flex-1">
        <div className="bg-[#f6f6f6] p-4 rounded-lg text-[#515151] flex items-center justify-between h-12 w-12">
          <div className="flex-grow"></div> {/* spacer */}
        </div>
      </div>
    </div>
  ),
  twoDropdownFields: (
    <div className="flex gap-12">
      <div className="bg-[#f6f6f6] p-4 rounded-lg text-[#515151] flex items-center justify-between h-12 flex-1">
        {/* <span className="max-w-32 truncate">Dropdown Input Field</span> */}
        <div className="flex-grow"></div> {/* spacer */}
        <ChevronDownIcon className="w-5" />
      </div>
      <div className="bg-[#f6f6f6] p-4 rounded-lg text-[#515151] flex items-center justify-between h-12 flex-1">
        {/* <span className="max-w-32 truncate">Dropdown Input Field</span> */}
        <div className="flex-grow"></div> {/* spacer */}
        <ChevronDownIcon className="w-5" />
      </div>
    </div>
  ),
  threeStringFields: (
    <div>
      <div className="bg-[#f6f6f6] p-4 rounded-lg text-[#515151] h-12 flex-1 mb-2">
        {/* <span className="max-w-32 truncate">String Input Field</span> */}
      </div>
      <div className="bg-[#f6f6f6] p-4 rounded-lg text-[#515151] h-12 flex-1 mb-2">
        {/* <span className="max-w-32 truncate">String Input Field</span> */}
      </div>
      <div className="bg-[#f6f6f6] p-4 rounded-lg text-[#515151] h-12 flex-1">
        {/* <span className="max-w-32 truncate">String Input Field</span> */}
      </div>
    </div>
  )
};

export default PersonalInformationFormEditor;
