import React, { useState } from "react";
import { PlusSmallIcon } from "@heroicons/react/24/outline";

import { CancelIcon } from "../../asssets/icons";
import Button from "./button";

export default function DropdownToggle({
  heading,
  subheading,
  toggleButtonText,
  errorMessage,
  list = [],
  formik,
  name = "",
  onClick
}) {
  const [areListItemsVisible, setAreListItemsVisible] = useState(false);

  const toggleVisibility = () => {
    setAreListItemsVisible(!areListItemsVisible);
  };

  return (
    <div className="mb-8">
      {heading && <p className="text-base font-bold text-[#191919] mb-1">{heading}</p>}
      {subheading && <p className="text-base text-[#757575] mb-2">{subheading}</p>}

      {formik?.errors[name] && formik?.touched[name] && (
        <p className="text-sm text-red-600">{formik?.errors[name]}</p>
      )}
      {/* SELECTED ITEMS */}
      {list.length > 0 &&
        list
          .filter(({ isSelected }) => isSelected)
          .map(({ name, _id }) => (
            <Button.ItemwithSingleButton
              key={_id}
              handleClick={() => onClick(_id)}
              icon={CancelIcon}>
              {name}
            </Button.ItemwithSingleButton>
          ))}
      {/* ADD NEW DROPDOWN  */}
      <div type={"button"} id={name} name={name} onBlur={formik?.handleBlur} className="w-[100%]">
        <div className="w-full bg-[#ffffff] rounded relative">
          <div
            className="flex justify-start items-center gap-4 px-4 py-3 cursor-pointer shadow-sm"
            onClick={toggleVisibility}>
            <PlusSmallIcon className={`w-7 h-7 stroke-2 stroke-white bg-[#E3E3E3] rounded-full`} />
            <p className="text-md font-bold text-gray-900">{toggleButtonText}</p>
          </div>
          {/* DROPDOWN ITEMS */}
          {list.length > 0 && areListItemsVisible && (
            <div className="relative left-0 right-0 bg-[#F9F9F9] border rounded-b-md overflow-auto max-h-60 mini-scrollbar">
              <div className="scrollbar-thumb-gray-400 scrollbar-track-gray-200 scrollbar-thin">
                <div className="scrollbar-thumb rounded-full bg-gray-700"></div>
              </div>
              <div className="max-h-60">
                {list.map(({ _id, name, description, isSelected }) => {
                  return (
                    <Button.ItemWithMultipleButtons
                      key={_id}
                      isSelected={isSelected}
                      onClick={() => onClick(_id)}
                      description={description}>
                      {name}
                    </Button.ItemWithMultipleButtons>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {errorMessage && <p className="ml-1 text-sm text-red-500">{errorMessage}</p>}
    </div>
  );
}
