import React from "react";

export default function PageWrapper({ pageHeading, pageSubheading, children, fullWidth = false }) {
  return (
    <div className="flex flex-col items-start h-[calc(100vh_-_6rem)]">
      <div className={`mb-5 w-full ${fullWidth ? "lg:w-full" : "lg:max-w-[768px]"}`}>
        <h1 className="text-xl font-bold text-left text-[#191919] mb-1">{pageHeading}</h1>
        <p className="text-lg text-left text-[#757575] mb-2">{pageSubheading}</p>
        <hr className="border-[0.5px] border-solid" />
      </div>
      <div className={`w-full mb-4 ${fullWidth ? "lg:w-full" : "lg:max-w-[768px]"}`}>
        {children}
      </div>
    </div>
  );
}
