import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  CheckedBoxIcon,
  InfoBlackIcon,
  PlusIcon,
  UncheckedBoxIcon
} from "../../../../asssets/icons";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Input, Spinner } from "../../../form";
import AdminPanelButton from "../../../form/button/adminPanelButton";
import { BaseModal } from "../../../popups";
import Profiles from "./profile/profile";
import TeacherAdminSetup from "./teacherAdmin/school";
import ToggleComponent from "../../../common/toggleComponent";
import { createService, getServices } from "../../../../service";

const services = [
  {
    id: "profile-setup",
    title: "Profile Setup Platform"
  },
  {
    id: "mission-future-school",
    title: "mission future School Platform"
  },
  {
    id: "mission-future-team",
    title: "mission future Team Platform"
  }
];

const missionFuturePlatformData = [
  {
    id: "teachers-admin-portal",
    name: "Teachers Admin Portal",
    key: "hasAdminPortal"
  },
  {
    id: "teachers-registration-webpage",
    name: "Teachers Registration Webpage",
    key: "hasRegistrationWebpage"
  },
  {
    id: "registration-form-iframe",
    name: "Registration Form (i-Frame)",
    key: "hasRegistrationFormIframe"
  },
  {
    id: "class-student-setup",
    name: "Class Website / Student Setup & Profile",
    key: "hasClassStudentSetup"
  },
  {
    id: "workshop-portal",
    name: "Workshop Portal",
    key: "hasWorkshopPortal"
  }
];

export default function OrgServices({ org, service }) {
  const navigate = useNavigate();

  const [showServiceModal, setShowServiceModal] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [servicesList, setServicesList] = useState(services);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [isAddServiceLoading, setIsAddServiceLoading] = useState(false);
  const [addedServicesList, setAddedServicesList] = useState([]);
  const [mfPlatformSelections, setMfPlatformSelections] = useState(missionFuturePlatformData);
  const [currentService, setCurrentService] = useState(null);

  async function fetchAddedServices() {
    try {
      const { data } = await getServices(org._id);
      setAddedServicesList(data);

      if (!service) return;
      const innerService = data?.find((s) => s?.type?.toLowerCase() === service?.toLowerCase());

      setCurrentService(innerService);
    } catch (error) {
      toast.error("Failed to get services list.");
      console.error("Failed to get services list: ", error);
    }
  }

  useEffect(() => {
    if (org?._id) {
      setCurrentService(null);

      fetchAddedServices();
    }
  }, [org, service]);

  const handleElementToggle = (itemIndex) => {
    const updatedList = JSON.parse(JSON.stringify(mfPlatformSelections));
    updatedList[itemIndex].isActive = !updatedList[itemIndex].isActive;
    setMfPlatformSelections(updatedList);
  };

  const handleComponent = (service) => {
    navigate(`/dashboard/organisation/services/${org?._id}/${service?.type}`);
  };

  const handleCloseService = () => {
    navigate(`/dashboard/organisation/services/${org?._id}`);
  };

  const handleServiceOpen = () => {
    setShowServiceModal(!showServiceModal);
  };

  const handleServiceSelectClick = (selectedServiceIndex) => {
    const updatedServices = servicesList?.map((service, index) => ({
      ...service,
      isChecked: index === selectedServiceIndex
    }));

    setSelectedServiceId(updatedServices[selectedServiceIndex].id);

    setServicesList(updatedServices);
  };

  const handleAddService = async () => {
    const selectedService = servicesList.find((service) => service.isChecked);
    if (!selectedService) {
      toast.info("Please select a service");
      return;
    }

    if (!serviceName || serviceName.trim() === "") {
      toast.info("Please add a valid service name");
      return;
    }

    const serviceInfo = {
      serviceKey: selectedServiceId,
      serviceName
    };

    if (["mission-future-school", "mission-future-team"].includes(selectedServiceId)) {
      mfPlatformSelections.forEach((element) => {
        serviceInfo[element.key] = element.isActive || false;
      });
    }

    try {
      setIsAddServiceLoading(true);
      await createService(serviceInfo, org._id);
      toast.success("Service added successfully");
      setShowServiceModal(false);
      fetchAddedServices();
    } catch (error) {
      toast.error("Failed to create the service.");
      console.error("Failed to create the service: ", error);
    } finally {
      setIsAddServiceLoading(false);
    }
  };

  return (
    <>
      {!currentService?.type && (
        <div className="p-2 rounded border border-solid">
          {addedServicesList?.map((addedService) => (
            <div className="flex justify-between mb-3" key={addedService._id}>
              <h1 className="font-bold">{addedService.name}</h1>
              <div className="flex items-center gap-2">
                <div onClick={() => handleComponent(addedService, addedService.type)}>
                  <PencilIcon className="w-[1rem] h-[1rem] mr-2 cursor-pointer" />
                </div>
              </div>
            </div>
          ))}
          <button onClick={handleServiceOpen} className="mt-4">
            <PlusIcon />
          </button>
          <BaseModal open={showServiceModal} setOpen={setShowServiceModal} title="Add Service">
            <div className="flex flex-col">
              <div className="mt-3 p-5 rounded border border-solid">
                <h2 className="font-bold">Service Type</h2>
                <div className="flex responsive flex-col gap-3">
                  {servicesList?.map((service, index) => (
                    <div key={index} className="relative">
                      <div>
                        <Input
                          className="py-2"
                          inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                          placeholder={service.title}
                          disabled
                        />
                      </div>
                      <div
                        onClick={() => handleServiceSelectClick(index)}
                        className="absolute top-4 right-0 inline-block mr-3">
                        {service.isChecked ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-10">
                  <h2 className="font-bold">Service Name</h2>
                  <div>
                    <Input
                      className="py-2"
                      inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                      placeholder=""
                      onChange={(e) => setServiceName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-10">
                  {(selectedServiceId === "mission-future-school" ||
                    selectedServiceId === "mission-future-team") &&
                    mfPlatformSelections?.map((element, index) => (
                      <div key={index} className="mt-3">
                        <div className="flex justify-between">
                          <h2 className="font-semibold">{element.name}</h2>
                          <ToggleComponent
                            handleCompToggle={() => handleElementToggle(index)}
                            element={element.id}
                            isToggle={element.isActive}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="mt-10">
                <div className="flex mt-2 justify-center">
                  <AdminPanelButton
                    type="submit"
                    isSave={true}
                    bgColor="#FC9E5A"
                    textColor="#FFFFFF"
                    color="white"
                    onClick={handleAddService}>
                    Add <Spinner isSubmitting={isAddServiceLoading} />
                  </AdminPanelButton>
                </div>
                <div className="flex gap-2 mt-7 p-5 rounded bg-[#F6F5F5]">
                  <div className="mt-3">
                    <InfoBlackIcon />
                  </div>
                  <div>Once added, you can add edit the contents in the organisation settings.</div>
                </div>
              </div>
            </div>
          </BaseModal>
        </div>
      )}

      {currentService?.type === "profile-setup" && (
        <Profiles
          title={currentService?.name}
          currentService={currentService}
          setShowComponent={handleCloseService}
          org={org}
        />
      )}

      {(currentService?.type === "mission-future-school" ||
        currentService?.type === "mission-future-team") && (
        <TeacherAdminSetup
          title={currentService?.name}
          currentService={currentService}
          setShowComponent={handleCloseService}
          org={org}
        />
      )}
    </>
  );
}
