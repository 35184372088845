import FullRoundedButton from "./fullRoundedButton";
import ButtonOutlined from "./buttonOutlined";
import ButtonRounded from "./buttonRounded";
import ButtonNext from "./buttonNext";
import ItemWithMultipleButtons from "./itemWithMultipleButtons";
import ItemwithSingleButton from "./itemWithSingleButton";
import AdminPanelButton from "./adminPanelButton";

const Button = {
  FullRoundedButton,
  ButtonOutlined,
  ButtonRounded,
  ButtonNext,
  ItemWithMultipleButtons,
  ItemwithSingleButton,
  AdminPanelButton
};

export default Button;
