import { PlusSmallIcon, XCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { RefreshIcon } from "../../asssets/icons";

const ImageUploader = ({ label = "Image", image, setImage, inputRef }) => {
  const getImageLink = (value) => {
    // new image
    if (value?.type) {
      return URL.createObjectURL(value);
    }
    return process.env.REACT_APP_AWS_URL + value;
  };

  return (
    <div className="border-2 border-gray-500 rounded my-2 p-4">
      <p className="text-base font-bold text-[#191919] mb-2">{label}</p>
      <div className="group relative inline-block">
        {/* Remove Icon */}
        <div
          className="hidden group-hover:block absolute -top-2 -right-2 w-6 h-6 bg-black cursor-pointer rounded-full z-10"
          onClick={() => {
            setImage(null);
            if (inputRef.current) inputRef.current.value = "";
          }}>
          <XCircleIcon className="w-6 h-6 text-red-500" />
        </div>
        <label
          htmlFor="imageInput"
          className="relative block w-max group"
          onClick={() => {
            if (inputRef.current) inputRef.current.click();
          }}>
          {/* Reload logo */}
          <div className="hidden group-hover:flex absolute top-0 left-0 bottom-0 right-0 w-full h-full bg-black opacity-50 cursor-pointer items-center justify-center">
            <span className="w-8 h-8">
              <RefreshIcon color="#FFFFFF" />
            </span>
          </div>

          {image && (
            <div className="bg-[#F9F9F9] rounded-md p-4 w-[200px] h-[200px]">
              <div className="w-full h-full rounded-full overflow-hidden">
                <img
                  src={getImageLink(image)}
                  className="w-full h-full object-cover cursor-pointer"
                  alt=""
                />
              </div>
            </div>
          )}
        </label>
      </div>
      <div className="w-full bg-[#ffffff] rounded relative px-4 py-3 cursor-pointer">
        <div
          className={
            image ? "flex justify-start items-center gap-4 px-4 py-3 cursor-pointer shadow-sm" : ""
          }
          onClick={() => {
            inputRef.current.click();
          }}>
          <PlusSmallIcon className={`w-7 h-7 stroke-2 stroke-white bg-[#E3E3E3] rounded-full`} />
        </div>
      </div>

      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        name="image"
        id="imageInput"
        onChange={(event) => {
          setImage(event.currentTarget.files[0]);
        }}
        className={"hidden"}
      />
    </div>
  );
};

export default ImageUploader;
