import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const uninterceptedAxiosInstance = axios.create();
const baseAxios = axios.create();
const adminAxios = axios.create();

baseAxios.defaults.baseURL = BASE_URL;
adminAxios.defaults.baseURL = BASE_URL + "/master-admin";

adminAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem("masterToken");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

adminAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log("ERRROROR", error);
    if (error.response?.status === 401) {
      console.log("Unauthorized");
      localStorage.removeItem("masterToken");
      window.location.href = "/";
    }
    // return new Error(error);
    return Promise.reject(error);
  }
);

export const adminLogin = (body) => {
  return baseAxios.post(`/auth/master-admin`, body);
};

export const adminMagicVerify = (body) => {
  return baseAxios.post(`/auth/admin-magic`, body);
};

export const fetchOrganizations = () => {
  return adminAxios.get(`/organization`);
};

export const createOrganization = (body) => {
  return adminAxios.post(`/organization`, body);
};

export const updateOrganizationInfo = (body) => {
  return adminAxios.put(`/organization`, body);
};

export const updateOrganizationContactInfo = (body) => {
  return adminAxios.post(`/organization-contact`, body);
};

export const updateOrganizationTheme = (body) => {
  return adminAxios.patch(`/organization-theme`, body);
};

export const getOrganizationProfileTypes = (id) => {
  return adminAxios.get(`/profile-types/${id}`);
};

export const createOrganizationProfileType = (id, body) => {
  return adminAxios.post(`/profile-types/${id}`, body);
};

export const updateOrganizationProfileType = (id, body) => {
  return adminAxios.put(`/profile-types/${id}`, body);
};

export const getProfileTypePages = (id) => {
  return adminAxios.get(`/pages/${id}`);
};

export const getProfileTypeFields = (id) => {
  return adminAxios.get(`/fields/${id}`);
};

export const fetchAdmins = () => {
  return adminAxios.get("");
};

export const getAdminProfileById = (id) => {
  return adminAxios.get(`/${id}`);
};

export const createAdmin = (body) => {
  return adminAxios.post("", body);
};

export const updateAdminById = (body, id) => {
  return adminAxios.put(`/${id}`, body);
};

export const createAccessKey = (body, organisation) => {
  return adminAxios.post(`/organization/${organisation}/access-token`, body);
};

export const getAccessKeyByOrg = (organisation) => {
  return adminAxios.get(`/organization/${organisation}/access-token`);
};

export const updateAccesKey = (body, organisation, id) => {
  return adminAxios.put(`/organization/${organisation}/access-token/${id}`, body);
};

export const deleteAccesKey = (organisation, id) => {
  return adminAxios.delete(`/organization/${organisation}/access-token/${id}`);
};

export const getRegions = (organisation) => {
  return adminAxios.get(`/regions/${organisation}`);
};

export const createRegion = (body, organisation) => {
  return adminAxios.post(`/regions/${organisation}`, body);
};

export const updateRegion = (body, organisation) => {
  return adminAxios.patch(`/regions/${organisation}`, body);
};

export const deleteRegion = (organisation, body) => {
  return adminAxios.delete(`/regions/${organisation}`, { params: body });
};

export const getAcademies = (organisation) => {
  return adminAxios.get(`/academy/${organisation}`);
};

export const createAcademy = (body, organisation) => {
  return adminAxios.post(`/academy/${organisation}`, body);
};

export const updateAcademy = (body, organisation) => {
  return adminAxios.patch(`/academy/${organisation}`, body);
};

export const deleteAcademy = (organisation, body) => {
  return adminAxios.delete(`/academy/${organisation}`, { params: body });
};

export const getEstablishments = (organisation) => {
  return adminAxios.get(`/establishment/${organisation}`);
};

export const createEstablishment = (body, organisation) => {
  return adminAxios.post(`/establishment/${organisation}`, body);
};

export const updateEstablishment = (body, organisation) => {
  return adminAxios.patch(`/establishment/${organisation}`, body);
};

export const deleteEstablishment = (organisation, body) => {
  return adminAxios.delete(`/establishment/${organisation}`, {
    params: body
  });
};

export const getProfessionMaps = (organization) => {
  return adminAxios.get(`/profession-map/${organization}`);
};

export const saveProfessionMap = (organization, body) => {
  return adminAxios.post(`/profession-map/${organization}`, body);
};

export const deleteProfessionMap = (organization, _id) => {
  return adminAxios.delete(`/profession-map/${organization}`, { params: { _id } });
};

export const createService = (body, organization) => {
  return adminAxios.post(`/services/${organization}`, body);
};

export const updateService = (body, organization) => {
  return adminAxios.put(`/services/${organization}`, body);
};

export const getServices = (organisation) => {
  return adminAxios.get(`/services/${organisation}`);
};

export const getPermissions = () => {
  return adminAxios.get(`/permissions`);
};

export const getAdminProfile = () => {
  return adminAxios.get(`/me`);
};

export const getServiceDetails = (organisation, serviceId) => {
  return adminAxios.get(`/service-details/${organisation}`, {
    params: { serviceId }
  });
};

export const createNewProfileType = (id, body) => {
  return adminAxios.post(`/profile-type/${id}`, body);
};

export const updateProfileType = (id, body) => {
  return adminAxios.put(`/profile-type/${id}`, body);
};

export const getPersonalInfomationFormValidFields = (organizationId) => {
  return adminAxios.get(`/valid-input-fields/${organizationId}`);
};

export const getTeachersPortal = (id, organization) => {
  return adminAxios.get(`/teachers-portal/${organization}/${id}`);
};

export const updateTeachersPortal = (id, organization, body) => {
  return adminAxios.put(`/teachers-portal/${organization}/${id}`, body);
};

export const getSystemAdminUserGroups = (organization) => {
  return adminAxios.get(`/system-admin-groups/${organization}`);
};

export const getProfessions = (organization) => {
  return baseAxios.get(`/public/professions`, { params: { organization } });
};

// Get Uplaoding File Link and Final Name
export const getUploadingFileData = (body) => {
  return baseAxios.post("/uploader/upload", { ...body, keepOriginal: true });
};

// Upload File to the Link
export const uploadFileToAWS = (url, file, contentType) => {
  return uninterceptedAxiosInstance.put(url, file, {
    headers: {
      "Content-type": contentType
    }
  });
};

export const getOrgStrength = (organization) => {
  return adminAxios.get(`/strengths/${organization}`);
};

export const addStrength = (organisation, body) => {
  return adminAxios.post(`/strengths/${organisation}`, body);
};

export const updateStrength = (organisation, body) => {
  return adminAxios.patch(`/strengths/${organisation}`, body);
};

export const deleteStrength = (organisation, body) => {
  return adminAxios.delete(`/strengths/${organisation}`, { params: body });
};

export const getOrgRoles = (organization) => {
  return adminAxios.get(`/roles/${organization}`);
};

export const addRole = (organisation, body) => {
  console.log("roles body ", body);
  return adminAxios.post(`/roles/${organisation}`, body);
};

export const updateRole = (organisation, body) => {
  return adminAxios.patch(`/roles/${organisation}`, body);
};

export const deleteRole = (organisation, body) => {
  return adminAxios.delete(`/roles/${organisation}`, { params: body });
};

export const getBadgeUIConfig = (organisation) => {
  return adminAxios.get(`/badge-ui-config/${organisation}`);
};
export const updateBadgeUIConfig = (organisation, body) => {
  return adminAxios.put(`/badge-ui-config/${organisation}`, body);
};
