import clsx from "clsx";
import React, { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { MultiUserCogIcon } from "../../../../asssets/icons";
import { ProfileContext } from "../../../../contexts/profileContext";
import MenuItem from "./menuItem";

const subItemsList = [
  {
    name: "Organisation Information",
    url: "/dashboard/organisation/orginfo/",
    permissionId: "657aed29738aece3c05ef5e3"
  },
  {
    name: "Theme Setup",
    url: "/dashboard/organisation/theme/",
    permissionId: "657aed34738aece3c05ef5e4"
  },
  {
    name: "Region, Est., & Academy",
    url: "/dashboard/organisation/region/",
    permissionId: "657aed53738aece3c05ef5e7"
  },
  {
    name: "Services",
    url: "/dashboard/organisation/services/",
    permissionId: "657aed3d738aece3c05ef5e5"
  },
  {
    name: "Profession Maps",
    url: "/dashboard/organisation/maps/",
    permissionId: "657aed3d738aece3c05ef5e5"
  },
  {
    name: "Strength & Role Setup",
    url: "/dashboard/organisation/strength-and-role/",
    permissionId: "657aed49738aece3c05ef5e6"
  }
];

export default function Menu({ items = [] }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [profile] = useContext(ProfileContext);

  const currentPath = window.location.pathname;

  const { orgId: organizationId } = useParams();

  const getPathBasedProperties = (targetPath) => {
    const isSelected = pathname === targetPath;
    const classNames = clsx("text-lg font-semibold text-black group-hover:text-white", {
      "text-white": isSelected
    });

    return { isSelected, classNames };
  };

  const subAdminPath = "/dashboard/sub-admin";
  const subAdminsProps = getPathBasedProperties(subAdminPath);

  const getOrgLogoLink = (value) => {
    return process.env.REACT_APP_AWS_URL + value;
  };

  return (
    <div className="w-full pt-8 relative">
      {/* Manage Sub-Admins */}
      {profile.personalInfo?.permissions?.includes("657aecb7d45df50bf49485b5") && (
        <MenuItem
          className="py-4 mb-2"
          onClick={() => navigate(subAdminPath)}
          isSelected={subAdminsProps.isSelected}>
          <span className="block w-5 h-5">
            <MultiUserCogIcon isSelected={subAdminsProps.isSelected} />
          </span>
          <span className={subAdminsProps.classNames}>Sub Admins</span>
        </MenuItem>
      )}

      {/* Organizations */}
      {items.map((item) => {
        const isSelected = organizationId === item._id;

        return (
          <div key={item._id}>
            <MenuItem
              className={"py-4 mb-2"}
              itemId={item._id}
              isSelected={isSelected}
              onClick={() => navigate(`/dashboard/organisation/orginfo/${item?._id}`)}>
              <span
                className={`text-lg font-semibold text-black group-hover:text-white flex items-center gap-2`}>
                {item?.logo && (
                  <img
                    src={getOrgLogoLink(item.logo)}
                    className="w-[30px] h-[30px] rounded-full overflow-hidden"
                    alt=""
                  />
                )}
                {item.name}
              </span>
            </MenuItem>

            {/* sub menu items */}
            <ul>
              {subItemsList.map(
                (listItem, index) =>
                  profile.personalInfo?.permissions?.includes(listItem.permissionId) && (
                    <div key={index} className="flex items-center justify-start w-[80%] mx-auto">
                      <div className="flex items-center">
                        <div className="w-[2px] h-10 bg-[#E3E3E3]  "></div>
                        <div className="w-10 h-[2px] bg-[#E3E3E3] "></div>
                      </div>
                      <div>
                        <li
                          onClick={() => navigate(`${listItem.url}${item._id}`)}
                          className="px-2 py-2 rounded-2xl cursor-pointer"
                          style={{
                            background:
                              currentPath.includes(`${listItem.url}`) && item._id == organizationId
                                ? "#E3E3E3"
                                : ""
                          }}>
                          {listItem.name}
                        </li>
                      </div>
                    </div>
                  )
              )}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
