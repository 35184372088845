import React, { useContext, useEffect } from "react";
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ProfileContext } from "./contexts/profileContext";

import { ToastContainer } from "react-toastify";

import AdminLayout from "./components/admin/layout";
import {
  AddSubAdmin,
  Base,
  CreateOrganization,
  Login,
  Magic,
  SubAdmins,
  EditSubAdmin,
  OrganizationMaps
} from "./pages";

import OrganisationInfo from "./pages/OrganisationInfo";
import OrganisationProfile from "./pages/OrganisationProfile";
import OrganisationRegion from "./pages/OrganisationRegion";
import OrganisationTheme from "./pages/OrganisationTheme";
import OrganisationServices from "./pages/OrganistaionServices";
import StrenghtsAndRoles from "./pages/strengthsAndRoles";
import { fetchAdmins, fetchOrganizations, getAdminProfile } from "./service";

function App() {
  const [profile, setProfile] = useContext(ProfileContext);

  // Check login token
  useEffect(() => {
    const token = localStorage.getItem("masterToken");
    if (token) setProfile({ ...profile, token });
  }, []);

  // Load data is token exists
  useEffect(() => {
    if (!profile?.token) return;

    (async () => {
      try {
        // Fetch admins
        const { data: admins } = await fetchAdmins();
        const { data: personalInfo } = await getAdminProfile();

        // Fetch organizations
        const { data: orgs } = await fetchOrganizations();

        setProfile({
          ...profile,
          admins: admins?.admins,
          organizations: orgs,
          personalInfo
        });
      } catch (error) {
        console.error("Fetch orgs error", error);

        // The multi db problem is only with admins endpoing apparently
        const { data: personalInfo } = await getAdminProfile();

        // Fetch organizations
        const { data: orgs } = await fetchOrganizations();

        setProfile({
          ...profile,
          organizations: orgs,
          personalInfo
        });
      }
    })();
  }, [profile?.token, profile?.reload]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Base />}>
            {/* Children routes */}
            <Route
              path="/"
              element={
                <TokenExistence>
                  <Login />
                </TokenExistence>
              }
            />
            <Route path="/magic" element={<Magic />} />

            {/* Dashboard Routes */}
            <Route
              path="dashboard"
              element={
                <ProtectedAdmin>
                  <AdminLayout />
                </ProtectedAdmin>
              }>
              <Route path="sub-admin" element={<SubAdmins />} />
              <Route path="add-sub-admin" element={<AddSubAdmin />} />
              <Route path="edit-sub-admin/:adminId" element={<EditSubAdmin />} />
              <Route path="organisation" element={<OrgOutlet />}>
                <Route path="" element={<CreateOrganization />} />
                <Route path="orginfo/:orgId" element={<OrganisationInfo />} />
                <Route path="theme/:orgId" element={<OrganisationTheme />} />
                <Route path="profile/:orgId" element={<OrganisationProfile />} />
                <Route path="services/:orgId/:service" element={<OrganisationServices />} />
                <Route path="services/:orgId" element={<OrganisationServices />} />
                <Route path="region/:orgId" element={<OrganisationRegion />} />
                <Route path="maps/:orgId" element={<OrganizationMaps />} />
                <Route path="strength-and-role/:orgId" element={<StrenghtsAndRoles />} />
              </Route>
            </Route>
          </Route>

          {/* Default not found route */}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Router>
      <ToastContainer hideProgressBar />
    </>
  );
}

function TokenExistence({ children }) {
  let token = localStorage.getItem("masterToken");
  if (token) {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
}

function ProtectedAdmin({ children }) {
  let token = localStorage.getItem("masterToken");

  if (!token) {
    return <Navigate to="/" replace />;
  }

  return children;
}

function OrgOutlet() {
  return <Outlet />;
}

export default App;
