import React from "react";
import { FaArrowRight } from "react-icons/fa";

import Spinner from "../spinner";
export default function ButtonNext({
  title,
  type = "",
  handler,
  className = "",
  loading = false,
  disabled
}) {
  return (
    <button
      type={type}
      className={`btn btn-next ${className} disabled:opacity-60 !bg-[red]`}
      onClick={handler}
      disabled={loading || disabled}>
      <span>{title}</span>
      <span className="icon-container ml-4">
        <FaArrowRight className="icon" />
      </span>
      <Spinner isSubmitting={loading} />
    </button>
  );
}
