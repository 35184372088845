import { useState, useRef, useEffect } from "react";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import {
  getOrgRoles,
  addRole,
  updateRole,
  deleteRole,
  uploadFileToAWS,
  getUploadingFileData,
} from "../../../service";
import { PlusIcon, RemoveIcon } from "../../../asssets/icons";

const AddOrgRoles = ({ org, orgId }) => {
  const fileRefsRole = useRef({});
  const fileRefsCropRole = useRef({});
  const [CroppedImage, setCroppedImage] = useState("");
  const [roleSection, setRoleSection] = useState([
    {
      _id: "",
      name: "",
      name_fr: "",
      name_en: "",
      name_de: "",
      name_it: "",
      image_fr: "",
      image_en: "",
      image_de: "",
      image_it: "",
      cropped_img_obj: null,
      image_fr_obj: null,
      image_en_obj: null,
      image_de_obj: null,
      image_it_obj: null,
      description_fr: "",
      description_en: "",
      description_de: "",
      description_it: "",
      cloud_fr: "",
      cloud_en: "",
      cloud_de: "",
      cloud_it: "",
    },
  ]);

  const fetchRoles = async () => {
    try {
      const { data } = await getOrgRoles(orgId);
      data.length > 0 ? setRoleSection(data) : addRoleSection();
    } catch (error) {}
  };

  useEffect(() => {
    fetchRoles();
    const randomIndex = Math.floor(Math.random() * org.languages.length);
    console.log(randomIndex);
    setCroppedImage(org.languages[randomIndex]);
    console.log(org.languages[randomIndex]);
  }, [org, orgId]);

  const handleChange = (e, index, lang) => {
    const { name, value } = e.target;
    const updatedSections = [...roleSection];
    updatedSections[index] = { ...updatedSections[index], [name]: value };
    setRoleSection(updatedSections);
  };

  const handleUploadChangeRole = (file, index, key_name, obj_name, lang) => {
    if (file !== undefined) {
      const filename = "roles-images/" + orgId + "/cloud/" + file.name;
      const updatedSections = [...roleSection];
      updatedSections[index] = { ...updatedSections[index], [obj_name]: file };
      updatedSections[index] = {
        ...updatedSections[index],
        [key_name]: filename,
      };
      setRoleSection(updatedSections);
    }
  };

  const handleUploadChangeCroppedRole = (
    file,
    index,
    key_name,
    obj_name,
    lang
  ) => {
    if (file !== undefined) {
      const filename = "roles-images/" + orgId + "/" + file.name;
      const updatedSections = [...roleSection];
      updatedSections[index] = { ...updatedSections[index], [obj_name]: file };
      updatedSections[index] = {
        ...updatedSections[index],
        image_en: filename,
      };
      updatedSections[index] = {
        ...updatedSections[index],
        image_de: filename,
      };
      updatedSections[index] = {
        ...updatedSections[index],
        image_fr: filename,
      };
      updatedSections[index] = {
        ...updatedSections[index],
        image_it: filename,
      };
      setRoleSection(updatedSections);
    }
  };

  const uploadImage = async (type, image) => {
    if (image !== undefined) {
      const {
        data: { url },
      } = await getUploadingFileData({
        name: type + "/" + orgId + "/cloud/" + image.name,
        type: image.type,
      });
      await uploadFileToAWS(url, image, image.type);
    }
  };

  const saveRoles = async () => {
    roleSection.forEach(async (item, index) => {
      if (item?.image_en_obj || item?.image_en_obj?.name !== undefined) {
        await uploadImage("roles-images", item.image_en_obj);
      }
      if (item?.image_de_obj || item?.image_de_obj?.name !== undefined) {
        await uploadImage("roles-images", item.image_de_obj);
      }
      if (item?.image_fr_obj || item?.image_fr_obj?.name !== undefined) {
        await uploadImage("roles-images", item.image_fr_obj);
      }
      if (item?.image_it_obj || item?.image_it_obj?.name !== undefined) {
        await uploadImage("roles-images", item.image_it_obj);
      }

      if (item._id !== "") {
        updateRole(orgId, item);
      } else {
        addRole(orgId, item);
      }
    });
  };

  const addRoleSection = () => {
    setRoleSection([
      ...roleSection,
      {
        _id: "",
        name: "",
        name_fr: "",
        name_en: "",
        name_de: "",
        name_it: "",
        image_fr: "",
        image_en: "",
        image_de: "",
        image_it: "",
        cropped_img_obj: null,
        image_fr_obj: null,
        image_en_obj: null,
        image_de_obj: null,
        image_it_obj: null,
        description_fr: "",
        description_en: "",
        description_de: "",
        description_it: "",
        cloud_fr: "",
        cloud_en: "",
        cloud_de: "",
        cloud_it: "",
      },
    ]);
  };

  const removeRoleSection = async (index) => {
    const updatedSections = [...roleSection];
    const removedSection = updatedSections[index];
    if (removedSection._id !== "")
      await deleteRole(orgId, { _id: removedSection._id });
    updatedSections.splice(index, 1);
    setRoleSection(updatedSections);
  };

  return (
    <>
      <div className="container mx-auto p-4">
        <div className="flex justify-end mt-4">
          <button
            onClick={saveRoles}
            type="button"
            className="flex items-center gap-2 disabled:opacity-60 select-none py-2 rounded font-bold text-md border  bg-[#515151] text-[#FFFFFF] px-8 rounded-3xl"
          >
            Save
          </button>
        </div>
      </div>
      <div>
        {roleSection.map((role, index) => (
          <div key={index} className="container mx-auto">
            <div>
              <table className="table-auto w-full mt-5 rounded">
                <thead className="text-center tracking-wider">
                  <tr>
                    <th className="py-2 px-4"> </th>
                    <th className="py-2 px-4">Role Name </th>
                    <th className="py-2 px-4">Description</th>
                    <th className="py-2 px-4">Image Cloud Image</th>
                    <th className="py-2 px-4">Cropped Image </th>
                    <th className="py-2 px-4" rowspan="4">
                      {" "}
                    </th>
                  </tr>
                </thead>
                <tbody className="border-2 rounded-md p-4 mr-8">
                  {org.languages?.map((language, languageIndex) => {
                    return (
                      <tr key={languageIndex}>
                        <td className="py-2">{language.toUpperCase()}</td>
                        <td className="hidden">{role._id}</td>
                        {language.toUpperCase() === "EN" && (
                          <>
                            <td>
                              <input
                                type="text"
                                name="name_en"
                                value={role.name_en}
                                onChange={(e) => handleChange(e, index, "en")}
                                className="w-full mb-2 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="description_en"
                                value={role.description_en}
                                onChange={(e) => handleChange(e, index, "en")}
                                className="w-full mb-2 ml-3 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                              />
                            </td>
                            <td>
                              <input
                                ref={(el) =>
                                  (fileRefsRole.current[`en_${index}`] = el)
                                }
                                name="cloud_en"
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                  handleUploadChangeRole(
                                    event.currentTarget.files[0],
                                    index,
                                    "cloud_en",
                                    "image_en_obj",
                                    "en"
                                  );
                                }}
                                className="hidden"
                              />
                              <div className="ml-6">
                                <div
                                  className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                  onClick={() => {
                                    fileRefsRole.current[`en_${index}`].click();
                                  }}
                                >
                                  <PlusSmallIcon
                                    className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                  />
                                  <span>{role.image_en_obj?.name}</span>
                                </div>
                              </div>
                            </td>
                            {CroppedImage.toLowerCase() === "en" && (
                              <td rowSpan="4">
                                <input
                                  ref={(el) =>
                                    (fileRefsCropRole.current[`crp_${index}`] =
                                      el)
                                  }
                                  name="image_en"
                                  type="file"
                                  accept="image/*"
                                  onChange={(event) => {
                                    handleUploadChangeCroppedRole(
                                      event.currentTarget.files[0],
                                      index,
                                      "image_en",
                                      "cropped_img_obj",
                                      "en"
                                    );
                                  }}
                                  className="hidden"
                                />
                                <div className="ml-6">
                                  <div
                                    className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                    onClick={() => {
                                      fileRefsCropRole.current[
                                        `crp_${index}`
                                      ].click();
                                    }}
                                  >
                                    <PlusSmallIcon
                                      className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                    />
                                    <span>{role.cropped_img_obj?.name}</span>
                                  </div>
                                </div>
                              </td>
                            )}
                          </>
                        )}
                        {language.toUpperCase() === "DE" && (
                          <>
                            <td>
                              <input
                                type="text"
                                name="name_de"
                                value={role.name_de}
                                onChange={(e) => handleChange(e, index, "de")}
                                className="w-full mb-2 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="description_de"
                                value={role.description_de}
                                onChange={(e) => handleChange(e, index, "de")}
                                className="w-full mb-2 ml-3 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                              />
                            </td>
                            <td>
                              <input
                                ref={(el) =>
                                  (fileRefsRole.current[`de_${index}`] = el)
                                }
                                name="cloud_de"
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                  handleUploadChangeRole(
                                    event.currentTarget.files[0],
                                    index,
                                    "cloud_de",
                                    "image_de_obj",
                                    "de"
                                  );
                                }}
                                className="hidden"
                              />
                              <div className="ml-6">
                                <div
                                  className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                  onClick={() => {
                                    fileRefsRole.current[`de_${index}`].click();
                                  }}
                                >
                                  <PlusSmallIcon
                                    className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                  />
                                  <span>{role.image_de_obj?.name}</span>
                                </div>
                              </div>
                            </td>
                            {CroppedImage.toLowerCase() === "de" && (
                              <td rowSpan="4">
                                <input
                                  ref={(el) =>
                                    (fileRefsCropRole.current[`crp_${index}`] =
                                      el)
                                  }
                                  name="image_de"
                                  type="file"
                                  accept="image/*"
                                  onChange={(event) => {
                                    handleUploadChangeCroppedRole(
                                      event.currentTarget.files[0],
                                      index,
                                      "image_de",
                                      "cropped_img_obj",
                                      "en"
                                    );
                                  }}
                                  className="hidden"
                                />
                                <div className="ml-6">
                                  <div
                                    className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                    onClick={() => {
                                      fileRefsCropRole.current[
                                        `crp_${index}`
                                      ].click();
                                    }}
                                  >
                                    <PlusSmallIcon
                                      className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                    />
                                    <span>{role.cropped_img_obj?.name}</span>
                                  </div>
                                </div>
                              </td>
                            )}
                          </>
                        )}
                        {language.toUpperCase() === "FR" && (
                          <>
                            <td>
                              <input
                                type="text"
                                name="name_fr"
                                value={role.name_fr}
                                onChange={(e) => handleChange(e, index, "fr")}
                                className="w-full mb-2 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="description_fr"
                                value={role.description_fr}
                                onChange={(e) => handleChange(e, index, "fr")}
                                className="w-full mb-2 ml-3 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                              />
                            </td>
                            <td>
                              <input
                                ref={(el) =>
                                  (fileRefsRole.current[`fr_${index}`] = el)
                                }
                                name="cloud_fr"
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                  handleUploadChangeRole(
                                    event.currentTarget.files[0],
                                    index,
                                    "cloud_fr",
                                    "image_fr_obj",
                                    "fr"
                                  );
                                }}
                                className="hidden"
                              />
                              <div className="ml-6">
                                <div
                                  className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                  onClick={() => {
                                    fileRefsRole.current[`fr_${index}`].click();
                                  }}
                                >
                                  <PlusSmallIcon
                                    className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                  />
                                  <span>{role.image_fr_obj?.name}</span>
                                </div>
                              </div>
                            </td>
                            {CroppedImage.toLowerCase() === "fr" && (
                              <td rowspan="4">
                                <input
                                  ref={(el) =>
                                    (fileRefsCropRole.current[`crp_${index}`] =
                                      el)
                                  }
                                  name="image_fr"
                                  type="file"
                                  accept="image/*"
                                  onChange={(event) => {
                                    handleUploadChangeCroppedRole(
                                      event.currentTarget.files[0],
                                      index,
                                      "image_fr",
                                      "cropped_img_obj",
                                      "en"
                                    );
                                  }}
                                  className="hidden"
                                />
                                <div className="ml-6">
                                  <div
                                    className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                    onClick={() => {
                                      fileRefsCropRole.current[
                                        `crp_${index}`
                                      ].click();
                                    }}
                                  >
                                    <PlusSmallIcon
                                      className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                    />
                                    <span>{role.cropped_img_obj?.name}</span>
                                  </div>
                                </div>
                              </td>
                            )}
                          </>
                        )}
                        {language.toUpperCase() === "IT" && (
                          <>
                            <td>
                              <input
                                type="text"
                                name="name_it"
                                value={role.name_it}
                                onChange={(e) => handleChange(e, index, "it")}
                                className="w-full mb-2 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="description_it"
                                value={role.description_it}
                                onChange={(e) => handleChange(e, index, "it")}
                                className="w-full mb-2 ml-3 mt-2 px-2 py-1 border border-[#757575]  rounded w-full placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                              />
                            </td>
                            <td>
                              <input
                                ref={(el) =>
                                  (fileRefsRole.current[`it_${index}`] = el)
                                }
                                name="cloud_it"
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                  handleUploadChangeRole(
                                    event.currentTarget.files[0],
                                    index,
                                    "cloud_it",
                                    "image_it_obj",
                                    "it"
                                  );
                                }}
                                className="hidden"
                              />
                              <div className="ml-6">
                                <div
                                  className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                  onClick={() => {
                                    fileRefsRole.current[`it_${index}`].click();
                                  }}
                                >
                                  <PlusSmallIcon
                                    className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                  />
                                  <span>{role.image_it_obj?.name}</span>
                                </div>
                              </div>
                            </td>
                            {CroppedImage.toLowerCase() === "it" && (
                              <td rowspan="4">
                                <input
                                  ref={(el) =>
                                    (fileRefsCropRole.current[`crp_${index}`] =
                                      el)
                                  }
                                  name="image_it"
                                  type="file"
                                  accept="image/*"
                                  onChange={(event) => {
                                    handleUploadChangeCroppedRole(
                                      event.currentTarget.files[0],
                                      index,
                                      "image_it",
                                      "cropped_img_obj",
                                      "en"
                                    );
                                  }}
                                  className="hidden"
                                />
                                <div className="ml-6">
                                  <div
                                    className="ml-3 mb-2 flex gap-4 mt-2 px-2 py-1 border border-[#757575] rounded  placeholder-[#606060] bg-[#F6F5F5] text-sm focus:border-[#000000] focus:ring-0 focus:outline-none"
                                    onClick={() => {
                                      fileRefsCropRole.current[
                                        `crp_${index}`
                                      ].click();
                                    }}
                                  >
                                    <PlusSmallIcon
                                      className={`w-5 h-5 stroke-1 stroke-white bg-[#E3E3E3] rounded-full`}
                                    />
                                    <span>{role.cropped_img_obj?.name}</span>
                                  </div>
                                </div>
                              </td>
                            )}
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="flex justify-end mt-4">
              {index > 0 && (
                <button
                  className="mt-4 mr-4"
                  onClick={() => removeRoleSection(index)}
                >
                  <RemoveIcon />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="container mx-auto p-4">
        <div className="flex justify-end mt-4">
          <button className="mt-4" onClick={() => addRoleSection()}>
            <PlusIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default AddOrgRoles;
