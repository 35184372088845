import React, { useContext, useState } from "react";
import { isEmpty } from "lodash";
import { ProfileContext } from "../contexts/profileContext";
import { PlusSmallIcon, InformationCircleIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

import { PageWrapper } from "../components/admin";
import { Button } from "../components/form";
import { Tooltip } from "../components/popups";

export default function SubAdmins() {
  const [profile] = useContext(ProfileContext);

  const [openTooltipId, setOpenTooltipId] = useState("");

  const navigate = useNavigate();
  let pageHeading = "Manage Sub-Admins";
  let pageSubheading =
    "Manage your sub-admin accounts: add new sub-admins, update account information, and remove accounts.";

  const handleAddClick = () => {
    navigate("/dashboard/add-sub-admin");
  };

  const hadleTooltipShow = (id) => {
    const currentProfile = profile?.admins?.find((admin) => admin._id === id);
    if (!currentProfile) return;
    setOpenTooltipId(currentProfile?._id);
  };

  const handleCloseTooltip = () => {
    setOpenTooltipId("");
  };

  return (
    <PageWrapper pageHeading={pageHeading} pageSubheading={pageSubheading}>
      <div className="mt-5">
        <div className="mb-1 py-2 my-10 border-b-2">
          <p className="text-base font-bold text-left text-[#191919]">Your Account</p>
          <Button.ItemwithSingleButton classNames={"bg-white mb-1"} key={profile.personalInfo}>
            {profile.personalInfo?.email}
          </Button.ItemwithSingleButton>
          <div className="relative mb-4 mt-0">
            <button
              className="flex items-center"
              onClick={() => hadleTooltipShow(profile.personalInfo?._id)}>
              <InformationCircleIcon width={16} className="mr-2" /> <p>Roles</p>
            </button>
            <Tooltip
              open={!isEmpty(openTooltipId) && openTooltipId === profile.personalInfo?._id}
              setOpen={handleCloseTooltip}
              title={"Roles"}>
              <ul className="list-disc list-inside">
                {!isEmpty(profile.personalInfo?.permissions) ? (
                  profile?.admins
                    .find((admin) => admin?._id === profile.personalInfo?._id)
                    ?.permissions.map(({ _id, name }) => <li key={_id}>{name}</li>)
                ) : (
                  <li>No roles</li>
                )}
              </ul>
            </Tooltip>
          </div>
        </div>
        <div className="h-7 mb-1">
          <p className="text-base font-bold text-left text-[#191919]">Sub-Admin Accounts</p>
        </div>
        <div className="h-7 mt-1">
          <p className="text-base text-left text-[#757575]">
            Sub-admin accounts that have been added:
          </p>
        </div>
        <div className="mt-4">
          {profile?.admins?.map(
            ({ _id, email, permissions = [] }) =>
              _id !== profile.personalInfo?._id && (
                <div key={_id}>
                  <div className="flex items-center">
                    <Button.ItemwithSingleButton classNames={"bg-white mb-1 w-full"} key={_id}>
                      {email}
                    </Button.ItemwithSingleButton>
                    <button
                      className="p-4"
                      onClick={() => navigate(`/dashboard/edit-sub-admin/${_id}`)}>
                      <PencilIcon className="w-8 h-5" />
                    </button>
                  </div>
                  <div className="relative mb-4 mt-0">
                    <button className="flex items-center" onClick={() => hadleTooltipShow(_id)}>
                      <InformationCircleIcon width={16} className="mr-2" /> <p>Roles</p>
                    </button>
                    <Tooltip
                      open={!isEmpty(openTooltipId) && openTooltipId === _id}
                      setOpen={handleCloseTooltip}
                      title={"Roles"}>
                      <ul className="list-disc list-inside">
                        {!isEmpty(permissions) ? (
                          permissions.map(({ _id, name }) => <li key={_id}>{name}</li>)
                        ) : (
                          <li>No roles</li>
                        )}
                      </ul>
                    </Tooltip>
                  </div>
                </div>
              )
          )}
        </div>
        <div className="w-full mt-[50px] bg-[#F9F9F9] rounded relative">
          <div
            className="flex justify-start items-center gap-4 px-4 py-3 cursor-pointer bg-white shadow-sm"
            onClick={() => handleAddClick()}>
            <PlusSmallIcon className={`w-7 h-7 stroke-2 stroke-white bg-orange-300 rounded-full`} />
            <p className="text-lg font-bold text-gray-900">{`Add sub admin`}</p>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
